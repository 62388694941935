$color-error-red: #B20019;
$color-red: #cf0a2c; // Stortinget red
$color-blue: #001f5b; // Stortinget blue
$color-pink: #b00060; // Stortinget pink
$color-purple: #701c45; // Stortinget purple
$color-green: #4c9462; // Stortinget green
$color-green-new: #3b7a4e; // Stortinget midlertidlig Green
$color-blue-light: #3c76c1;
$color-blue-light-hover: #3668aa;
$color-blue-light-focus: #16488b;
$color-blue-lightest: #dee8f5;

$color-black: #111820; // body text

$color-grey-lightestest: red;
$color-grey-lightest: #f7f6f4; // link underlines
$color-grey-light: #ece8e5; // box background
$color-grey: #d8d1ca; // dark box background
$color-grey-darker: #b7ada5;
$color-grey-dark: #7d7068; // ny farge som snooker AA

$color-highlight: #fcecbb;
$color-highlight-yellow: #ffd923;
