.biography-header {
  margin-top: 0.2rem;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: rem-calc(22);
  line-height: 1.5;
  text-rendering: optimizeLegibility;

  .biography-header-years {
    white-space: nowrap;
  }
}

.biography-details {
  @media #{$large-down} {
    margin-bottom: 20px;
  }
  dl {
    @extend .clearfix;
    margin-bottom: 20px;
  }
  dt,
  dd {
    display: block;
    float: left;
    margin-bottom: 0;
  }
  dt {
    clear: left;
    margin-right: 5px;
    font-weight: 700;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.subscribe-options {
  @extend .no-bullet;
  margin-top: 12px;
  margin-bottom: 12px;

  li {
    margin-top: 8px;
  }
}

.subscribe-form {
  h2 {
    margin-bottom: rem-calc(8);
    font-weight: bold;
    font-family: inherit;
    font-weight: 600;
    line-height: 1.3;
    font-size: 1rem;
    text-rendering: optimizeLegibility;
  }

  input[type="text"],
  input[type="email"] {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.biography-subheader {
  margin-bottom: 20px;
}

.biography-block {
  margin-bottom: 20px;
}

.biography-frame {
  padding: 20px 20px;
  background-color: $color-grey-light;
  margin-bottom: 40px;

  @media #{$large-up} {
    padding: 40px 0;
  }

  .row {
    &.line-above,
    &.line-below {
      margin-top: 20px;
    }

    &.line-above {
      &:before {
        display: block;
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
        padding-bottom: 30px;
        content: "";
        border-top: 1px solid $color-grey;
      }
    }

    &.line-below {
      &:after {
        display: block;
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
        padding-top: 20px;
        content: "";
        border-bottom: 1px solid $color-grey;
      }
    }
  }

  .biography-frame-left {
    .profile-image-wrapper {
      @media #{$medium-only} {
        margin-left: 0;
      }
      @media #{$large-up} {
        margin-right: 0;
      }
    }
  }
}

.biography-contact,
.biography-affiliation {
  .row > .columns {
    margin-bottom: 20px;
  }
}

.biography-contact {
  h3 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: rem-calc(16);
    font-family: $fontSans;
    font-weight: 700;
    text-rendering: optimizeLegibility;
    line-height: 1.5;
  }
}

.biography-affiliation {
  h3 a {
    font-size: rem-calc(16);
    font-family: $fontSans;
  }
  margin-bottom: 40px;
}

.twtr-section {
  margin-top: 20px;

  @media #{$large-up} {
    margin-top: 30px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.twtr-subheader {
  margin-bottom: 20px;

  .twtr-subheader-link {
    font-size: rem-calc(16);
    border-bottom: none !important;

    @media #{$large-down} {
      display: block;
    }
  }

  @media #{$medium-up} {
    font-size: rem-calc(28);
  }
}

.biography-wrap {
  margin-bottom: 40px;

  @media #{$large-down} {
    &:nth-child(2n + 1) {
      clear: left;
    }
  }

  @media #{$large-up} {
    &:nth-child(3n + 1) {
      clear: left;
    }
  }
}

.biography-image-wrapper {
  position: relative;
  width: 120px;
  height: 120px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  @media #{$medium-up} {
    float: left;
    margin: 0 20px 0 0;
  }

  img {
    width: 100%;
  }
}

.twtr-tweet-text {
  p {
    margin-bottom: 0;
  }
}
.twtr-timestamp {
  font-style: normal;
}
