$fontSans: "Berlingske Sans" !default;
$fontSerif: "Berlingske Serif" !default;
$fontSerifText: "Berlingske Serif Text" !default;

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Header styles */

h1 {
  @include h1();
}
h2 {
  @include h2();
}
h3 {
  @include h3();
}
h4,
h5,
h6 {
  font-size: rem-calc(18px);
  @media #{$medium-up} {
    font-size: rem-calc(19);
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 2px;
  margin-bottom: 5px;
}

h2 + h3 {
  margin-top: 20px;
}

.section-title {
  @include section-title();
}

.block-title {
  @include block-title();
}

a .block-title,
.block-title a {
  display: inline;
  color: $color-black;
  &:hover,
  &:focus {
    color: $color-red;
    border-bottom-color: #cac9c9;
    border-bottom-color: rgba(180, 180, 180, 0.8);
  }
}

/* Default Link Styles */
a {
  img {
    border: none;
  }
  @include link();
}

/* Default paragraph styles */
p {
  font-size: rem-calc(16px);
  line-height: 1.5;
  margin-bottom: 30px;
  font-family: $fontSans;
}

p.excerpt {
  font-family: $fontSerifText;
}

.line-through {
  text-decoration: line-through;
}
