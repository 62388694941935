.block {
	overflow: hidden;
	margin: 0 0 30px 0;
	&.block-light-grey {
		background-color: #f7f7f7;
	}
	.block-inner {
		overflow: hidden;
	}

}

// Deprecated
.block-grey {
	@extend .block-color-grey;
}

.block-color-grey {
	background-color: $color-grey;
}

.block-color-lightgrey {
	background-color: $color-grey-light;
}

.block-color-white {
	background-color: #fff;
}

.block-color-purple {
	background-color: $color-purple;
}

// Deprecated
.block-alpha {
	@extend .block-spacing-small;
}

.block-spacing-small {
	padding: upper-bound($small-spacing-range) / 2;
	@media #{$medium-up} {
		padding: middle-bound($small-spacing-range);
	}
	@media #{$large-up} {
		padding: upper-bound($small-spacing-range);
	}
}

.block-spacing-small-inner {
	padding: upper-bound($small-spacing-range);
	@media #{$medium-up} {
		padding: upper-bound($medium-spacing-range);
	}
	@media #{$large-up} {
		padding: middle-bound($large-spacing-range);
	}
}

.block-spacing-medium {
	padding: lower-bound($medium-spacing-range);
	@media #{$medium-up} {
		padding: middle-bound($medium-spacing-range);
	}
	@media #{$large-up} {
		padding: upper-bound($medium-spacing-range);
	}
}

.block-spacing-xlarge {
	padding: lower-bound($medium-spacing-range);
	@media #{$medium-up} {
		padding: middle-bound($medium-spacing-range);
	}
	@media #{$large-up} {
		padding: lower-bound($xlarge-spacing-range);
	}
}

.block-medium {
	padding: $medium-spacing;
	@media #{$large-up} {
		padding: $medium-spacing;
	}
}

.block-echo {
	padding: 20px;

	@media #{$medium-up} {
		padding: 40px;
	}
}

.block-excerpt {
	> p:last-child {
		margin-bottom: 0;
	}
}

.block-list {
	margin: 0;

	li {
		margin: 0 0 7px 0;
	}
}

.block-box {
	.block-title {
		display: block;
		margin: 0 0 15px 0;

		a {
			color: $color-black;
		}
	}
	.block-excerpt {
		color: $color-black;
		margin: 0;
	}
	.block-list {
		margin-top: 10px;
	}
}
