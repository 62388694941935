input,
select,
textarea {
  font-family: $fontSans;
  font-size: inherit;
}

.error-red {
    color: $color-error-red;
}

legend.legend-fullwidth {
  display: block;
  width: 100%;
}

ul.form-area-tabs {
  display: flex;
  margin: 0;
  background-color: $color-grey;
  align-items: flex-start;
  margin-right: -1.25rem;
  margin-left: -1.25rem;

  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
  }

  > li {
    list-style: none;

    &:first-child {
      > button {
        @media #{$large-up} {
          margin-left: -10px;
        }
      }
      @media #{$large-up} {
        padding-left: 16.66667% !important;
      }

      > a {
        @media #{$large-up} {
          padding-left: 10px;
        }
      }
    }

    &:not(:first-child) {
      flex: auto;
    }

    > a {
      display: block;
      padding: 20px;
      color: $color-black;
      border: none;
      @include h2;

      @media #{$small-only} {
        font-size: rem-calc(15) !important;
      }

      @media #{$medium-up} {
        font-size: rem-calc(22) !important;
      }

      &:focus,
      &:hover {
        background-color: darken($color-grey, 5%);
      }
    }

    &:not(.active):focus,
    &:not(.active):hover {
      background-color: darken($color-grey, 5%);

      > a {
        background-color: darken($color-grey, 5%);
      }
    }

    &.active {
      background-color: $color-grey-light;

      > a {
        &:focus,
        &:hover {
          background-color: $color-grey-light;
        }
      }
    }
  }
}

.form-area {
    &.form-area-framed {
        margin-bottom: 40px;
        padding-top: 40px;
        padding-bottom: 40px;
        background-color: $color-grey-light;

        @media #{$large-down} {
            margin-right: rem-calc(-20);
            margin-left: rem-calc(-20);
            padding-right: rem-calc(20);
            padding-left: rem-calc(20);
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    &.form-area-padded {
        @media #{$large-up} {
            padding-right: 45px;
            padding-left: 45px;
        }
    }

    .row {
        position: relative;

        + .row {
            padding-top: rem-calc(15);
        }

        .columns + .columns {
            padding-top: rem-calc(20);

            @media #{$medium-up} {
                padding-top: 0;
            }
        }

        h2,
        h3 {
            margin-bottom: 10px;
        }

        .force-to-bottom-right {
            position: absolute;
            right: 0;
            bottom: 0;
        }

        .custom-dropdown {
            margin-bottom: 0;
        }
    }

    .line-above {
        position: relative;
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $color-grey;

        @media #{$medium-up} {
            padding-top: 30px;
            margin-top: 30px;
        }
    }

    .line-below {
        position: relative;
        margin-bottom: 10px;
        margin-right: rem-calc(15);
        margin-left: rem-calc(15);
        border-bottom: 2px solid $color-grey;
    }

    textarea {
        width: 100%;
        margin: 0;
        padding: 14px 16px 15px;
        border: none;
        background-color: #fff;
        border: 1px solid $color-grey;

        &:hover {
            border-color: $color-grey-darker;
        }

        &:focus {
            border-color: $color-grey-dark;
            outline: none;
        }
    }

    select {
        border: 1px solid $color-grey;
    }

    select[multiple] {
        padding: 10px;
    }

    label {
        font-family: $fontSans;
        display: inline-block;
        margin-bottom: rem-calc(3);
        color: $color-black;

        &.label-fullwidth {
            display: block;
        }
    }

    span,
    label,
    input[type="text"],
    input[type="email"] {
        + label {
            margin-top: 20px;
        }
    }

    .checkbox {
        display: inline-block;
        margin-right: 1.5em;
    }

    .checboxspacingtop {
        input[type="checkbox"] {
            + label {
                margin-top: 20px;
            }
        }
    }

    .radio-fullwidth,
    .checkbox-fullwidth {
        display: block;
        margin-top: 0.3em;
        margin-bottom: 0.3em;
    }
    // Radio buttons
    .hide-radio-input input[type="radio"] {
        @extend .visuallyhidden;
    }

    input[type="radio"] + label {
        display: inline-block;
        position: relative;
        padding-left: 30px;

        &:after {
            content: "";
            width: 17px;
            height: 17px;
            position: absolute;
            top: 2px;
            left: 2px;
            background-color: #fff;
            border: 4px solid #fff;
            border-radius: 50%;
        }

        &:before {
            content: "";
            width: 21px;
            height: 21px;
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-grey;
            border: 1px solid $color-grey-darker;
            border-radius: 50%;
        }

        small {
            font-size: 85%;
        }
    }

    &.form-area-framed input[type="radio"] + label:before {
        background-color: #fff;
    }

    input[type="radio"] + label:hover,
    input[type="radio"]:focus + label {
        &:before {
            border: 2px solid black;
        }
    }

    input[type="radio"]:checked + label {
        &:after {
            background-color: $color-red;
        }
    }

    input[type="radio"]:checked:focus + label:before {
        border-color: black;
    }
    // Checkboxes       
    .hide-checkbox-input input[type="checkbox"] {
        @extend .visuallyhidden;
    }

    input[type="checkbox"] {
        @extend .visuallyhidden;

        + label {
            display: inline-block;
            padding-left: 30px;
            text-indent: -20px;
            cursor: pointer;

            &:before {
                display: inline-block;
                position: relative;
                top: 5px;
                width: 25px;
                height: 25px;
                left: -10px;
                line-height: 20px;
                content: "";
                background-repeat: no-repeat;
                background-position: center;
                background-size: 15px;
                background-color: #fff;
                border: 1px solid $color-grey;
            }

            &:hover:before {
                border-color: $color-grey-dark;
            }
        }

        &:focus + label:before {
            border-color: $color-grey-dark;
        }

        &:checked {
            + label {
                &:before {
                    background-image: url("../gfx/icons/check.svg");
                }
            }

            &:focus {
                + label,
                + input[type="hidden"] + label {
                    &:before {
                        color: #333;
                    }
                }
            }
        }
    }

    input[type="checkbox"][disabled] {
        @extend .visuallyhidden;

        + label {
            color: $color-grey;

            &:hover:before {
                border-color: $color-grey;
                pointer-events: none;
            }
        }
    }

    &.form-area-framed input[type="checkbox"] {
        + label:before {
            color: #fff;
            background-color: #fff;
        }

        &:checked {
            + label {
                &:before {
                    background-image: url("../gfx/icons/check.svg");
                }
            }
        }

        &:checked {
            + label {
                &:before {
                    color: #000;
                }

                &:hover {
                    &:before {
                        color: #000;
                    }
                }
            }

            &:focus {
                + label,
                + input[type="hidden"] + label {
                    &:before {
                        color: #000;
                    }
                }
            }
        }
    }

    .custom-file-upload-wrapper {
        margin-bottom: 10px;
    }

    .no-js & {
        .custom-file-upload,
        .custom-file-upload .upload {
            display: none;
        }
    }

    .js & {
        .custom-file-upload {
            position: relative;
            overflow: hidden;
            display: inline-block;
            width: 100%;

            .fake-upload-field,
            .fake-upload-field:disabled {
                width: 100%;
                margin: 0;
                padding: 16px 16px 16px;
                border: none;
                background-color: #fff;
                border: 1px solid $color-grey;
                opacity: 1;
            }

            .upload {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                font-family: inherit;
                border-bottom: none;

                &.focused {
                    background-color: $color-blue-light-focus;
                }
            }

            &:hover {
                .fake-upload-field,
                .fake-upload-field:disabled {
                    border-color: $color-grey-darker;
                }

                .upload {
                    background-color: $color-blue-light-hover;
                }
            }

            input[type="file"] {
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 53px;
                margin: 0;
                padding: 0;
                cursor: pointer;
                opacity: 0;
                filter: alpha(opacity=0);
            }
        }
    }
}

span[style*="hidden"] {
  display: none;
}
span.error {
    color: $color-error-red;
}

.simple-mobile-toggler {
  &-label {
    background-color: white;
    border: 1px solid $color-grey;
    padding: 13px 50px 13px 1.3em !important;
    position: relative;
    text-indent: 0 !important;
    width: 100%;

    &::before {
      content: none !important;
    }

    &::after {
      position: absolute;
      top: 20px;
      right: 10px;
      padding: 7px;
      content: "";
      background-color: transparent;
      pointer-events: none;
      background-image: url(../gfx/icons/arrow-right.svg);
      background-repeat: no-repeat;
      transform: rotate(90deg);
    }
  }

  &-content {
    display: none;
  }

  &:focus {
    ~ .simple-mobile-toggler-label {
      border-color: $color-grey-dark;
    }
  }

  &:checked {
    ~ .simple-mobile-toggler-label {
      &::after {
        top: 15px;
        transform: rotate(270deg);
      }
    }

    ~ .simple-mobile-toggler-content {
      display: block;
    }
  }

  @media #{$medium-up} {
    &-label {
      display: none !important;
    }

    &-content {
      display: block;
    }
  }
}

.form-flex {
  display: flex;
  flex-wrap: wrap;

  &__text {
    flex: 1;
    min-width: 1em/16 * 220;
  }
}
