.btn-help {
  padding-left: 38px;
  margin-top: 5px;
  border-bottom: none;
  overflow: visible;
  @media #{$small-only} {
    margin-top: 5px;
  }
  margin-left: 10px;
  &:before,
  &:after {
    left: 0;
  }
}

.help-popup {
  display: none;

  &.open {
    display: block;
    position: relative;
    padding: 30px 45px 30px 30px;
    font-size: rem-calc(14px);
    color: #fff;
    background-color: #333;

    @media #{$medium-up} {
      position: absolute;
      z-index: 10;
      width: 500px;
    }

    * {
      margin-top: 0;
      margin-bottom: 0;
      font-size: inherit;

      + * {
        margin-top: rem-calc(20px);
      }
    }

    .btn-close {
      position: absolute;
      top: 10px;
      right: 10px;
      width: 32px;
      height: 32px;
      margin-top: 0 !important;
      border-bottom: none;
    }
  }
}
