.nav-secondary {
  display: none;

  @media #{$large-up} {
    float: left;
    display: inline-block;
    > ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
      > li {
        float: left;
        line-height: 1.4;
        border-right: 1px solid $color-grey;
        padding-right: 2px;
        border-left: 1px solid $color-grey;
        padding-left: 4px;
      }
    }
  }
  .language-toggle {
    position: relative;
    z-index: 11;
    padding: 2px 10px;
    padding-right: 25px;
    line-height: 1.4;
    @include meta-title();
    font-size: 15px;
    border: none;

    &:after {
      position: absolute;
      top: 2px;
      right: 8px;
      padding: 0;
      content: "";
      background-color: transparent;
      pointer-events: none;
      background-image: url(../gfx/icons/arrow-grey-down.svg);
      background-repeat: no-repeat;
      background-size: 10px;
      background-position: center center;
      content: "";
      width: 10px;
      height: 10px;
    }

    // Fix for menu
    @media (max-width: 1150px) {
      font-size: 14px;
    }

    &:hover,
    &:focus,
    &.active {
      color: $color-red;
      &:after {
        background-image: url(../gfx/icons/arrow-red-down.svg);
      }
    }
  }
  .language-selector {
    display: none;
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: absolute;
    z-index: 10;
    width: auto;
    padding: 10px;
    left: 4px;
    top: 28px;
    border: 1px solid $color-grey;
    background: #fff;
    min-width: 182px;
    a {
      padding: 2px 10px;
      line-height: 1.6;
      position: relative;
      color: $color-grey-dark;
      border: none;
      &.current,
      &:active,
      &:focus {
        color: $color-red;
      }
    }
  }
}
