.feedbackcontainer {
  @media #{$medium-up} {
    position: fixed;
    bottom: 0;
    z-index: 99999;
  }
  color: #000;
  background-color: #f5f5f5;

  .feedbacktop {
    position: relative;
    top: 0;
    display: table;
    width: 100%;
    height: 70px;

    h3 {
      position: relative;
      display: table-cell;
      height: 70px;
      padding-right: 8px;
      padding-left: 85px;
      margin-top: 0;
      font-size: 18px;
      line-height: 1;
      vertical-align: middle;
      background-color: #eee;
      outline: none;
      cursor: pointer;

      @media #{$xlarge-up} {
        padding-left: 95px;
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 70px;
        height: 70px;
        content: "";
        font-size: 24px;
        text-align: center;
        color: #fff;
        background-color: $primary-color;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        background-image: url("../gfx/icons/questionmark.svg");
        background-size: 35px;
      }

      &:hover,
      &:focus {
        &:before {
          background-color: #fc6f6c;
        }
      }
    }
  }

  .feedbackresponse {
    padding: 20px;

    .form h3 {
      text-align: left;
      padding-top: 0px;
      padding-bottom: 0.3em;
    }

    .radio-toolbar {
      margin-bottom: 20px;

      @media #{$medium-up} {
        margin-bottom: 50px;
      }

      ul {
        margin: 0;
        padding: 0;
        list-style-type: none;

        li {
          margin-bottom: 5px;
        }
      }
    }

    textarea {
      height: 160px;
    }

    input[type="submit"] {
      margin-top: 10px;
    }

    div.end {
      display: none;
      width: 207px;
      height: 155px;
    }
  }

  .feedbacksubmit {
    text-align: right;

    .submit {
      text-transform: uppercase;
    }
  }

  &.surveyAnswered {
    display: none;
  }
}
