.page-title {
    font-family: $fontSerif;
    font-weight: 700;
    color: $color-black;
    margin: 0 0 30px;
    @include h1();
    h1{
      margin: 0;
    }
}

.page-excerpt{
  @extend .article-excerpt;
}

.page-meta-info{
  @include paragraph-article();
}
