body.modal-view {
  overflow: hidden;
}

.main-container {
  &.modal-view {
    height: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;

    .header {
      position: fixed;
    }

    .view-modal {
      top: 59px;
      bottom: 0;
      left: 0;
      max-height: 100%;
      width: 100%;
      background: #fff;
      z-index: -1;
      opacity: 0;
      overflow-y: auto;
      overflow-x: hidden;
      transition: all 400ms ease-in-out;
      opacity: 1;
      z-index: 20;
      padding: 20px 0 0 0;

      @media #{$large-up} {
        top: 130px;
      }

      .row {
        max-width: 1280px;
        margin: 0 auto;
      }

      .columns {
        margin-bottom: 40px;
      }

      .view-modal-title {
        float: left;
      }

      .view-modal-close {
        background: none;
        font-family: "Berlingske Sans";
      }

      .view-modal-close-wrapper {
        position: fixed;
        width: 100%;
        height: 50px;
        background-color: #fff;
        z-index: 2;

        .view-modal-close {
          position: absolute;
          right: 15px;
          background: none;
          border: none;
          font-size: rem-calc(14);
          outline: none;

          @media #{$large-up} {
            right: 30px;
          }

          &:after {
            content: "";
            background-image: url("../gfx/modal-close.png");
            width: 32px;
            height: 32px;
            display: inline-block;
            position: relative;
            top: 10px;
            margin-left: 10px;
          }
        }
      }

      .icon-print {
        float: right;
        color: #333;
        display: none;

        @media #{$large-up} {
          display: block;
        }
      }
    }
  }
}

.view-modal {
  opacity: 0;
  position: fixed;
}
