.hoering-uttalelse-form-button-row {
    padding: 25px 0 40px 0;

    .send-button {
        margin-left: 30px;
        background-color: $color-blue-light;
        color: $color-grey-lightest;
        &:hover {
            background-color: $color-blue-light-hover;
        }
        &:focus {
            background-color: $color-blue-light-focus;
        }
    }
}

.hoering-uttalelse-form-title-spacing {
    padding-top: 60px;
}
