.live-list-header {
  @extend .live-list-padding;
  @include h2();
}

.live-list-link {
  float: right;
  margin-top: 10px;
  @extend .live-list-padding;
}

ul.live-list-small {
  list-style: none;
  margin: 0;
  padding: 0;
  @extend .clearfix;
  width: 100%;

  @media #{$large-up} {
    width: 100%;
  }

  li {
    background-color: #eee;
    float: left;
    @extend .clearfix;
    width: 100%;
    @media #{$large-up} {
      width: 33.3%;
    }
  }
}

.block-small-content {
  width: 50%;
  font-size: 0.8rem;
  border: 20px solid #eee;
  border-bottom: 20px solid #eee;
  overflow: hidden;
  position: relative;
  line-height: 1.5;
  height: 100%;

  &:after {
    position: absolute;
    right: 0;
    bottom: 0px;
    left: 0;
    height: 20px;
    content: "";
    background: url("data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJod…EiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+");
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(0%, rgba(238, 238, 238, 0)),
      color-stop(100%, #eeeeee)
    );
    background: -webkit-linear-gradient(
      top,
      rgba(238, 238, 238, 0) 0%,
      #eeeeee 100%
    );
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(238, 238, 238, 0)),
      to(#eeeeee)
    );
    background: linear-gradient(
      to bottom,
      rgba(238, 238, 238, 0) 0%,
      #eeeeee 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eeeeee', endColorstr='#eeeeee',GradientType=0 );
    pointer-events: none;
  }
}

.block-small-content-text {
}

.block-small-media {
  width: 50%;
  float: right;
  height: 100%;

  img {
    vertical-align: initial;
  }
}

.live-list-padding {
  @media only screen and (max-width: 639px) {
    margin-left: 20px;
    margin-right: 20px;
    position: relative;
  }
}

/* Sly slider */
.block-live-wrapper {
  margin-top: -35px;
  margin-bottom: 0;
}
.page-whats-up {
  .block-live-wrapper.margin-bottom {
    margin-bottom: 30px;
  }
}
.block-live-small-wrapper {
  margin-top: -10px;
}
.block-live-small {
  overflow: visible;
  position: relative;
  margin-bottom: 0 !important;
  .live-list-small-wrapper {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAFCAYAAACNbyblAAAAJklEQVQIW2NkYGAwZmBgOMuABBihbBQJmCBIDi6BLAiXQBcESwAA8wcEOdhY1wEAAAAASUVORK5CYII=)
      repeat;
  }

  .live-list-small-wrapper {
    line-height: 113px;
    height: 113px;
  }
  .live-list-small-wrapper .live-list-small {
    margin: 0;
    padding: 0;
    height: 100%;
    list-style: none;
  }
  .live-list-small-wrapper .live-list-small li {
    float: left;
    margin: 0;
    padding: 0;
    width: 400px;
    height: 100%;
  }

  .live-list-small-scrollbar {
    width: 100%;
    height: 10px;

    background: #e3e2e2;
    line-height: 0;
    height: 3px;
    opacity: 0;
    transition: opacity 0.5s;
    .touch & {
      opacity: 1;
    }
  }

  &:hover,
  &:active {
    .live-list-small-scrollbar {
      opacity: 1;
      transition: opacity 0.5s;
    }
  }

  button.live-list-small-nav {
    position: absolute;
    top: 0;
    bottom: 3px;
    z-index: 99;
    background-color: rgba(0, 0, 0, 0.4);
    opacity: 1;
    transition: opacity 0.3s;
    border: none;
    width: 40px;
    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      background-size: 10px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
    }

    &.live-list-small-nav-prev {
      left: 0;
      &:after {
        background-image: url("../gfx/icons/arrow-left-white.svg");
      }
    }
    &.live-list-small-nav-next {
      right: 0;
      &:after {
        background-image: url("../gfx/icons/arrow-right-white.svg");
      }
    }
    &[disabled] {
      opacity: 0;
      transition: opacity 0.3s;
    }
  }

  .live-list-small-scrollbar .handle {
    width: 100px; /* overriden if dynamicHandle: 1 */
    height: 100%;
    background: #222;

    background: #ea4744;
    cursor: pointer;
  }
  .mousearea {
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: 0;
    right: 0;
  }
}

.live-video-ended {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  color: white;
  background: rgba(50, 50, 50, 0.7);
  color: white;
  span {
    line-height: 1.5;
    font-size: 0.8rem;
    font-weight: bold;
    position: absolute;
    top: 20px;
    left: 20px;
    right: 20px;
  }
}

a.video-play-button.small {
  &:after {
    width: 50px;
    height: 50px;
  }
}
