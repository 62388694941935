.calendar-day-title-wrapper {
    height: 60px;
    width: 100%;
    background-color: $color-grey-light;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.current {
        background-color: $color-blue;
        color: #fff;
    }

    .calendar-day-title,
    .calendar-day-weeknumber {
        font-size: rem-calc(18);
        margin: 0 20px;
        font-weight: bolder;

        @media #{$medium-up} {
            font-size: rem-calc(22);
            margin-right: 40px;
        }
    }
}

.calendar-day:last-child {
    border-bottom: 1px solid $color-grey;

    @media #{$medium-up} {
        margin-bottom: 50px;
    }
}

.calendar-event {
    flex-direction: row;
    padding-top: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    border-bottom: 1px solid $color-grey;

    &:last-child {
        border-bottom: 0;
    }

    @media #{$medium-up} {
        display: flex;
        padding-left: 25px;
        padding-top: 20px;
        padding-bottom: 15px;
    }

    .calendar-event-category {
        width: 100%;
        font-weight: bolder;
        font-size: rem-calc(16);

        @media #{$medium-up} {
            width: 30%;
            font-size: rem-calc(18);
        }

        @media #{$large-up} {
            width: 300px;
        }
    }

    .calendar-event-content {
        width: 100%;
        font-size: rem-calc(16);
        font-weight: normal;

        @media #{$medium-up} {
            font-size: rem-calc(18);
            width: calc(100% - 300px) !important;
        }

        @media #{$large-up} {
            width: auto;
        }

        .calendar-event-content-item {
            margin: 10px 0;
            display: flex;
            flex-wrap: wrap;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            .text {
                max-width: 100%;

                &:first-child {
                    line-height: rem-calc(26);
                }
            }

            .item-left {
                display: block;
                float: left;
                width: 100% !important;

                a {
                    display: inline;
                }

                @media #{$large-up} {
                    width: 70% !important;
                }
            }

            .item-right {
                display: flex;
                float: left;
                justify-content: flex-start;
                width: 100% !important;

                @media #{$large-up} {
                    width: 30% !important;
                    justify-content: flex-end;
                }

                p {
                    margin-bottom: 0;
                }

                a {
                    display: block;
                }
            }

            .horing-live {
                padding: 8px 12px 4px;
                font-weight: bold;
                font-size: 15px;
                line-height: 1.5;
                letter-spacing: 0.3px;
                text-transform: uppercase;
                color: white;
                background-color: $color-red;
                display: inline-block;
                margin-bottom: 0.5rem;
            }

            .horing-status {
                font-style: italic;
                color: #7d7068;
            }

            .image-wrapper {
                width: 70px;
                height: 70px;
                border-radius: 50%;
                overflow: hidden;
                margin: 15px 0;

                img {
                    vertical-align: middle;
                    max-width: 100%;
                    height: auto;
                    display: inline-block;
                }
            }

            .caption {
                min-height: 30px;
                background-color: $color-grey-light;
                display: table;

                .speech-bubble {
                    font-family: $fontSans;
                    display: table-cell;
                    padding: 5px 10px;
                    vertical-align: middle;
                    font-weight: 500;
                    font-size: rem-calc(14);
                    word-break: break-word;

                    a {
                        font-weight: 500;
                    }
                }
            }
        }
    }
}

.calendar-cal {
    margin-bottom: 40px;
}

.calendar-header {
    display: flex;
    margin-bottom: 10px;
    min-height: 42px;

    .calendar-intro {
        font-family: $fontSans;
        font-weight: bolder;
        font-size: rem-calc(16);
        align-self: center;
        margin-bottom: 10px;
        padding-right: $column-gutter/4;
    }

    @media #{$small-only} {
        flex-direction: column;
        background-color: $color-grey-light;
        margin: -40px ($column-gutter/2*-1) 0;
        padding: 0 $column-gutter/2;

        .calendar-intro {
            align-self: stretch;
            background-color: white;
            margin: 1em ($column-gutter/2*-1) 0;
            order: 2;
            padding: $column-gutter ($column-gutter/2) ($column-gutter/2);
        }
    }

    @media #{$medium-up} {
        flex-direction: row;
        justify-content: space-between;

        .calendar-intro {
            margin-bottom: 0;
            font-size: rem-calc(18);
        }
    }
}

.calendar-event-types {
    @media #{$small-only} {
        .checkbox {
            margin-right: 0 !important;
            width: 100%;
        }
    }

    @media #{$medium-up} {
        .checkbox {
            min-width: 40%;
        }
    }

    @media #{$large-up} {
        padding-right: $column-gutter*1.5;
        padding-left: $column-gutter*1.5;

        .checkbox {
            min-width: 0;
        }
    }
}

.calendar-select {
    margin-top: auto;
    margin-bottom: auto;

    @media #{$small-only} {
        margin-bottom: 10px;

        .custom-dropdown {
            &,
            select {
                width: 100%;
            }
        }
    }

    @media #{$medium-up} {
        margin-right: 1em;
        margin-left: auto;

        .custom-dropdown {
            select {
                height: 42px;
                min-width: 122px;
                padding-top: 9px;
                padding-bottom: 9px;
            }

            &::after {
                background-size: 8px;
                top: 17px;
            }
        }
    }
}

.calendar-navigator {
    border: 1px solid $color-grey;
    border-left: none;
    display: flex;
    height: fit-content;
    margin: auto 0;

    > * {
        height: 40px;
        line-height: 40px;
        border-left: 1px solid $color-grey;
    }

    > button {
        background-color: white;
        width: 40px;
        @include icon();
        @include icon-small-left();
        margin-left: 0;

        &:hover,
        &:focus {
            background-color: $color-grey-light;
        }

        &:before,
        &:after {
            left: 15px;
            top: 15px;
        }

        &.prev {
            @include icon-arrow-left();
        }

        &.next {
            @include icon-arrow-right();
        }
    }

    .calendar-nav-btn-text {
        width: auto;
        padding: 0 20px;
        font-weight: 500;
        font-size: rem-calc(16);
        white-space: nowrap;

        @media #{$small-only} {
            text-align: center;
            flex: 2;
        }
    }
}

.event-profile-label {
    display: block;
    background-color: $color-grey-light;
    padding: 6px 10px 2px;
    margin-top: 20px;
}

.page-calendar-subscription {
    .block {
        margin-bottom: 20px;
    }

    .step {
        @extend .block;

        @media #{$medium-up} {
            display: flex;
            flex-direction: row;
        }
    }

    .link-box {
        padding: 13px 50px 13px 1.3em;
        border: 1px solid $color-grey;
        width: 100%;
        background-color: $color-grey-light;

        &:disabled {
            opacity: 1;
        }
    }

    .number > span {
        @include number();
        @include number-small();
        background-color: $color-purple;
        margin: 0 15px 0 0;
        top: 10px;
        left: 10px;

        @media #{$large-up} {
            left: 20px;
        }

        border: 1px solid transparent;
    }

    .content * {
        margin-bottom: 10px;
    }

    .content > h3.step-header {
        padding-top: 5px;
    }

    .block-extra-facts {
        margin-bottom: 10px;
    }
}

.calendar-loading-container {
    text-align: center;
}
