.organization .map-container {
  $breakpoint-tablet: 641px;
  $breakpoint-desktop: 1024px;
  $breakpoint-huge: 1480px;

  max-width: 1750px;
  margin: 0 auto;

  #sections {
    margin: 2.5em 0 5em;
  }
  ul.org-chart-sections {
    padding: 0;
    margin: 0 0 1em;
    list-style: none;
    text-align: center;
    > li:before {
      content: none;
    }
  }
  .sections__item {
    margin: 0.5em 0;
    list-style: none;
    &:before {
      box-sizing: content-box;
    }
    a,
    .no-link {
      background: $color-grey;
      padding: 1.25em;
      display: block;
      text-align: center;
      text-decoration: none;
      color: #333;
      line-height: 1em;
      margin: 0 0 1em;
      transition: 0.25s;
      span {
        display: block;
        font-size: 15px;
        font-weight: 500;
      }
    }
    > a,
    > .no-link {
      background: $color-red;
      color: white;
    }
  }
  .sections__children {
    padding: 0 0 0 40px;
    .sections__children__item {
      &:before {
        content: "";
        width: 40px;
        height: 100px;
        border-bottom: 1px solid black;
        border-left: 1px solid black;
        display: block;
        margin: -4.5em 0 -30px -40px;
        position: absolute;
      }
      &:nth-child(1) {
        &:before {
          pointer-events: none;
        }
      }
    }
  }

  @media (min-width: 1024px) {
    .sections__item {
      display: block;
      width: 400px;
      margin: 1.5em auto 0;
      position: relative;
      &:before {
        content: "" !important;
        height: 3.6em;
        width: 3px;
        border-right: 1px solid black;
        top: 3em;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
        display: block !important;
      }

      .sections__children {
        left: 0px;
        margin: 2em auto;
        .sections__children__item {
          &:before {
            margin: 0;
            border: 0;
            height: auto;
          }
        }
        > .sections__children__item {
          width: 400px;
          &:nth-child(odd),
          &:only-child {
            a,
            .no-link {
              &:after {
                right: -3em;
                top: 1.5em;
              }
            }
          }
          &:nth-child(even) {
            a,
            .no-link {
              &:after {
                left: -3em;
                top: 1.5em;
              }
            }
          }
          &:nth-last-child(2):before,
          &:last-child:before {
            border: 0;
          }
          a,
          .no-link {
            left: 0;
            width: 100%;
            overflow: visible;
          }
          &:nth-child(odd) {
            margin: 1.5em 0 0 -18em;
          }
          &:nth-child(even) {
            left: 13.23em;
            margin-top: -4.5em;
          }
          &:nth-child(odd) {
            a,
            .no-link {
              &:after {
                border-right: 1px solid black;
                margin-right: -2px;
              }
            }
          }
          &:nth-child(even) {
            a,
            .no-link {
              &:after {
                margin-left: 0;
                margin-right: 0;
                margin-left: -2px;
              }
            }
          }

          &:nth-child(1),
          &:nth-child(2) {
            a,
            .no-link {
              &:after {
                content: "";
                height: 55px;
                width: 50px;
                display: inline-block;
                border-bottom: 1px solid black;
                text-align: left;
                position: absolute;
                margin-top: -3em;
                margin-right: -2px;
                z-index: 1;
                pointer-events: none;
              }
            }
          }

          &:nth-child(n + 3) {
            a:after,
            .no-link:after {
              content: "";
              height: 85px;
              width: 50px;
              display: inline-block;
              border-bottom: 1px solid black;
              text-align: left;
              position: absolute;
              margin-top: -5em;
              margin-right: -2px;
              z-index: 1;
              pointer-events: none;
            }
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-huge) {
    .sections__item
      .sections__children
      > .sections__children__item:nth-child(n + 3)
      a:before,
    .sections__item
      .sections__children
      > .sections__children__item:nth-child(n + 3)
      .no-link:before {
      content: "";
      height: 109px;
      width: 50px;
      display: inline-block;
      border-right: 1px solid black;
      text-align: left;
      position: absolute;
      right: -3em;
      margin-top: 0.5em;
      margin-right: -2px;
      z-index: 1;
      pointer-events: none;
    }
    ul.org-chart-sections {
      &:after {
        content: "";
        border-right: 1px solid black;
        min-height: 7em;
        position: absolute;
        margin-top: -3em;
        width: 4px;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        left: 0;
        right: 0;
      }
    }
  }

  /* Department/ section colors */
  .department.dep-a,
  .department.dep-b,
  .department.dep-c,
  .department.dep-d,
  .department.dep-e,
  .department.dep-f,
  .department.dep-g,
  .department.dep-h,
  .department.dep-i,
  .department.dep-j {
    .departments__item__parent--link {
      background: $color-pink;
    }
  }
  #departments {
    margin: 0;
  }
  .org-chart-departments {
    padding: 0;
    margin: 0;
  }
  .departments__item {
    margin: 1em 0 4em;

    @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-huge) {
      margin: 1em;
    }

    vertical-align: top;
    span {
      font-size: 14px;
      font-weight: 500;
    }
    .departments__item__parent--link {
      position: relative;
      border-bottom: 0;
      color: white;
    }
    .departments__item__parent {
      padding: 1em;
      margin-bottom: 8px;
      height: 5.5em;
      display: table;
      width: 100%;
      line-height: 1.2;
      .departments__item__parent--content {
        position: relative;
        vertical-align: middle;
        display: table-cell;
        text-align: center;
      }
    }
    .departments__children {
      display: block;
      margin: 0;
      padding: 0;
      .departments__children__item {
        a,
        .no-link {
          font-weight: normal;
          padding: 1em 5px;
          border-left: 2.5em solid white;
          border-bottom: 8px solid white;
          margin-bottom: 8px;
          background: $color-grey-light;
          span {
            color: black;
            line-height: 1.2;
          }
          &[href]:hover {
            background-color: $color-purple;
            transition: 0.25s;
          }
        }
        &:before {
          width: 40px;
          height: 75px;
          border-bottom: 1px solid black;
          border-left: 1px solid black;
          z-index: 2;
          display: block;
          content: "";
          margin-left: 0;
          position: absolute;
          margin-top: -4.6em;
          padding-top: 1.5em;
          box-sizing: content-box;
        }
        &:nth-child(1) {
          &:before {
            pointer-events: none;
          }
        }
      }
    }
  }

  @media (min-width: $breakpoint-desktop) and (max-width: $breakpoint-huge) {
    #departments {
      overflow: hidden;
    }
    .departments__item {
      width: 30%;
      display: inline-block;
      vertical-align: top;
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
    }
  }

  @media (min-width: $breakpoint-huge) {
    .org-chart-departments {
      display: table;
      width: 100%;
      display: table-row;
    }
    .departments__item {
      &:first-child {
        border-left: 0;
        &:after {
          content: "";
          background: white;
          display: block;
          position: absolute;
          top: -3em;
          width: 50%;
          height: 1em;
        }
      }
      &:last-child {
        border-right: 0;
        &:after {
          content: "";
          background: white;
          display: block;
          position: absolute;
          top: -3em;
          width: 50%;
          height: 1em;
          right: 0;
        }
      }
    }

    #departments:before {
      content: "";
      display: block;
      padding: 2em 0 0;
      margin-left: 95px;
      width: calc(100% - 95px);
      margin: 0 auto;
      width: 100%;
      border-top: 1px solid black;
    }
    .departments__item {
      width: 12%;
      display: table-cell;
      margin: 0 1em;
      border-left: 0.5em solid white;
      border-right: 0.5em solid white;
      &:before {
        content: "";
        width: 0;
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        height: 40px;
        border-left: 1px solid black;
        margin: -2em auto 0;
      }
      .departments__children__item {
        a,
        .no-link {
          border-left: 0;
        }
        &:before {
          border: none;
          display: none;
        }
      }
    }
  }

  #sections *,
  #departments * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    position: relative;
  }

  #sections,
  #departments {
    font-family: sans-serif;
    a:focus {
      border: 2px solid black;
    }
    ul {
      list-style: none;
      margin: 0 auto;
    }
    a,
    .no-link {
      font-family: "Berlingske Sans";
      display: block;
      text-align: center;
      text-decoration: none;
      line-height: 15px;
      min-height: 56px;
      font-size: 16px;

      &[href]:hover {
        background-color: $color-purple;
        transition: 0.25s;
        span {
          color: white;
        }
      }
    }
    ul.departments__children {
      background-color: #fff;
    }
  }
}

#sections a,
.no-link {
  min-height: 56px;
}

@media print {
  .organization .map-container {
    .sections__item {
      direction: initial;
      width: initial;
      margin: initial;
      position: initial;

      > a,
      > .no-link {
        padding: 1em;
        margin-bottom: 8px;
        height: 75px;
        display: flex !important;
        justify-content: center;
        align-items: center;
        > span {
          vertical-align: middle;
          display: table-cell;
          text-align: center;
        }
      }

      &:before {
        display: none;
      }
      .sections__children {
        left: initial;
        margin: initial;
        .sections__children__item {
          &:before {
            border-bottom: 1px solid black !important;
            border-left: 1px solid black !important;
            height: 100px;
            margin: -6.5em 0 -30px -40px;
          }
        }
        > .sections__children__item {
          width: initial;
          right: initial;
          top: initial;
          left: initial;
          &:nth-child(odd) {
            margin: initial;
          }
          &:nth-child(even) {
            left: inherit;
            margin-top: inherit;
          }
          a:after,
          .no-link:after {
            display: none !important;
          }
        }
      }
    }
  }

  .org-chart-departments {
    display: inherit;
    widows: auto;
  }
  .departments__item {
    width: 100% !important;
    display: block !important;
    margin: 1em 0 30px !important;
    &:before {
      display: none !important;
    }
  }

  .organization {
    max-width: 50%;
  }

  #departments:before {
    display: none !important;
  }
  .organization .map-container * {
    color: #000 !important;
    background-color: transparent !important;
  }
  .organization .map-container .departments__item {
    display: block;
    page-break-inside: avoid;
    &:last-child {
      page-break-after: avoid;
      margin-bottom: 0;
    }
  }

  #sections,
  #departments {
    a:not(.departments__item__parent--link),
    a.departments__item__parent--link > span:not(.departments__item__parent),
    .no-link:not(.departments__item__parent--link),
    .no-link > .departments__item__parent {
      border: 1px solid black;
    }
  }
  a.departments__item__parent--link > span.departments__item__parent {
    border: 1px solid black;
  }

  #departments {
    a:not(.departments__item__parent--link),
    a.departments__item__parent--link > span:not(.departments__item__parent),
    .no-link:not(.departments__item__parent--link) {
      margin-left: 40px;
    }
  }
}

.nuspecbuildtest {
  display: none;
}
