.block-extra-facts {
	background-color: $color-grey-lightest;
	margin-bottom: 40px;

	.sticky-bottom {
		background-color: $color-grey-lightest;
		padding-top: .5em;
	}
}

.block-extra-facts-expandable {
	background-color: $color-grey-light !important;
	margin-bottom: 20px !important;

	.content-expandable {
		padding: 20px 0px 0px 0px !important;
	}

	.expandable-link {
		color: $color-black;
		font-family: "Berlingske Serif";
		font-size: 1.375rem;
		margin-left: 0px;

		&:hover,
		&.active {
			color: $color-red;
		}
	}
}



.block-extra-facts-title {
	padding: 12px 40px 8px 5px;
	border-top: 1px solid $color-grey;
	border-bottom: 1px solid $color-grey;

	h2, h3 {
		margin: 0;
		border: none;
	}
}

a.block-extra-facts-trigger {
	color: $color-black;
	display: block !important;
	border: none;
	padding-left: 35px;
	margin-left: 0px !important;
	@include icon();
	@include icon-left();
	@include icon-plus();

	&:before,
	&:after {
		top: -2px;
		left: 0px !important;
	}

	&:hover,
	&.active {

		> h2, h3 {
			color: $color-red;
		}
	}

	> h2, h3 {
		border: none;
		color: $color-black;
	}

	&.active {
		@include icon-minus();
	}
}

.block-extra-facts-content {
	padding: 20px;

	@media #{$medium-up} {
		padding: 40px;
	}

	&:not(.bottom-padded) {
		padding-bottom: 0;
	}

	display: none;

	&.open {
		display: block;
	}
}