.alphabet {
  ul {
    margin: 0;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    @include clearfix();

    li {
      display: block;
      float: left;

      > a,
      > span {
        display: block;
        margin-right: 10px;
        margin-bottom: 10px;

        .form-area & {
          margin-right: 8px;
          margin-bottom: 8px;
          padding-left: 2px;
          padding-right: 2px;
          font-weight: 700;
          text-align: center;
          border-color: $color-grey;
          font-family: $fontSans;
          font-weight: 400;
        }

        @media #{$small-only} {
          margin-right: 10px;
          margin-bottom: 10px;

          .form-area & {
            min-width: 32px;
            padding-left: 2px;
            padding-right: 2px;
          }
        }
      }
      > a,
      > span {
        &.inactive {
          .form-area & {
            color: $color-grey-darker;
            background-color: transparent;
          }
        }
      }
      > span {
        padding-bottom: 1px;
      }
    }
  }
}
.alphabet-circles {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    @include clearfix();

    li {
      float: left;
      margin-right: 10px;
      margin-bottom: 10px;

      > span,
      a {
        @include number();
        @include number-small();
        background-color: #fff;
        color: $color-red;
        font-size: rem-calc(18px);
      }

      a.active,
      a:hover,
      a:focus {
        background-color: $color-purple;
        color: #fff;
      }

      .inactive {
        background-color: $color-grey-lightest;
        color: $color-black;
      }
    }
  }
}

.no-bullet {
  list-style: none;

  > li {
    list-style: none;
  }

  li {
    ul,
    ol {
      margin-left: 20px;
      margin-bottom: 0;
      list-style: none;
    }
  }

  &__item {
    margin-bottom: 1em;
  }
}

.listing {
  @media #{$large-down} {
    max-width: rem-calc(840);
    margin-right: auto;
    margin-left: auto;
  }
}

.listing-title {
  @include h1();

  @media #{$small-only} {
    margin-bottom: 25px;
  }

  @media #{$medium-up} {
    margin-bottom: 20px;
    font-size: rem-calc(30);
  }
}

.listing-header {
  margin-bottom: 20px;
}

.close-button {
  margin-top: 2em;
}

.tabs-listing-container {
  margin-bottom: 40px;
}

.tabs-listing-title-inner {
  display: none;
  margin-bottom: 20px !important;

  @media #{$large-up} {
    display: block;
  }
}

h2.tabs-listing-filter-trigger {
  @extend .listing-filters-trigger;
  margin-right: -1.25rem;
  margin-left: -1.25rem;
  padding-right: 1.25rem;
  padding-left: 1.25rem;
  padding-top: 20px;
  padding-bottom: 20px;
  border-top: 1px solid $color-grey-light;
  border-bottom: 1px solid $color-grey-light;
  @media #{$large-up} {
    display: none;
  }
}

.tabs-listing-filters-target {
  @extend .listing-filters-target;
  margin-top: 0 !important;
  margin-bottom: 0;
}

@media #{$large-down} {
  .listing-filters-trigger {
    margin-bottom: rem-calc(4);
    cursor: pointer;
    position: relative;

    &:after {
      position: absolute;
      content: "";
      background-image: url(../gfx/icons/arrow-down.svg);
      background-position: center;
      background-repeat: no-repeat;
      background-size: 15px;
      top: 50%;
      transform: translateY(-50%);
      width: 40px;
      height: 40px;
    }

    &:focus {
      border: 1px solid black;
    }

    &:focus-visible {
      border: 1px solid black;
    }

    &.active {
      margin-bottom: rem-calc(10);

      &:after {
        background-image: url(../gfx/icons/arrow-up.svg);
      }
    }
  }

  .listing-filters-target {
    display: none;
    &.open {
      display: block;
    }
  }
  .columns > [data-listing-filters-trigger] + .listing-filters-target.open,
  .columns > [data-travel-filters-trigger] + .listing-filters-target.open,
  .columns > [data-travel-filters-trigger] + .travel-list-top.open {
    margin-top: 30px;
  }

  .listing-filters-frame {
    @include clearfix;
    padding: 0 0 20px;
    @media #{$medium-only} {
      padding: 20px 0;
    }
  }
}
.listing-filters-trigger {
  @media #{$large-down} {
    margin-bottom: 0 !important;
  }
  @media #{$large-up} {
    margin-bottom: 10px;
    outline: none;
  }
}

.sortUrlWithArrow {
  display: block;
}

.listing-content {
    padding: 0;

    .article-content p {
        margin-bottom: 30px;
    }

    p {
        margin-bottom: 20px;
    }

    .committee-proceeding-list-notification-link {
        margin-top: -25px;
    }

    // lister med rød prikk test
    .listitem {
        > ul.no-bullet {
            > li {
                &:before {
                    display: none;
                }
            }
        }

        > ul:not(.listing-group-indent) {
            > li {
                position: relative;
                list-style-type: none;

                &:before {
                    content: "\00B7";
                    position: absolute;
                    font-size: 37px;
                    line-height: 1;
                    color: $color-black;
                    left: -15px;
                    top: -2px;

                    @media #{$medium-up} {
                        top: 2px;
                    }
                }
            }
        }
    }

    .listing-content-letter {
        @include number();
        @include number-large();
        background-color: $color-purple;
        color: #fff;
        margin-bottom: 20px;
    }

    .hits {
        font-weight: 500;
        margin-bottom: 20px;
    }

    > ul.listing-simple {
        > li.listitem {
            margin-bottom: rem-calc(25);

            .listitem-title {
                margin-bottom: 0;
            }
        }
    }
    // Listing simple hack
    li.listing-simple-listitem {
        margin-bottom: rem-calc(25) !important;

        .listitem-title {
            margin-bottom: 0 !important;
        }
    }

    > ul,
    > [id^="ctl"] > ul,
    > [id*="MainRegion"] > ul {
        margin-left: 0;
        padding-left: 0;
        list-style: none;

        .listitem {
            position: relative;
            margin-bottom: rem-calc(40);

            &.listitem-has-image {
                .listitem-title,
                .listitem-text {
                    margin-left: 0;
                }

                @media #{$medium-up} {
                    .listitem-text {
                        padding-left: 170px;
                    }
                }

                .listitem-image {
                    clear: left;
                    float: left;
                    margin: 10px 30px 30px 0;
                }

                @media #{$small-only} {
                    .listitem-text,
                    .listitem-title {
                        margin-left: 0;
                    }

                    .listitem-image {
                        float: none;
                        margin-right: 0;
                        margin-left: 0;
                        margin-bottom: 10px;
                    }
                }
            }

            &.line-below {
                margin-bottom: 10px;
                padding-bottom: rem-calc(16);
                border-bottom: 1px solid $color-grey;
            }
        }
    }

    .linklist {
        a {
            color: $color-black;

            &:hover,
            &:focus {
                color: $color-red;
            }
        }
    }

    .listing-speakers {
        margin-top: 20px;
        margin-bottom: 20px;
        border-top: 1px solid $color-grey;

        .listing-listitem {
            @include clearfix;
            position: relative;
            background-color: $color-grey-light;
            border-bottom: 1px solid $color-grey;

            .btn-toggler {
                position: absolute;
                top: 15px;
                left: 8px;
                background-color: transparent;
                border: none;
                padding: 0;

                &:after,
                &:before {
                    width: 30px;
                    height: 30px;
                    margin-left: 10px;
                }

                &:hover {
                    &:before {
                        background-image: url("../gfx/icons/toggle-open-hover.svg");
                        opacity: 1;
                    }

                    &:after {
                        background-image: url("../gfx/icons/toggle-close-hover.svg");
                        opacity: 1;
                    }
                }

                &:before {
                    background-image: url("../gfx/icons/toggle-open.svg");
                    content: "";
                    display: block;
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &:after {
                    background-image: url("../gfx/icons/toggle-close.svg");
                    content: "";
                    display: none;
                    background-size: 30px;
                    background-position: center;
                    background-repeat: no-repeat;
                }

                &.active {
                    &:before {
                        display: none;
                    }

                    &:after {
                        display: block;
                    }
                }

                &:focus {
                    outline: none;

                    &:before {
                        opacity: 1;
                    }
                }
            }

            .speaker-wrapper {
                > a {
                    padding: 10px;
                    border-bottom: none;

                    &:hover,
                    &:focus {
                        .speaker-name {
                            color: $primary-color;
                        }
                    }
                }

                @include clearfix;
                display: block;
                margin-left: 60px;
                padding: 15px 10px 15px 7px;
                font-size: rem-calc(18);
                font-weight: 700;
                color: #333;

                @media #{$small-only} {
                    font-size: rem-calc(16);
                }

                > span {
                    display: block;
                    float: left;
                }

                .speaker-name {
                    color: #333;
                }

                .speaker-name span {
                    border-bottom: 1px solid $color-grey;
                }

                .speaker-party {
                    font-weight: 900;
                    display: inline-block;
                }

                .speaker-time,
                .speaker-type,
                .speaker-type-small {
                    font-weight: 500;
                    color: #111720;
                }

                .speaker-time {
                    color: $primary-color;
                    padding-top: 0;
                    padding-bottom: 0;
                    margin-top: -2px;
                    border: none;

                    span {
                        border-bottom: 1px solid $color-grey;
                    }
                }

                .speaker-type-small {
                    display: none;
                }

                .ref-innlegg-video-disabled {
                    pointer-events: none;
                    display: none;
                    cursor: default;
                    color: #8e8e8f !important;

                    span {
                        border-bottom: 0 !important;
                    }

                    &:link {
                        color: #8e8e8f !important;
                        border-bottom: 0 !important;
                    }
                }

                @media #{$small-only} {
                    > a {
                        padding: 0;
                    }

                    .speaker-name,
                    .speaker-time {
                        margin-right: 5px;
                    }

                    .speaker-time {
                        clear: left;
                    }

                    .speaker-party {
                        float: none;
                    }

                    .speaker-mobile-break {
                        clear: both;
                        float: none;
                    }

                    .speaker-type {
                        display: none;
                    }

                    .speaker-type-small {
                        display: block;
                    }
                }

                @media #{$medium-up} {
                    .speaker-name {
                        width: 40%;
                    }

                    .speaker-party,
                    .speaker-time,
                    .speaker-type {
                        position: absolute;
                    }

                    .speaker-party {
                        left: 48%;
                    }

                    .speaker-time {
                        left: 60%;
                    }

                    .speaker-type {
                        right: 2%;
                    }
                }

                @media #{$large-up} {
                    .speaker-type {
                        right: 4%;
                    }
                }
            }

            ul {
                display: none;
            }

            &.open {
                background-color: #fff;

                ul {
                    display: block;
                    margin-left: 0;
                    padding-bottom: 10px;
                    padding-left: 0;
                    list-style: none;

                    a {
                        padding-top: 7px;
                        padding-bottom: 7px;
                        border-bottom: none;
                    }

                    @media #{$medium-up} {
                        li:first-child {
                            > a {
                                margin-top: 4px;
                            }
                        }
                    }
                }
            }
        }

        @media #{$small-only} {
            &:not(.open) .listing-listitem:nth-child(n + 6) {
                display: none;
            }
        }
    }

    .btn-listing-speakers-notexpandable-replikk {
        &:hover,
        &:focus {
            outline: auto;
            outline-color: black;
        }
    }

    .btn-listing-speakers-expandable-replikk {
        &:hover,
        &:focus {
            outline: auto;
            outline-color: black;
        }

        @media #{$small-only} {
            margin-right: 2%;
        }

        @media #{$medium-up} {
            margin-right: 2%;
        }

        @media #{$large-up} {
            margin-right: 4%;
        }
    }

    .listing-speakers-expandable {
        list-style: none;
        margin-top: 34px;
        margin-left: 0;
        margin-bottom: 0;

        .listing-listitem {
            background-color: $color-grey-light;
        }
    }

    @media #{$medium-up} {
        .toggler-small-only {
            display: none;
        }

        .toggler-small-only-btn {
            dipslay: none;
        }
    }

    .toggler-small-only-btn {
        background-color: white;
        padding-top: 10px;
    }

    .toggler-small-only {
        &:hover,
        &:focus {
            outline: auto;
            outline-color: black;
        }
    }

    .listing-group-indent {
        @media #{$small-only} {
            margin-left: 0;

            ul {
                margin-left: 15px;
            }
        }

        @media #{$medium-up} {
            margin-left: 30px;

            ul {
                margin-left: 30px;
            }
        }

        ul {
            padding-left: 0;
            list-style: none;
        }
    }

    .inline-links {
        a {
            display: inline-block;

            @media #{$medium-up} {
                margin-left: 6px;
            }
        }

        @media #{$small-only} {
            display: block;

            a {
                margin-right: 14px;
            }
        }
    }

    .listing-group-title {
        @include listing-group-title();
    }

    .listitem-title {
        margin-bottom: rem-calc(10);
        margin-top: 0;
        font-size: rem-calc(22);
        line-height: 1.9;

        @media #{$small-only} {
            font-size: rem-calc(18);
        }

        &h4 {
            @extend h4;
        }

        a {
            color: $color-black;

            &:hover,
            &:focus {
                color: $color-red;
            }
        }
    }

    .listing-alphabet-heading {
        @include listing-group-title();
        display: block;
        line-height: 1.9;
    }

    .tblwrp-minified .listing-alphabet-heading {
        margin-left: 0;
    }

    .listing-group-title,
    .listing-alphabet-heading {
        &.listing-heading-inverted {
            a {
                color: $color-black;

                &:hover,
                &:focus {
                    color: $color-red;
                }
            }
        }

        &.listing-heading-smaller {
            padding-top: 14px;
            padding-bottom: 12px;
            font-size: rem-calc(22);
        }
    }

    .listing-alphabet {
        font-family: $fontSerifText;

        > li {
            > ul {
                margin-top: 1em;
                margin-bottom: 3em;

                > li {
                    margin-bottom: 0.2em;

                    > a {
                        @include paragraph-article();
                        color: $color-black;

                        &:hover,
                        &:focus {
                            color: $color-red;
                        }
                    }
                }
            }
        }
    }

    .listing-representatives {
        font-family: $fontSans;

        > li {
            @media #{$small-only} {
                &:nth-child(2n + 1) {
                    clear: left;
                }
            }

            @media #{$medium-only} {
                &:nth-child(3n + 1) {
                    clear: left;
                }
            }

            @media #{$large-up} {
                &:nth-child(4n + 1) {
                    clear: left;
                }
            }

            &:last-child {
                float: left;
            }
        }

        > li {
            margin-top: 40px;
        }

        p {
            margin-bottom: 0;
        }

        .representative-portrait {
            margin-bottom: 10px;
        }

        a.representative-name {
            margin-right: -10px;
            font-weight: 700;
            color: $color-black;

            &:focus,
            &:hover {
                color: $color-red;
            }

            @media #{$medium-up} {
                margin-right: -20px;
            }
        }
    }

    .listing-representatives.portrait-download li {
        margin-left: -1.25rem !important;
    }

    a {
        .listitem-title span {
            @include link();
            color: $color-black;
        }

        &:hover,
        &:focus {
            .listitem-title span {
                color: $color-red;
            }
        }
    }

    .listitem-subtitle {
        @include small-title();
        margin-bottom: rem-calc(6);

        &.normal-font-weight {
            font-weight: 400;
        }
    }

    .listitem-text {
        margin-bottom: rem-calc(10);
        @include paragraph-article();

        p {
            margin-top: 0;
            margin-bottom: 0;
            font-size: inherit;
            font-family: $fontSerifText;
            line-height: 2;

            &.deadline + p {
                margin-top: rem-calc(6);
            }
        }
    }

    .listitem-type,
    .listitem-date {
        font-weight: 500;
        color: #767676;
    }

    .listing-week-number {
        font-weight: 600;
        text-transform: uppercase;
    }

    .listitem-footnote {
        font-size: rem-calc(14);
        margin-top: 10px;
        display: inline-block;
    }

    .descending,
    .ascending {
        position: relative;
        padding-right: 25px;
        color: $color-black;

        &:hover,
        &:active {
            color: $color-red;
        }

        &:after {
            position: absolute;
            margin-left: 3px;
            content: "";
            background-position: center;
            background-repeat: no-repeat;
            background-size: 15px;
            top: 50%;
            transform: translateY(-50%);
            width: 20px;
            height: 20px;
        }
    }

    .descending:after {
        background-image: url(../gfx/icons/arrow-up.svg);
    }

    .ascending:after {
        background-image: url(../gfx/icons/arrow-down.svg);
    }

    table {
        margin-bottom: 0;

        h3,
        h4 {
            line-height: 1.9;

            a {
                color: $color-black;

                &:hover,
                &:focus {
                    color: $color-red;
                }
            }
        }

        h4 {
            @include h3();
            font-size: rem-calc(16);
            line-height: inherit;
        }

        tr {
            &:nth-of-type(even) {
                background-color: #fff;
            }

            &.space-above {
                border-top: 40px solid #fff;
            }
        }
    }
}

.sortLink,
.sortlink {
  color: $color-black;
  font-weight: 700;

  &:hover,
  &:focus {
    color: $color-red;
  }
}

.listing-faq {
  padding: 15px 0 0 0;

  @media #{$large-up} {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  ul {
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    .btn-link {
      margin-top: 5px;
      padding: 0px 0 !important;
      font-family: inherit;
      font-size: inherit;
      font-weight: 500;
      margin-bottom: 20px;
      text-align: left;
      display: inline-block;
      line-height: 1.4;
      border-bottom: none;
      font-size: 20px;

      > span {
        @include link();
        color: $color-black;
      }

      &:focus,
      &:hover {
        background-color: transparent;

        > span {
          color: $color-red;
        }
      }

      &.active {
        background-color: transparent;
      }
    }

    [data-answer-id] {
      display: none;

      &.open {
        display: block;
      }
    }
  }
}

.ref-presidenten,
.ref-innlegg-navn {
  font-weight: 700 !important;
}

.ref-innlegg-navn {
  color: $color-black;
  border-bottom: none;

  &:hover,
  &:focus {
    color: $color-red;
    cursor: default;
  }
}

.ref-innlegg-video {
  font-weight: 700 !important;
  display: inline-block;
  border-bottom: none;

  span {
    @include link();
  }
}

.ref-uinnrykk,
.strtngt_a {
  .ref-innlegg-video-disabled {
    pointer-events: none;
    cursor: default;
    color: $body-font-color !important;

    span {
      border-bottom: 0;
      color: inherit;
    }

    &:link {
      color: $body-font-color !important;
      border-bottom: 0 !important;
    }
  }
}

.sr-hightlight {
  background-color: $color-highlight;
}

.list-group-title {
  @include listing-group-title();
  text-transform: capitalize;
  margin-left: -20px;
  margin-right: -20px;
}

ol.krnl_list_lower-alpha.type_alpha {
  list-style: lower-alpha;
  margin-left: 3.5em;

  > li {
    padding-left: 0.25em;
  }
}

ol.strtngt_liste.type_numgas,
ol.strtngt_liste.type_alfagas {
    li:first-child:before {
        content: '«';
        position: absolute;
        left: 3em;
    }
}

ul.strtngt_liste.type_strekgas{
    li:first-child:before {
        content: '«';
        position: absolute;
        left: 2.9em;
    }
}

.proceeding-list-page-notification-link {
    margin-top: 13px;
    margin-bottom: 8px;

    span {
        a {
            border-bottom: 1px solid #e3e2e2;
            border-bottom-color: rgba(200, 200, 200, 0.8);
            max-height: 26px;
        }
    }
}
