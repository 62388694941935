.skip-link {
  position: absolute;
  top: 0;
  left: -999em;
  text-align: center;
  border-bottom: none;
  &,
  &:focus {
    color: $color-black;
  }
  &:focus {
    right: 0;
    left: 0;
    z-index: 100;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: $primary-color;
    outline: none;
  }
}

.header {
  width: 100%;
  top: 0;
  z-index: 999;
  .mobile-nav-open &,
  .mobile-search-open & {
    z-index: 9999;
  }

  @media #{$large-down} {
    position: fixed;
  }

  @media #{$large-up} {
    position: relative;
    height: 130px;

    &:before {
      content: "";
      width: 100%;
      height: 100%;
      z-index: 3;
      position: absolute;
      background-color: #fff;
    }
    &.open ~ .content-container:before {
      opacity: 1;
      z-index: 100;
    }
  }

  .header-curtain {
    z-index: 0;
    right: 0;
    left: 0;
    z-index: 0;
    height: 0;
    background-color: #fff;
    transition: height 200ms ease-in-out;

    @media #{$large-up} {
      position: absolute;
      display: block;
    }
    @media #{$large-down} {
      position: fixed;
      .main-container.mobile-search-open &,
      .main-container.mobile-nav-open & {
        height: calc(100% - 80px) !important;
      }
    }
  }
}
@media #{$large-up} {
  .open ~ .content-container:before {
    transition: opacity 400ms ease-in-out;
    content: "";
    position: fixed;
    top: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    background: rgba(51, 51, 51, 0.8);
    z-index: 1;
    left: 0;
  }
}

.header-container {
  @media #{$large-up} {
    @include grid-row;
    position: relative;
    z-index: 4;

    .border {
      margin: 0;
      padding: 45px 0 30px 0;
      border-bottom: 1px solid #d8d1ca;
      body#startpage & {
        border: none;
      }
      @include clearfix();
    }
  }
}

body#startpage {
  .header.open {
    .header-container {
      .border {
        @media #{$large-up} {
          border-bottom: 1px solid #d8d1ca;
        }
      }
    }
  }
}

.header-actions {
  position: relative;
  z-index: 3;
  background-color: #fff;
  padding-left: 20px;
  padding-right: 20px;

  @media #{$medium-up} {
    margin-left: -20px;
    margin-right: -20px;
  }

  @media #{$large-up} {
    padding: 0;
    margin: 0;
    float: left;
    width: auto;
    background-color: none;
    .nav-primary-toggle {
      display: none;
    }
  }
  .nav-primary-toggle {
    position: absolute;
    top: 12px;
    padding-right: 0;
    font-family: inherit;
    font-size: 18px;
    color: $color-black;
    text-transform: uppercase;
    background-color: transparent;
    border: none;
    line-height: 1.4;
    padding-top: 5px;

    @media #{$large-down} {
      @include meta-title();
      padding: 2px 10px;
      top: 19px;
      right: 15px;
    }

    @media #{$small-only} {
      right: 5px;
      font-size: 14px;
    }

    .nav-primary-toggle-open {
      display: block;
      @media #{$large-down} {
        .main-container.mobile-nav-open & {
          display: none;
        }
      }
    }

    .nav-primary-toggle-close {
      display: none;
      color: $color-red;
      @media #{$large-down} {
        .main-container.mobile-nav-open & {
          display: block;
        }
      }
    }

    &:focus {
      outline: 1px solid black;
    }
    &:hover {
      > span {
        color: $color-red;
      }
    }
  }
}

.header-bar {
  @media #{$large-up} {
    display: block;
    float: right;
    z-index: 3;
  }
}

.no-csstransforms3d {
  .search-form {
    @media #{$large-down} {
      top: -100px;
    }
  }
}

.header .logo {
  display: inline-block;
  border: none;
  padding: 0;
  width: 152px;

  &:focus {
    border: 2px solid black;
    outline: none;
  }

  &:active {
    border: none;
    outline: none;
  }

  @media #{$large-up} {
    // Fix for menu
    @media (max-width: 1150px) {
      height: 55px;
      width: 170px;
    }

    @media (min-width: 1151px) {
      width: 209px;
    }

    @media #{$xlarge-up} {
      width: 209px;
    }
  }

  img {
    margin: 20px 0;
    width: 100%;
    height: auto;

    @media #{$large-up} {
      margin: 0;
      margin-top: -1px;
      margin-bottom: 1px;
    }
  }
}

body > form.active {
  position: relative;
  height: 100%;
}
.main-container {
  position: relative;
  padding-top: 80px;
  @media #{$large-up} {
    padding-top: 0;
  }

  @media #{$large-down} {
    &.mobile-search-open,
    &.mobile-nav-open {
      height: calc(100% - 80px);
      overflow: hidden;
    }
  }
}
