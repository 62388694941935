.tabs {
	margin-bottom: 0;
	margin-left: 0;
	padding-left: 0;
	list-style: none;

	&.calendar-tabs {
		@media #{$small-only} {
			display: flex;
			justify-content: space-between;
		}
	}

	li {
		display: inline-block;
		margin-right: 10px;
		margin-bottom: 20px;

		&:last-child {
			margin-right: 0;
		}
	}

	a:link, a:visited, button {
			display: block;
			padding: 8px 25px 8px;
			line-height: 1.4;
			font-weight: 500;
			border-bottom: none;
			background-color: rgba(255,255,255,0.5);
			color: $color-black;
			border: 1px solid $color-grey;
			border-radius: 25px;
			@media #{$small-only} {
				padding: .5em 15px;
			}
			&:hover{
				border-color: $color-grey-darker;
			}
			&:focus{
				border-color: $color-grey-dark;
				outline: none;
			}

			&.selected {
				position: relative;
				color: #fff;
				background-color: $color-blue;

				&:after {
					position: absolute;
					bottom: -17px;
					left: 50%;
					display: block;
					width: 0;
					height: 0;
					margin: 0 0 0 -10px;
					content: "";
					border: inset 10px;
					border-color: $color-blue transparent transparent transparent;
					border-top-style: solid;
				}
			}
		
	}
}
