// removed .form-area prefix
input[type="email"],
input[type="text"] {
  width: 100%;
  margin: 0;
  padding: 16px 16px 16px;
  border: none;
  background-color: #fff;
  border: 1px solid $color-grey;
  border-radius: 0;
  box-shadow: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;

  .block-color-lightgrey & {
    background-color: #fff;
  }

  &:hover {
    border-color: $color-grey-darker;
  }

  &:focus {
    border-color: $color-grey-dark;
    outline: none;
  }
}
