.toggle-search {
    position: absolute;
    top: 19px;
    color: #86786f;
    z-index: 9;
    padding: 0;
    background: none;
    border: none;
    display: block;
    @include meta-title();

    @media #{$large-down} {
        padding: 2px 10px;
        border-right: 1px solid $color-grey;
        border-left: 1px solid $color-grey;
        right: 79px;
    }

    @media #{$small-only} {
        right: 67px;
        font-size: 14px;
    }

    &:focus{
        outline: 1px solid black;
    }

    &.active,
    &:hover {
        color: $color-red;

        > span {
            color: $color-red;
            border-bottom: none;
        }
    }

    @media #{$large-up} {
        @include icon();
        @include icon-right();
        @include icon-search();

        @media (max-width: 1150px) {
            font-size: 14px;
        }

        &::before,
        &::after {
            right: 3px;
            top: 0;
        }
    }

    > span {
        color: $color-grey-dark;
        border-bottom: none;
    }

    .toggle-search-close {
        display: none;
        color: $color-red;
    }

    @media #{$large-down} {
        &.active .toggle-search-close {
            display: inline-block;
        }
    }

    .toggle-search-open {
        display: inline-block;
    }

    @media #{$large-down} {
        &.active .toggle-search-open {
            display: none;
        }
    }

    @media #{$large-up} {
        position: relative;
        top: auto;
        right: auto;
    }
}

.search-form {
    @media #{$large-up} {
        float: left;
        margin-left: 10px;
        border-right: 1px solid $color-grey-light;
        padding-right: 5px;
        margin-top: -5px;
    }
}

.search-container {
    display: none;
    width: 100%;
    background-color: #fff;
    padding: 40px 40px 60px;
    position: absolute;
    left: -9999px;
    opacity: 0;
    overflow-x: hidden;
    overflow-y: hidden;
    transition: opacity 500ms ease-in-out 500ms;

    @media #{$large-down} {
        height: calc(100vh - 80px);
    }

    @media #{$large-up} {
        padding: 0;
        top: 131px;
        padding-top: 40px;
    }

    &.open {
        display: block;
        transform: translate3d(0, 0, 0);
        left: 0;
        opacity: 1;

        @media #{$large-down} {
            transition: opacity 200ms ease-in-out;
            -webkit-overflow-scrolling: touch;
            border-top: 1px solid $color-grey;
        }

        @media #{$large-up} {
            transition: all 250ms linear;
            transition-property: height, opacity;
        }
    }

    .close-wrapper {
        display: none;

        @media #{$large-up} {
            @include clearfix();
            display: block;
            border-top: 1px solid #d8d1ca;
            position: absolute;
            bottom: 0px;
            right: 0;
            left: 0;
            margin: 0 20px;
            padding-top: 20px;
            margin-bottom: 20px;
        }
    }
}

.search-form-wrapper {
    position: relative;

    input.search-form-query {
        display: block;
        width: 100%;
        padding: 20px;
        margin: 0 0 20px 0;
        padding-right: 50px;
        background-color: #fff;
    }

    .search-form-submit {
        position: absolute;
        border: none;
        background: transparent;
        @include icon();
        @include icon-right();
        @include icon-search();
        position: absolute;
        width: 40px;
        height: 40px;
        right: 15px;
        top: 9px;

        &::before,
        &::after {
            right: 0;
            top: 5px;
        }

        &:focus {
            outline: thin dotted $color-red;
        }
    }
}

.ui-autocomplete {
    position: relative !important;
    z-index: 999 !important;
    background-color: #fff;
    width: 100%;
    margin: 0;
    list-style: none;
    list-style-type: none;
    width: 100%;
    padding-left: 13px;
    margin: 0;
    top: -10px !important;
    left: 0 !important;
    border: none;
    padding-top: 0;

    @media #{$large-up} {
        width: 230px;
    }

    @media #{$xlarge-up} {
        width: 400px;
    }

    li {
        margin: 0 0 2px 0 !important;

        a {
            display: block;
            padding: 7px 7px !important;
            border: none;
            font-size: 18px;
            font-family: $fontSerifText;
            color: #000;
            font-size: rem-calc(18px);

            strong {
                font-family: $fontSerif;
            }
        }

        &.ui-state-hover,
        &.ui-state-focus {
            border: none !important;
            background: none !important;

            a {
                color: $color-red;
                background-color: #fff;
                border-radius: 0;
                border: none;
                font-weight: normal;
                background: none;
                padding: 7px 7px !important;
            }
        }
    }
}

.close-search-container {
    margin: 0;
    float: right;
    margin-right: 0;
}
