.vote-status-title {
	padding: 15px 15px;
	margin: 0;
	background-color: $color-blue;
	color: #fff;
}

.vote-status-content {
	background-color: $color-grey-light;
	padding: 20px;

	@media #{$medium-up} {
		padding: 20px 60px;
	}

	@media #{$large-up} {
		padding: 40px 100px;
	}

	p {
		margin: 10px 0;
	}
}

.block-vote-status {
	position: relative;
	padding-top: 60px !important;
	.block-inner {
		padding: 30px;
	}
	.block-description-title {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		margin: 0;
		background-color: #333;
		color: #fff;
		padding: 15px 70px;
	}
}
