.block-events {
  font-family: $fontSans;
  font-weight: 500;
  margin-bottom: 50px;

  .alert-hearings {
    margin-top: 40px;
  }
  @media #{$medium-up} {
    margin-bottom: 0;
  }
  @media #{$large-up} {
    padding: 30px;
    background-color: $color-grey-light;
  }

  a {
    @media #{$large-up} {
      @include link-on-grey();
    }
  }

  .block-link {
    margin: 0;
  }

  .block-title {
    @include section-title();
    margin: 0 0 40px 0;
  }
  .whats-up-profile {
    .block-image-wrapper {
      display: block;
    }
  }
  ul.additional-links {
    margin: 0;
    padding: 0;
    margin-top: 50px;
    li {
      margin: 0 12px 15px 0;
      display: inline-block;
    }
  }
}

.block-events-list {
  margin: 0;
  padding: 0;
  li {
    overflow: hidden;
    margin: 0 0 30px 0;
    display: flex;
    &.alternate {
      margin: 20px 0;
    }
    &:empty {
      display: none;
    }
  }

  .block-link {
    margin-top: 0 !important;
  }

  .block-events-list-date {
    min-width: 80px;
  }
  .block-events-list-info {
    margin-left: 20px;
    @media #{$medium-up} {
      margin-left: 30px;
    }
  }
  .event-label {
    font-family: $fontSans;
    display: block;
    margin-bottom: 7px;

    &:last-child {
      margin: 0;
    }
  }
  .event-profile {
    display: block;

    margin: 10px 0 10px 0;
    max-width: 320px;
    > .profile-image-wrapper {
      margin-bottom: 10px;
    }
  }
  .event-profile-label {
    font-size: 14px;

    padding: 6px 10px 2px;
    position: relative;
    display: block;
    line-height: 1.6;
    background-color: $color-grey-light;
    @media #{$large-up} {
      background-color: #fff;
    }
  }
  .event-day {
    display: block;
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: 1px;
    font-size: rem-calc(14px);
  }
  .event-number {
    font-size: rem-calc(26px);
    font-family: $fontSerif;
    margin-left: 0;
  }
}

.block-events-alert {
  padding: rem-calc(20);
}
