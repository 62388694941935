button,
.button,
input[type="submit"] {
    display: inline-block;
    font-family: $fontSans;
    border: none;
    background-color: $color-grey-lightest;
    border: 1px solid $color-grey;
    color: $color-black;
    padding: 14px 20px;
    outline: none;
    line-height: rem-calc(20px);
    font-weight: 500;
    font-size: rem-calc(18px);
    border-radius: 0;


    &:hover,
    &:focus {
        background-color: darken($color-grey-lightest, 10%);
    }

    &.reset {
        padding: 0;
        background-color: transparent;
        border-bottom: none;
    }

    &.icon {
        padding: 15px 20px 15px 60px;

        @media #{$large-up} {
            padding: 20px 20px 20px 60px;
        }

        &.icon-right {
            padding: 15px 20px 15px 60px;

            @media #{$large-up} {
                padding: 20px 60px 20px 20px;
            }

            &::before,
            &::after {
                left: auto;
                right: 0px;
            }
        }
    }

    &.primary,
    &.submit {
        background-color: $color-blue-light;
        border-color: $color-blue-light;
        color: #fff;

        &:hover {
            background-color: $color-blue-light-hover !important;
        }

        &:focus {
            background-color: $color-blue-light-focus !important;
            border-color: $color-blue-light-focus;
        }
    }

    &.button-dropdown {
        position: relative;
        width: 100%;
        padding: 15px 16px 15px !important;
        text-align: left;
        color: $color-black;
        background-color: #fff;
        border-bottom: none;
        font-size: rem-calc(16);

        &::after {
            position: absolute;
            top: 50%;
            right: 10px;
            padding: 7px;
            content: "";
            background-color: transparent;
            pointer-events: none;
            background-image: url(../gfx/icons/arrow-right.svg);
            background-repeat: no-repeat;
            transform: rotate(90deg) translateX(-50%);
        }
    }

    &.button-dropdown-list {
        width: 100%;
        margin-left: -2px;
        padding: 2px;
        background-color: transparent;
        border-bottom: none;
    }

    &.button-select-trigger {
        position: relative;
        width: 100%;
        padding: 0 0 7px;
        font-size: rem-calc(16);
        text-align: left;
        white-space: nowrap;
        overflow-x: hidden;
        background-color: transparent;
        border: none;
        border-bottom: 2px solid $color-grey;

        &:hover,
        &:focus {
            border-bottom-color: $color-grey-darker;
        }

        &::after {
            position: absolute;
            top: 3px;
            right: 0;
            padding: 7px;
            content: "";
            background-color: transparent;
            pointer-events: none;
            background-image: url(../gfx/icons/arrow-right.svg);
            background-repeat: no-repeat;
            transform: rotate(90deg);
        }

        &.active:after {
            top: 0;
            transform: rotate(-90deg);
        }

        .selected,
        span {
            font-weight: 700;

            &::after {
                position: absolute;
                top: 0;
                right: 0;
                width: 35px;
                height: 100%;
                content: "";
                pointer-events: none;
                background: -moz-linear-gradient( left, rgba(236, 232, 229, 0) 0%, rgba(236, 232, 229, 1) 51%, rgba(236, 232, 229, 1) 100% ); // FF3.6-15
                background: -webkit-linear-gradient( left, rgba(236, 232, 229, 0) 0%, rgba(236, 232, 229, 1) 51%, rgba(236, 232, 229, 1) 100% ); // Chrome10-25,Safari5.1-6
                background: linear-gradient( to right, rgba(236, 232, 229, 0) 0%, rgba(236, 232, 229, 1) 51%, rgba(236, 232, 229, 1) 100% ); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ece8e5', endColorstr='#ece8e5',GradientType=1 ); // IE6-9
            }
        }

        &.on-white {
            .selected,
            span {
                &::after {
                    background: linear-gradient( to right, rgba(255, 255, 255, 0) 0%, rgb(255, 255, 255) 51%, rgb(255, 255, 255) 100% ); // W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fff', endColorstr='#fff',GradientType=1 ); // IE6-9
                }
            }
        }
    }
}

.button-redirect {
    display: inline-block;
    font-family: $fontSans;
    background-color: $color-blue-light;
    border-color: $color-blue-light;
    padding: 14px 20px;
    outline: none;   
    font-weight: 500;
    border-radius: 0;

    a {
        color: white;
        border-bottom: none;
        
    }

    &:hover {
        background-color: $color-blue-light-hover !important;
    }

    &:focus {
        background-color: $color-blue-light-focus !important;
        border-color: $color-blue-light-focus;
    }
}

.button-group {
    .button {
        display: block;
    }
}

.link-button {
    display: inline;
    background: none;
    border: none;
    color: $color-red;
    line-height: inherit;
    padding: 0 0 2px 0;
    border-bottom: 2px solid #e3e2e2;

    @media #{$large-up} {
        display: inline-block;
        border: none;
        background-color: #f5f5f5;
        border-bottom: 2px solid #d7d7d7;
        color: $color-black;
        padding: 10px 15px;
        outline: none;
    }

    &:hover,
    &:focus {
        color: darken($color-red, 10%);
        background-color: #eee;
    }

    .ie9 & {
        border-bottom: none;
    }
}

a.button-aligned-link {
    display: inline-block;
    margin-top: 13px;
}

.btn-text {
    padding: 0;
    text-align: left;
    border: none;
    background-color: transparent;
    position: relative;
    line-height: 1.2;
    margin-left: 0;
    &:focus,
    &:hover {
        background-color: transparent;
    }

    &.icon-left {
        padding-left: 35px;
    }
}

.btn-link {
    padding: 0;
    border: none;
    @extend .antialiased;
    @include link();
    background-color: transparent;
    font-family: $fontSans;

    &:focus,
    &:hover {
        background-color: transparent;
    }

    &--small {
        font-size: 1rem;
    }
}

.close-button {
    position: relative;
    font-family: $fontSans;
    font-weight: 500;
    font-size: 16px;
    background-color: transparent;
    border: none;
    padding-right: 50px;

    &::before {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        background-image: url(../gfx/icons/close.svg);
        width: 40px;
        height: 40px;
        background-size: 10px;
        border-radius: 50%;
        border: 1px solid $color-grey-darker;
        background-position: center center;
        background-repeat: no-repeat;
        right: 0px;
    }
    &::after {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        content: "";
        background-image: url(../gfx/icons/close-hover.svg);
        width: 40px;
        height: 40px;
        background-size: 10px;
        border-radius: 50%;
        border: 1px solid $color-grey-darker;
        background-position: center center;
        background-repeat: no-repeat;
        right: 0px;
        display: none;
    }

    &:hover,
    &:focus {
        color: $color-red;
        background: transparent;
        &::before {
            display: none;
        }
        &::after {
            display: block;
        }
    }
}

.btn-reset {
    @extend .btn-link;
    display: inline-block;
    margin: 14px 20px;
}

button[disabled],
html input[disabled] {
    opacity: 0.5;
}

button[disabled] {
    transition: padding-right 0.3s;

    &.waiting {
        opacity: 1;
        position: relative;
        padding-right: 50px;
        background-color: #999;
        border-color: #999;

        &:hover,
        &:focus {
            background-color: #999 !important;
            border-color: #999;
        }

        .label {
            position: relative;
            z-index: 3;
        }

        .spinner {
            position: absolute;
            z-index: 2;
            display: inline-block;
            width: 24px;
            height: 24px;
            top: 17%;
            margin-top: 3px;
            margin-left: 10px;
            opacity: 1;
            background: transparent 0 0 no-repeat;
            background-image: url("../gfx/spinner.svg");
            border-radius: 40%;
            pointer-events: none;
            -webkit-animation-name: spin;
            -webkit-animation-duration: 1200ms;
            -webkit-animation-iteration-count: infinite;
            -webkit-animation-timing-function: linear;
            -moz-animation-name: spin;
            -moz-animation-duration: 1200ms;
            -moz-animation-iteration-count: infinite;
            -moz-animation-timing-function: linear;
            -ms-animation-name: spin;
            -ms-animation-duration: 1200ms;
            -ms-animation-iteration-count: infinite;
            -ms-animation-timing-function: linear;
            animation-name: spin;
            animation-duration: 1200ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
        }
    }
}
@-ms-keyframes spin {
    from {
        -ms-transform: rotate(0deg);
    }
    to {
        -ms-transform: rotate(360deg);
    }
}
@-moz-keyframes spin {
    from {
        -moz-transform: rotate(0deg);
    }
    to {
        -moz-transform: rotate(360deg);
    }
}
@-webkit-keyframes spin {
    from {
        -webkit-transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
.submit-loader-container {
    display: flex;

    .submit-loader {
        display: none;
        margin-left: 20px;
        margin-top: 10px;
        img {
            width: 30px;
            height: 30px;
        }
    }
}
