.block-vote-list-title {
	position: relative;
	// padding-bottom: 10px;

	// font-size: rem-calc(38);

	@include section-title();
	margin-bottom: 10px;
	// &:before,
	// &:after {
	// 	position: absolute;
	// 	top: 30px;
	// 	left: 35px;
	// }
}

.block-vote-list {
	position: relative;
	margin: 0;
	padding: 0;
	list-style-type: none;
	overflow: hidden;
	@media #{$large-up} {
		//padding: 30px 0;
	}
	&:before {
		content: "";

		position: absolute;
		top: 0px;
		left: 28px;

		width: 4px;
		height: 100%;

		background-image: url(../gfx/icons/dot-space-vertical.svg);
		background-repeat: repeat-y;
		background-size: 3px 10px;

		z-index: 0;
		@media #{$large-up} {
			left: 38px;
		}
	}
	.case-step-status {
		position: relative;
		z-index: 2;
	}
	// .alternative-first {
	// 	&:after {
	// 		content: "";
	// 		background: #eee;
	// 		height: 60px;
	// 		width: 20px;
	// 		position: absolute;
	// 		bottom: -10px;
	// 		left: 50%;
	// 		transform: translate3d(-50%, 0 , 0);
	// 	}
	// }
	// .alternative-second {
	// 	&:before {
	// 		content: "";
	// 		background: #eee;
	// 		height: 60px;
	// 		width: 20px;
	// 		position: absolute;
	// 		top: 10px;
	// 		left: 50%;
	// 		transform: translate3d(-50%, 0 , 0);
	// 	}
	// }

	> li {
		@include clearfix();
		display: block;
		position: relative;
		//padding: 70px 0 50px;
		&:last-child {
			// padding: 0;
			padding-bottom: 0;
		}
		// &.case-step-status:last-child {
		// 	padding-top: 0;
		// }
	}
}

.vote-result-title{
	@include medium-title();
	margin-bottom: 10px;
}
