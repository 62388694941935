[data-layout="masonry"] {
	margin-right: rem-calc(5px);
	margin-left: rem-calc(5px);
	position: relative;
}
.masonry-item {
	width: 100%;
	padding-right: rem-calc(15);
	padding-left: rem-calc(15);
	padding-bottom: 30px;

	@media #{$medium-up} {
		width: 50%;
	}

	@media #{$large-up} {
		width: 33.33%;
	}

	> .block {
		margin-top: 0;
		margin-bottom: 0;
	}
}
