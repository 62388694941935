#netigate-fade {
    background-color:#c1c1c1;
    opacity: 0.65;
    filter:alpha(opacity=65);
    position:absolute;
    z-index: 9001;
    top:0px;
    left:0px;
    width:100%;
}

.netigate-wrapper {
    width: 50%;
}

.netigate-content {
    padding: 50px 110px 90px;
    background: #fff;
    h3 {
        font-family: $fontSerif;
        font-weight: bold;
        font-size: 22px;
    }
    p {
        font-family: $fontSerifText;
        font-weight: normal;
        font-size: 18px;
        line-height: 30px;
    }
    .logo-wrapper {
        margin-bottom: 50px;

        img {
            margin-left: -57px;
        }
    }
    .button-wrapper {
        .button {
            margin-right: 20px;
            color: #fff;
            font-family: $fontSans;
            font-size: 18px;
            font-weight: normal;
            border: none;

            &-yes {
                background-color: $color-green;
                &:hover {
                    background-color: #3a744b;
                }
            }
            &-no {
                background-color: $color-red;
                &:hover {
                    background-color: #9c0d26;
                }
            }
        }
    }
}