.county-distribution {
  overflow: hidden;
  padding: 20px;
  margin: 0 0 40px 0;
  background-color: $color-grey-light;

  @media #{$large-up} {
    padding: 40px;
  }

  p {
    font-family: $fontSerif;
    line-height: rem-calc(28);
  }

  .map-container {
    display: none;
    @media #{$large-up} {
      display: block;
    }
  }

  .county-distribution-list {
    margin: 0;
    padding: 0px;
    list-style: none;
    &:first-child {
      @media #{$large-up} {
        padding-left: 80px;
        border-left: 1px solid $color-grey;
        margin-left: -20px;
      }
    }

    @media #{$medium-up} {
      float: left;
      padding: 20px 40px 0 0;
    }
    @media #{$large-up} {
      float: left;
      padding: 65px 0 65px 60px;
    }
    li {
      margin: 0 0 5px 0;
    }
    a {
      @include link($color-grey);
      color: $color-black;
    }
  }

  .map-rep-count {
    display: none;
    @media #{$large-up} {
      display: block;
      position: absolute;
      left: 310px;
      top: 200px;
    }
  }

  .rep-title {
    @media #{$large-up} {
      font-size: rem-calc(34);
      line-height: 1.2;
      font-weight: bold;
    }
    margin: 0;
  }

  .rep-count {
    @media #{$large-up} {
      font-family: $fontSerif;
      font-size: rem-calc(22);
    }
  }
}

.county-distribution-summary {
  max-width: 60%;
  margin: 0 auto 40px auto;
}
