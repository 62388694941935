﻿.proceeding-page {
    .space-between-bell-and-text {
        margin-right: 10px;
    }

    .proceeding-list-page-notification-link {
        margin-top: 13px;
        margin-bottom: 8px;

        span {
            a {
                border-bottom: 1px solid #e3e2e2;
                border-bottom-color: rgba(200,200,200,0.8);
                max-height: 26px;
            }
        }
    }
}
