/*
 * Playtype Foundry
 *
 * Berlingske Serif
 * Bold
 *
 * Berlingske Serif Text
 * Regular
 * Italic
 * Bold
 *
 * Berlingske Sans
 * Regular
 * Italic
 * Medium
 * Bold
 *
 */

@font-face {
	font-family: 'Berlingske Serif';
	src:    url('../fonts/berlingske/BerlingskeSerif-Bd.eot');
	src:    url('../fonts/berlingske/BerlingskeSerif-Bd.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSerif-Bd.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSerif-Bd.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Berlingske Serif Text';
	src:    url('../fonts/berlingske/BerlingskeSerifText-Rg.eot');
	src:    url('../fonts/berlingske/BerlingskeSerifText-Rg.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSerifText-Rg.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSerifText-Rg.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Berlingske Serif Text';
	src:    url('../fonts/berlingske/BerlingskeSerifText-It.eot');
	src:    url('../fonts/berlingske/BerlingskeSerifText-It.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSerifText-It.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSerifText-It.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Berlingske Serif Text';
	src:    url('../fonts/berlingske/BerlingskeSerifText-Bd.eot');
	src:    url('../fonts/berlingske/BerlingskeSerifText-Bd.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSerifText-Bd.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSerifText-Bd.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: 'Berlingske Sans';
	src:    url('../fonts/berlingske/BerlingskeSans-Rg.eot');
	src:    url('../fonts/berlingske/BerlingskeSans-Rg.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSans-Rg.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSans-Rg.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Berlingske Sans';
	src:    url('../fonts/berlingske/BerlingskeSans-It.eot');
	src:    url('../fonts/berlingske/BerlingskeSans-It.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSans-It.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSans-It.woff') format('woff');
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: 'Berlingske Sans';
	src:    url('../fonts/berlingske/BerlingskeSans-Md.eot');
	src:    url('../fonts/berlingske/BerlingskeSans-Md.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSans-Md.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSans-Md.woff') format('woff');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Berlingske Sans';
	src:    url('../fonts/berlingske/BerlingskeSans-Bd.eot');
	src:    url('../fonts/berlingske/BerlingskeSans-Bd.eot?#iefix') format('embedded-opentype'),
					url('../fonts/berlingske/BerlingskeSans-Bd.woff2') format('woff2'),
					url('../fonts/berlingske/BerlingskeSans-Bd.woff') format('woff');
	font-weight: 700;
	font-style: normal;
}
