.form-filter {
  display: none;
}

.horinger-filter {
  display: none;
  position: absolute;
  z-index: 1;
  right: rem-calc(20px);
  left: rem-calc(20px);
  margin-top: 5px;
  background-color: #fff;
  border: 1px solid $color-grey-light;
  box-shadow: 0 0 20px rgba(105, 98, 93, 0.25);

  @media #{$medium-up} {
    min-width: 354px;
  }

  &.horinger-filter-padding {
    padding: 18px 13px;
  }

  &.open {
    display: block;
  }

  .nowrap {
    white-space: nowrap;
  }

  .col-dropdown {
    float: left;
    margin-top: 20px;
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }

  .custom-dropdown select {
    padding-right: 40px;
  }

  ul {
    list-style: none;
    margin-left: 0;
    padding-left: 0;

    li + li {
      margin-top: 5px;
    }
  }

  .button {
    font-size: rem-calc(16);
    padding: 2px 4px 1px;
    text-align: left;
    background-color: #fff;
    border: none;
    border-radius: 2px;

    &:hover,
    &:focus {
      background-color: $color-grey-light;
    }

    &.current {
      color: $color-red;
      font-weight: 700;

      &:hover,
      &:focus {
        background-color: #fff;
        cursor: default;
      }
    }

    &.button-tab-trigger {
      position: relative;
      padding: 10px 5px;
      font-size: rem-calc(16);
      text-align: left;
      background-color: transparent;
      border: none;

      &:hover,
      &:focus {
        background-color: $color-grey-lightest;
      }

      &:after {
        content: "";
        position: absolute;
        right: 10px;
        left: 10px;
        bottom: 0;
        border-bottom: 2px solid transparent;
      }
      &.active:after {
        border-bottom-color: $color-red;
      }

      &:not(.active) {
        &:hover,
        &:focus {
          &:after {
            border-bottom: 2px solid $color-grey;
          }
        }
      }
    }
  }

  .form-area & {
    input[type="checkbox"] + label {
      font-size: rem-calc(14px);
      line-height: 1.2;

      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }

  .filter-subheader {
    background-color: $color-grey-lightest;

    .button {
      position: relative;
      padding: 18px 15px 15px;
    }
  }

  .horinger-subfilter {
    display: none;
    padding: 0 15px 20px;
    background-color: #fff;

    &.open {
      display: block;
    }
  }
}

.hearing-details {
  @media #{$large-up} {
    padding-left: 30px;
  }
  @media #{$medium-up} {
    border-left: 1px solid $color-grey-darker;
  }
}

.hearing-details-skriftlig {
  @media #{$large-up} {
    padding-left: 30px;
  }

  @media #{$large-up} {
    border-left: 1px solid $color-grey-darker;
  }
}

.hearing-details-list-skriftlig {
  @extend .clearfix;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;

  @media #{$medium-only} {
    margin-top: 20px;
  }

  + .hearing-details-list {
    margin-top: 20px;
  }

  @media #{$medium-up} {
    display: block;
  }
}

.hearing-details-status {
  display: block;
  font-weight: 500;
  color: $color-grey-dark;
  font-style: italic;
  display: block;
}

.hearing-details-video {
  .icon:first-child {
    line-height: inherit;

    &::before,
    &::after {
      margin-top: -4px;
    }
  }
}

.hearing-sub-status {
  @include small-title();
}

.hearing-details-list {
  @extend .clearfix;
  margin-left: 0;
  padding-left: 0;
  list-style-type: none;

  @media #{$medium-up} {
    display: flex;
    justify-content: space-between;
  }

  @media #{$medium-only} {
    margin-top: 20px;
  }

  + .hearing-details-list {
    margin-top: 20px;
  }

  > li {
    @media #{$medium-up} {
      width: 23%;
    }

    &.hearing-details-date {
      font-weight: bold;
    }
  }
}

.hearings-status-small-screens {
  margin-bottom: 10px;

  @media only screen and (min-width: 45em) {
    display: none;
  }
}
.hearings-status-large-screens {
  @media only screen and (max-width: 44.9375em) {
    display: none;
  }
}

.hearing-note-form-button-row {
  input:not(:last-child) {
    margin-right: 40px;
  }
}

.hearings {
  ul.hearing-note-list,
  ul.hearing-statement-list {
    list-style: none;
    display: block;
    margin-bottom: 0;
    margin-left: 0;

    li {
      list-style: none;
      position: relative;
      background: url("../gfx/icons/bullet.svg") no-repeat left 0.9rem;
      padding-left: 15px;
      margin-left: -15px;
      margin-bottom: 0;
    }
  }

  .hearing-pre-title {
    margin-bottom: 0;
    font-weight: bold;

    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }

  .hearing-document-reference {
    @media #{$medium-up} {
      font-size: rem-calc(16);
    }
  }

  .hearing-committee {
    margin-top: 20px;

    @media #{$medium-up} {
      font-size: rem-calc(18);
    }
  }

  .hearing-title {
    margin-bottom: 20px;
  }

  .linklist {
    li {
      margin-bottom: 0;

      + li {
        margin-top: 5px;
      }
    }

    .case-link {
      margin-bottom: 3px;
    }

    .case-docref {
      margin-bottom: 3px;
    }
    &.space-between li + li {
      margin-top: 20px;
    }

    + .hearing-subtitle {
      margin-top: 30px;
      font-family: "Berlingske Sans";

      @media #{$medium-up} {
        margin-top: 50px;
      }
    }
  }

  .registration-not-possible {
    margin-bottom: 0;
    font-style: italic;
  }
}

.hearing-live-wrapper {
  margin-bottom: 3px;
}

.hearing-live {
  padding: 8px 12px 4px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.3px;
  text-transform: uppercase;
  color: white;
  background-color: $color-red;
}

.listing-content {
  .listing-hearings {
    font-size: rem-calc(16);

    .listitem {
      position: relative;
      margin-bottom: 30px;

      &:before {
        display: block;
        position: absolute;
        top: -22px;
        right: rem-calc(20);
        left: rem-calc(20);
        border-top: 1px solid $color-grey;
      }

      @media #{$medium-up} {
        margin-bottom: 40px;
      }

      &.hidden-month,
      &.hidden-committee,
      &.hidden-deadline {
        display: none;
      }
    }

    [id^="scrollID_"] + .listitem:before {
      top: auto;
      right: auto;
      left: auto;
      border-top: none;
    }

    @media #{$medium-up} {
      .listing-group-title {
        margin-right: 0;
        margin-left: 0;
      }
    }

    .listing-week-number {
      padding-right: 20px;
      padding-left: 20px;
    }

    .listitem-date {
      font-family: inherit;
      font-size: rem-calc(16);
      color: $color-black;
    }

    .listitem-link {
      margin-bottom: 7px;
      font-family: $fontSans;
      font-size: rem-calc(16);

      a {
        font-weight: 600;
      }
    }

    .hearing-document-reference,
    .listitem-committee,
    .listitem-place {
      margin-bottom: 5px;
      -webkit-font-smoothing: auto;
    }

    .listitem-committee {
      color: $color-grey-dark;
    }

    .listitem-place {
      color: $color-black;
      @media only screen and (max-width: 44.9375em) {
        .linebreak-small-screen {
          display: block;
        }
      }
      @media only screen and (min-width: 45em) {
        b:before {
          font-weight: normal;
          content: " – ";
        }
      }

      .listitem-status:before {
        content: " – ";
      }
    }

    .listitem-status {
      color: $color-grey-dark;

      .divider {
        margin-top: 0;

        + .divider {
          margin-top: 20px;
        }
      }

      .deadline {
        margin-top: 10px;
        font-size: rem-calc(14);
        color: $color-black;
      }

      .participate {
        padding-bottom: 12px;
        font-size: rem-calc(16);
      }

      .button.primary {
        margin-top: -8px;
        padding: 8px 10px 6px;
        font-size: rem-calc(16);

        @media #{$large-up} {
          width: 100%;
          text-align: center;
        }
      }
    }

    .listitem-live {
      display: inline-block;
      margin-bottom: 10px;
      padding: 5px 15px 3px;
      font-size: rem-calc(14);
      font-weight: 500;
      letter-spacing: 1px;
      text-transform: uppercase;
      color: #fff;
      background-color: $color-red;
    }
  }
}

.hidden-target {
  display: none;

  &.open {
    display: block;
  }
}

.loading-message {
  &:after {
    overflow: hidden;
    display: inline-block;
    vertical-align: bottom;
    -webkit-animation: ellipsis steps(4, end) 1500ms infinite;
    animation: ellipsis steps(4, end) 1500ms infinite;
    content: "\2026"; /* ascii code for the ellipsis character */
    width: 0px;
  }

  &.hidden {
    display: none;
  }
}

@keyframes ellipsis {
  to {
    width: 20px;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 20px;
  }
}

.date-deadline {
  color: $color-black;
  font-size: rem-calc(14);
  margin-top: 5px;

  span {
    font-weight: 700;
  }
}
