﻿.vcenter-item {
  display: block;
  align-items: center;
  margin: 0 13px;
  width: 95%;

  @media #{$medium-up} {
    display: flex;
    align-items: center;
    margin: auto;
    width: 100%;
  }
}

.small-padding-column {
  display: inline-block;
  margin-top: 2px;
  margin-bottom: 2px;
}

.content-tag-wrapper {
  padding-right: 0;
  display: flex;
  flex-flow: row;
  justify-items: left;
  width: 100%;

  @media #{$medium-up} {
    margin: 0 1em;
  }

  .content-tag-link-wrapper {
    width: 100%;
    display: block;
    align-self: center;

    .border-right {
      display: inline-block;
      border: 0;
      margin-right: 14px;
      padding-right: 16px;
      margin-bottom: 1em;
      margin-top: 2px;
      margin-bottom: 2px;

      @media #{$medium-up} {
        border-right: 1px solid rgba(129, 117, 110, 0.5);
      }
    }
  }

  #content-group-image {
    margin-right: 12px;
    align-self: center;
    float: left;
    width: auto;
    height: 47px;
    max-width: none;
  }
}

.button-content-tag-trigger {
  position: relative;
  padding: 0 1.1em 0 0;
  font-size: 1rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: hidden;
  background-color: transparent;
  border: none;
  color: rgb(125, 112, 104);

  &:hover {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
    outline: 1px solid black;
  }

  &::after {
    position: absolute;
    top: 3px;
    right: 0;
    padding: 7px;
    content: "";
    background-color: transparent;
    pointer-events: none;
    background-image: url(../gfx/icons/arrow-right-gray.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }
}

.button-content-tag-trigger.active::after {
  transform: rotate(-90deg);
}

.contenttargetgroup-bar {
  margin-top: 1em;
  display: none;
  position: absolute;
  z-index: 1;
  background-color: #fff;
  border: none;
  font-family: "Berlingske Sans";
  box-shadow: 0 0 20px rgba(105, 98, 93, 0.25);

  &.open {
    display: block;
    left: 0;

    @media #{$medium-up} {
      left: auto;
    }
  }

  .nowrap {
    white-space: nowrap;
  }

  .content-group-link {
    display: inline-block;
    color: rgb(17, 24, 32);
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    border: none;
    padding: 9px;
  }

  ul {
    list-style: none;
    margin: 16px 0px;

    li {
      padding: 0px 4em 0px 0px;
      margin: 0 15px 0 14px;

      &:hover {
        background-color: #ece8e5;
      }
    }

    a {
      white-space: pre-wrap; /* css-3 */
      white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      word-wrap: break-word;
    }
  }

  .button {
    font-size: rem-calc(16);
    padding: 2px 4px 1px;
    text-align: left;
    background-color: #fff;
    border: none;
    border-radius: 2px;

    &:hover,
    &:focus {
      background-color: $color-grey-light;
    }

    &.current {
      color: $color-red;
      font-weight: 700;

      &:hover,
      &:focus {
        background-color: #fff;
        cursor: default;
      }
    }

    &.button-tab-trigger {
      position: relative;
      padding: 10px 5px;
      font-size: rem-calc(16);
      text-align: left;
      background-color: transparent;
      border: none;

      &:hover,
      &:focus {
        background-color: $color-grey-lightest;
      }

      &:after {
        content: "";
        position: absolute;
        right: 10px;
        left: 10px;
        bottom: 0;
        border-bottom: 2px solid transparent;
      }

      &.active:after {
        border-bottom-color: $color-red;
      }

      &:not(.active) {
        &:hover,
        &:focus {
          &:after {
            border-bottom: 2px solid $color-grey;
          }
        }
      }
    }
  }

  .form-area & {
    input[type="checkbox"] + label {
      font-size: rem-calc(14px);
      line-height: 1.2;

      &:before {
        width: 20px;
        height: 20px;
      }
    }
  }

  .filter-subheader {
    background-color: $color-grey-lightest;

    .button {
      position: relative;
      padding: 18px 15px 15px;
    }
  }

  .horinger-subfilter {
    display: none;
    padding: 0 15px 20px;
    background-color: #fff;

    &.open {
      display: block;
    }
  }
}

.hidden-target {
  display: none;

  &.open {
    display: block;
  }
}
