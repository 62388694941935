.vote {
    .vote-bar {
        background-color: $color-grey-light;
        padding: 10px;
        overflow: hidden;

        @media #{$large-up} {
            padding: 10px 20px;
        }
    }

    .vote-title {
        float: left;
        margin: 0;
    }

    .vote-number {
        @include number();
        @include number-small();
        background-color: $color-black;
        color: #fff;
        margin-right: 10px;

        @media #{$large-up} {
            margin-right: 40px;
        }
    }

    .vote-date {
        float: right;
        line-height: 40px;
        font-size: rem-calc(12);

        @media #{$large-up} {
            font-size: inherit;
        }
    }

    .vote-result {
        position: absolute;
        left: 10px;
        top: 15px;
        @include number();
        @include number-small();
        font-family: $fontSans;
        color: #fff;
        text-transform: uppercase;
        font-size: rem-calc(14);
        font-weight: bold;

        @media #{$large-up} {
            left: 20px;
            top: 35px;
        }

        &.vote-for {
            background-color: $color-green-new;
        }

        &.vote-against {
            background-color: $color-red;
        }
    }

    .vote-content {
        display: flex;
        flex-flow: column;
        position: relative;
        padding: 20px 20px 20px 60px;

        @media #{$large-up} {
            flex-flow: row;
            padding: 40px 20px 40px 80px;
        }
    }

    .vote-content-section {
        margin-bottom: 20px;

        @media #{$large-up} {
            width: 33%;
            padding: 0 20px;
            margin: 0;
        }
    }

    .vote-actions {
        li {
            display: inline;
            margin: 0 10px 0 0;
        }
    }

    .vote-link {
        display: inline-block;
        background: #fff;
        border: 1px solid $color-grey;
        color: #B20019;
        padding: 8px 16px;
        margin: 0 0 20px 0;
        font-weight: 400;
        font-size: rem-calc(16);
        text-align: left;

        &:hover {
            background-color: #ece8e5;
            color: darken($color-red, 10%);
        }
    }

    .vote-mobile {
        .block-vote-result-count {
            margin-bottom: 5px;
        }

        @media #{$medium-up} {
            display: none;
        }
    }

    .block-vote-result-adopted {
        &:before {
            display: inline-block;
            content: "";
            width: 10px;
            height: 10px;
            background-color: $color-green;
            border-radius: 50%;
            margin: 0 5px 0 0;
        }
    }
}

.vote-graph {
  display: none;
  position: relative;
  border-left: 2px solid $color-grey-light;
  border-bottom: 2px solid $color-grey-light;
  min-height: 150px;
  margin-bottom: 20px;
  @media #{$medium-up} {
    display: block;
  }
  .block-vote-result-title {
    margin: 0 0 0 15px;
  }
  .graph-col {
    position: absolute;
    bottom: 0;
    left: 15px;
    width: 70px;
    height: 0;
    background-color: #eeeeef;
    &:last-child {
      left: 100px;
    }
    &.graph-col-for {
      background-color: $color-green-new;
    }
    &.graph-col-against {
      background-color: $color-red;
    }
    .count {
      position: absolute;
      bottom: -30px;
      display: block;
    }
  }
}
