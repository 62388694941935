.case-overview {
  background-color: $color-grey-light;
  padding: 20px;
  overflow: hidden;
  margin: 0 0 60px 0;
  @media #{$medium-up} {
    padding: 40px;
  }
  .case-overview-list {
    overflow: hidden;
    margin: 0;
    @media #{$large-up} {
      margin: 0 40px 0 -20px;
    }
    li {
      a {
        position: relative;
        display: block;
        color: #000;
        border: none;
        padding: 0 0 15px 70px;
        overflow: hidden;
        overflow: visible;
        @media #{$large-up} {
          padding: 0;
          text-align: center;
        }
        &:after {
          content: "";
          position: absolute;
          top: 45px;
          background-image: url(../gfx/icons/dot-space-vertical.svg);
          @media #{$large-up} {
            background-image: url(../gfx/icons/dot-space-horizontal.svg);
          }
          background-repeat: repeat-y;
          background-size: 3px 9px;
          width: 3px;
          height: 100%;
          left: 26px;
          z-index: 1;
          @media #{$large-up} {
            background-repeat: repeat-x;
            background-size: 9px 3px;
            top: 30px;
            left: 80px;
            width: 200px;
            height: 3px;
          }
          @media screen and (min-width: 1200px) {
            width: 218px;
          }
          @media #{$xlarge-up} {
            left: 45px;
            width: 150px;
          }
        }
        .number {
          display: block;

          background-color: $color-purple;
          border: 2px solid $color-purple;
          color: #fff;
          border-radius: 50%;

          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
          transition: all 400ms ease-in-out;

          @include number();
          @include number-large();
          @media #{$large-up} {
            position: relative;
            margin: 0 auto 10px;
          }
        }
        .inline {
          @media #{$small-only} {
            height: 50px;
            display: block;
            padding-top: 18px;
          }
          span {
            color: $color-black;
            border-bottom: 1px solid $color-grey;
            line-height: 1.4;

            @media #{$medium-up} {
              line-height: 55px;
            }
            @media #{$large-up} {
              line-height: 1.4;
            }
          }
          @media #{$large-up} {
            display: inline-block;
            width: 100%;
            padding: 0 0 3px 0;

            span {
              display: inline;
              font-size: rem-calc(16);
            }
          }
        }
        &:focus,
        &:hover {
          .inline {
            span {
              color: $color-red;
            }
          }
          .number {
          }
        }
        &.current {
          .number {
            background-color: $color-blue;
            border-color: $color-blue;
          }
        }
        &.future,
        &.skip {
          .number {
            background-color: $color-grey-light;
            border-color: $color-blue;
            color: $color-blue;
          }
        }
      }
      &:last-child a:after {
        display: none;
      }
    }
  }
}

.case-overview-title {
  @include section-title();
  margin: 10px 15px 25px 0px;
}

.case-overview-subtitle {
  color: $color-black;
  margin: 10px 15px 25px 0;
}

.case-overview-button {
  @media #{$large-up} {
    float: left;
    clear: left;
  }
}

.case-overview-steps {
  @media #{$xlarge-up} {
    width: 66.66%;
    float: left;
    padding-right: 40px;
  }
}

.case-overview-status {
  @media #{$xlarge-up} {
    width: 33.33%;
    float: right;
  }
}

.case-overview-status-internal-link {
  margin-bottom: 20px;
}

.case-overview-status-title {
  background-color: $color-blue;
  color: #fff;
  padding: 20px;
  font-family: $fontSerif;
  font-size: rem-calc(22);
  margin: 0 0 15px 0;
}

.case-overview-status-content {
  margin-left: 20px;
}

.case-overview-status-text {
  display: block;
  color: $color-black;
  font-family: $fontSans;
  margin: 0 0 5px 0;
  @media #{$large-up} {
    margin: 0 0 15px 0;
  }
}
.case-overview-status-date {
  display: block;
  margin: 0 0 10px 0;
  color: $color-black;
  font-family: $fontSans;
  font-style: italic;
}
