.profile{

  .profile-image-wrapper{
    margin-bottom: 30px;
  }
  .profile-text-wrapper{
    text-align: center;
  }
  .profile-name{
    display: inline-block;
    margin-bottom: 10px;
    @include link($color-grey);
  }
  .profile-party{
    display: block;
    text-align: center;
  }
  .profile-position{
    display: block;
    text-align: center;
  }
}

.profile-list{
  margin-top: 40px;
}
