.portraits-carousel-wrapper {
	@include clearfix();
	overflow: hidden;
	margin-bottom: 20px;
	padding-top: 30px;
	background-color: $color-grey-light;

	@media #{$large-up} {
		margin-bottom: 50px;
		padding-top: 40px;
		padding-right: 20px;
		padding-left: 20px;
	}

	@media #{$large-up} {
		border-width: 16px;
	}
}

.portraits-carousel {
	@include clearfix();

	.portraits-listing {
		@include clearfix();
		margin-bottom: 0;
		margin-left: 0;
		white-space: nowrap;

		@media #{$medium-up} {
			white-space: normal;
		}

		li {
			display: inline-block;
			vertical-align: top;
			width: 120px;
			margin: 0 8px 30px;

			@media #{$small-only} {
				max-height: 140px;
			}

			@media #{$medium-up} {
				width: 152px;
				margin-right: 13px;
				margin-left: 13px;
				margin-bottom: 40px;
			}

			@media #{$large-up} {
				margin-right: 8px;
				margin-left: 8px;
			}

			.portrait {
				width: 80%;
				height: 0;

				&.rounded {
					@include profile-image-wrapper();
					@extend .big;
					margin: 0 auto 10px;
				}
			}

			.comittee-text {
				text-align: center;

				@media #{$small-only} {
					white-space: normal;
				}

				p {
					margin-bottom: rem-calc(6);

					&.comittee-name span {
						@include link();
					}
				}
			}

			a:focus, a:focus-visible {
				outline: none
			}

			a:focus .comittee-text,
			a:focus-visible .comittee-text {
				border-width: 2px;
				border-style: solid;
				border-color: black
			}
		}
	}
}

.portraits-carousel-nav-wrapper {
	padding: 10px 0;
	margin: 0 10px;
	position: relative;
}

/* Sly slider */
.portraits-carousel-wrapper{
	overflow: visible;
	position: relative;

	@media only screen and (max-width : 1023px) {
		.portraits-carousel {
            overflow-y: hidden;
			width: 100%;
			height: 180px;
			padding: 0;
		}
		.portraits-carousel .portraits-listing {
			margin: 0;
			padding: 0;
			height: 100%;
			list-style: none;
		}
		.portraits-carousel .portraits-listing li {
			float: left;
			margin: 0;
			padding: 0;
			width: 152px;
			height: 100%;
		}

		.portraits-carousel-nav {
			width: 100%;
			height: 10px;

			background: #e3e2e2;
			line-height: 0;
			height: 3px;
			position: relative;
		}
		.portraits-carousel-nav .handle {
		    width: 100px; /* overriden if dynamicHandle: 1 */
		    height: 100%;

		    background: #ea4744;
		}
		.mousearea{
			position: absolute;
		    top: -10px;
		    bottom: -10px;
		    left: 0;
		    right: 0;
		    cursor: pointer;
		}
	}
}