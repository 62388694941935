.datepicker {
  position: relative;
}

.fd-hidden-input {
  display: none;
}

.fd-screen-reader {
  position: absolute;
  left: -999em;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  -moz-outline: 0 none;
  outline: 0 none;
}

.date-picker {
  position: absolute;
  z-index: 9999;
  text-align: center;
  /*
	Change the font-size to suit your needs. The following line is for the demo
	that has a 14px font-size defined on the body tag.

	All other rules are "em" based and inherit from the font-size set here.
	*/
  font-size: 1em;
  background: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;
  /*
	Set the fade in/out animation time here. This is used for browsers that
	support CSS transitions. Non-supporting browsers receive the fade effect
	using a Javascript fallback.
	*/
  opacity: 1;
  transition: opacity 0.15s ease-in-out;

  &.fd-dp-fade {
    opacity: 0;
  }

  // Create a "Lifted Corners" effect on the table. Ripped straight from @necolas
  // http://nicolasgallagher.com/css-drop-shadows-without-images/
  &:before,
  &:after {
    content: "";
    position: absolute;
    z-index: -2;
    bottom: 17px;
    left: 10px;
    width: 50%;
    height: 20%;
    max-width: 300px;
    max-height: 100px;
    -webkit-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    -moz-box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.7);
    -webkit-transform: rotate(-3deg);
    -moz-transform: rotate(-3deg);
    -ms-transform: rotate(-3deg);
    -o-transform: rotate(-3deg);
    transform: rotate(-3deg);

    .oldie & {
      display: none;
    }
  }
  &:after {
    right: 10px;
    left: auto;
    -webkit-transform: rotate(3deg);
    -moz-transform: rotate(3deg);
    -ms-transform: rotate(3deg);
    -o-transform: rotate(3deg);
    transform: rotate(3deg);
  }

  // The "month, year" display
  .month-display,
  .year-display {
    display: inline;
    letter-spacing: 1px;
    font-weight: normal;
  }

  // Generic button class for Next & Previous (both month & year) buttons
  .prev-but,
  .prev-year,
  .next-but,
  .next-year {
    cursor: pointer;

    height: 35px;
    background-position: center center;
    background-repeat: no-repeat;
  }
  .prev-but {
    background-image: url("../gfx/icons/datepicker/datepicker-prev-month.svg");
    .no-svg & {
      background-image: url("../gfx/icons/datepicker/datepicker-prev-month.png");
    }
  }
  .prev-year {
    background-image: url("../gfx/icons/datepicker/datepicker-prev-year.svg");
    .no-svg & {
      background-image: url("../gfx/icons/datepicker/datepicker-prev-year.png");
    }
  }
  .next-but {
    background-image: url("../gfx/icons/datepicker/datepicker-next-month.svg");
    .no-svg & {
      background-image: url("../gfx/icons/datepicker/datepicker-next-month.png");
    }
  }
  .next-year {
    background-image: url("../gfx/icons/datepicker/datepicker-next-year.svg");
    .no-svg & {
      background-image: url("../gfx/icons/datepicker/datepicker-next-year.png");
    }
  }

  .prev-but,
  .next-but {
    .fd-disabled:hover {
      /*
			Uncomment this if you have uncommented the "scale" rules above

			-webkit-transform:scale(1);
			   -moz-transform:scale(1);
				-ms-transform:scale(1);
				 -o-transform:scale(1);
					transform:scale(1);
			*/
    }
  }

  .touch & {
    .today-but {
      display: none;
    }
  }

  // Hover effect for Next & Previous (month, year) buttons
  .prev-but:not(.fd-disabled):hover,
  .next-but:not(.fd-disabled):hover,
  .today-but:not(.fd-disabled):hover {
    background-color: $color-red;
  }

  .prev-but.fd-disabled:hover,
  .next-but.fd-disabled:hover,
  .fd-disabled,
  .fd-disabled:hover {
    color: #aaa;
    cursor: default !important;
    opacity: 1;
  }

  // Today button
  .today-but {
    width: 100%;
    margin: 0 auto;
    padding-top: 0.3em;
    font-family: "MuseoSans700";
    font-weight: 700;
    font-size: 16px;
    text-decoration: none;
    text-align: center;
    height: 35px;
    line-height: 26px;
    vertical-align: middle;
    cursor: pointer;
  }

  // The table
  table {
    position: relative;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0.3em;
    table-layout: auto;
    empty-cells: show;
    font-size: 1em;
    text-align: center;
    color: #555;
    background-color: $color-grey-darker;
    border-spacing: 2px;
    border-collapse: separate;

    tr {
      th,
      td {
        &:first-child {
          padding-left: 0;
        }
      }
    }
  }

  td,
  tbody th,
  .day-disabled,
  .date-picker-hover.day-disabled,
  .date-picker-unused,
  .month-out {
    background-color: #fff;
  }

  th span {
    display: block;
    padding: 0;
    margin: 0;
    text-align: center;
    line-height: 1em;
    background: transparent;
    border: 0 none;
    cursor: pointer;
  }

  td,
  tbody th {
    padding: 0;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    background-position: 0 -35px;
    width: 3em;
    height: 2.5em;
    overflow: hidden;
    outline: transparent none 0px;
    border: 1px solid #cfcfcf;
    text-transform: none;
    font-weight: normal;
    border-radius: 3px;
  }

  th {
    border: 0 none;
    padding: 0;
    font-family: "MuseoSans500";
    font-weight: 500;
    font-size: 14px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    text-transform: none;

    // Week number "out of range" && "month-out" styles
    &.month-out,
    &.out-of-range {
      color: #aaa;
      font-style: oblique;
      background: #fcfcfc;
    }
  }

  tr {
    display: table-row;
  }
  sup {
    font-size: 0.86em;
    letter-spacing: normal;
    text-transform: none;
    height: 0;
    line-height: 1;
    position: relative;
    top: -0.2em;
    vertical-align: baseline !important;
    vertical-align: top;
  }

  .month-display,
  .year-display {
    cursor: default;
  }
  td:focus,
  .date-picker-hover {
    overflow: hidden;
    color: #fff;
    background-color: $color-black;
    -moz-outline: 0 none;
    outline: 0 none;
    -o-highlight: 0 none;
  }

  // The week header styles
  .date-picker-week-header {
    border: 1px solid #dcdcdc;
    font-style: oblique;
    background: transparent;
    cursor: default;
  }

  .date-picker-thead .date-picker-week-header {
    border: 0 none;
  }

  // The week days (mon, tue, wed, etc.) header styles
  .date-picker-day-header {
    cursor: default;
    font-weight: bold;
    border: 0 none;
    padding: 0 0 0.2em 0;
    text-transform: lowercase;
    height: auto;

    span {
      cursor: default;
    }
  }
  // tfoot status bar
  .date-picker-statusbar {
    cursor: default;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    border: 0 none;
    background: #fff;
    height: 2.8em;
  }
  // TD cell that is _not_ used to display a day of the month
  .date-picker-unused {
    border-color: #dcdcdc;
    cursor: default !important;
    background-position: 0 -25px;
  }
  // The TH cell used to display the "month, year" title
  .date-picker-title {
    width: auto;
    height: auto;
    padding: 0.4em 0;
  }
  // The "todays date" cell style
  .date-picker-today {
    background-color: $color-grey;
  }
  .month-out.date-picker-highlight {
    color: #b87676;
  }
  // The "disabled days" style
  .day-disabled {
    color: #888;
    cursor: default;
    text-decoration: line-through;
    background-position: 0 -15px;
  }
  .month-out {
    border-color: #ddd;
    border-color: rgba(220, 220, 220, 0.8);
    color: #aaa;
    background-position: 0 -25px;
  }

  // The date "out of range" style
  .out-of-range,
  .not-selectable {
    color: #ccc;
    font-style: oblique;
    background: #fcfcfc;
    cursor: default;
  }
  // Week numbers "out of range"
  .out-of-range {
    opacity: 0.6;
  }
  // Used when the entire grid is full but the next/prev months dates cannot be selected
  .not-selectable {
    opacity: 0.8;
  }
  // The "selected date" style
  .date-picker-selected-date {
    color: #fff;
    background-color: $primary-color;
  }
}

// Styles for the static datePickers
.static-datepicker {
  position: relative;
  top: 5px;
  left: 0;
  margin-bottom: 1.5em;
}

// The iframe hack to cover selectlists in IE6
.iehack {
  position: absolute;
  background: #fff;
  z-index: 9998;
  padding: 0;
  margin: 0;
  border: 0;
  display: none;
}

.datepicker-field-wrapper {
  position: relative;
}

.icon-datepicker {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50px;
  height: 50px;
  padding-bottom: 0 !important;

  &:before,
  &:after {
    position: absolute;
    top: 6px;
    right: 8px;
  }
}

.date-picker-control {
  // IE, older Safari & Opera. Seperate CSS rule seems to be required.
  display: inline-block;
  outline: none !important;

  // The activation "button" created beside each input for popup datePickers
  span {
    display: block;
    width: 18px;
    height: 18px;
    overflow: hidden;
    margin: auto 0;
    resize: none;
    outline: none;
  }
}

// The hover effect on the activation button
.date-picker-button-active {
  span {
    outline: none;
    box-shadow: 0 0 5px rgba(40, 130, 170, 0.7);
    border-radius: 2px;
  }
}

// Disabled activation button
.date-picker-control-disabled {
  &:link,
  &:visited,
  &:hover,
  &:active {
    cursor: default;
  }

  &:hover,
  &:active {
    span {
      box-shadow: none !important;
    }
  }
}

// Disabled buttons
.date-picker-disabled {
  .prev-but,
  .next-but,
  .today-but,
  .prev-but:hover,
  .next-but:hover,
  .today-but:hover {
    color: #aaa;
    cursor: default !important;
    opacity: 1;
  }
}
.oldie .date-picker .out-of-range {
  filter: alpha(opacity=60);
}
.oldie .date-picker .not-selectable {
  filter: alpha(opacity=80);
}
/*
	Remove the box-shadow & lifted corner effect for the inline datepickers
*/
.static-datepicker table {
  box-shadow: 0 0 0 transparent;
}
.static-datepicker:before,
.static-datepicker:after {
  display: none;
}
/*
	Add a box-shadow and enhance border for datepickers that have keyboard focus
*/
.date-picker-focus table {
  border-color: #999;
  border-color: rgba(153, 153, 153, 0.8);
  box-shadow: 0px 0px 5px #aaa;
}
/*
	Draggable datepickers
*/
.date-picker .drag-enabled,
.date-picker .drag-enabled span {
  cursor: move;
}
/*
	Disabled datePicker
*/
.date-picker-disabled table {
  opacity: 0.8 !important;
}
.date-picker-disabled table:before,
.date-picker-disabled table:after {
  display: none;
}
.oldie .date-picker-disabled table {
  filter: alpha(opacity=80);
}
.date-picker-disabled,
.date-picker-disabled td,
.date-picker-disabled th,
.date-picker-disabled th span {
  cursor: default !important;
}
body.fd-drag-active {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}
