.signal-r-wrapper .columns {
  margin-bottom: 20px;
}

// Page title
.speechlist-wrapper .page-title {
  float: left;
  margin-bottom: 20px;
}

@media #{$large-up} {
  .speechlist-wrapper .page-title {
    margin: 0 0 30px 0;
  }
}

// Settings
.speechlist-wrapper .toggle-options {
  float: right;
}

@media screen and (min-width: 64em) {
  .speechlist-wrapper .toggle-options {
    display: none;
  }
}

.speechlist-checkbox-layout{
    float: right;
}

.checkbox-label-desktop {
    display: block;
    float: right;
    margin: 20px 0 0 0;
}

.checkbox-label:before {
  display: inline-block;
  position: relative;
  top: 2px;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  font-family: "stortinget-symbols";
  font-size: 20px;
  text-align: center;
  text-indent: 0;
  line-height: 20px;
  content: "\e607";
  color: #eee;
  background-color: #eee;
  border-top: 2px solid #cbcbcb;
  transition: color 0.15s linear 0s, background-color 0.15s linear 0s;
}

.checkbox-label.checked:before {
  color: #000;
}

.speechlist-options .checkbox-label:before {
  background-color: #fff;
  color: #fff;
}

.speechlist-options .checkbox-label.checked:before {
  color: #000;
}

.speechlist-options li {
  margin: 0 0 10px 0;
}

.speechlist-options li:last-child {
  margin: 0;
}

// Video Link
.video-link {
  clear: left;
  float: left;
  margin-bottom: 20px;
}
.video-link:focus{
    outline:2px solid black;
}

@media #{$large-up} {
    .video-link {
        clear: none;
        float: right;
        margin-bottom: 0;
    }
}

// Meeting Agenda
.meeting-agenda-block {
  background-color: $color-grey-light;
  padding: 20px;
}

@media screen and (min-width: 64em) {
  .meeting-agenda-block {
    padding: 40px;
  }
}

.meeting-agenda-block h2 {
  @include block-title();
}

.agendaitems {
  margin: 0 0 20px 0;
  list-style-type: none;
  overflow: hidden;
}

.agendaitems-button-link {
    font-family: "Berlingske Serif";
    font-size: 1rem;
}
.agendaitems-button-link:focus{
    border: 2px solid $color-black;
}

.agendaitems li {
    float: left;
}
.agendaitems li:not(:first-child) {
    visibility:hidden;
}

.agendaitems li h3 {
  font-size: 16px;
}

.agendaitems li a {
  font-size: 16px;
}
.agendaitems li a:focus {
    outline: 2px solid black;
}
.agendaitems li .link {
    position: relative;
    left: 3px;
    bottom:1px;
}
.agendaitems li .link:focus{
    outline: 2px solid black;
}

.meeting-agenda-block {
  .next {
    float: right;
  }
}

// Current Speaker
div.current-speaker {
  background-color: $color-grey-light;
  padding: 20px;
  overflow: hidden;
}

@media screen and (min-width: 64em) {
  div.current-speaker {
    padding: 40px;
  }
}

.current-speaker-title {
  @include block-title();
}

.current-speaker-list,
.current-speaker-list ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

@media screen and (min-width: 64em) {
  .current-speaker-list {
    margin: 0 0 0 85px;
  }
  div.current-speaker .current-speaker-list {
    margin: 0 0 0 125px;
  }
}

.current-speaker-list li {
  margin-bottom: 5px;
}

.current-speaker .label {
  margin-right: 3px;
}

.current-speaker h3 {
  font-size: 16px;
}

.current-speaker h3 strong {
  font-size: 16px;
}

.current-speaker-image {
  .image-wrapper {
    float: left;
    margin-right: 15px;
    @extend .profile-image-wrapper;
    @extend .big;
  }
}

.current-speaker-block .label {
  font-weight: bold;
}

@media screen and (min-width: 64em) {
  .speech-itemlist-title {
    float: left;
    @include h2;
  }
}

.speech-itemlist.mobile {
  display: block;
}
.speech-itemlist.desktop {
  display: none;
}

@media screen and (min-width: 64em) {
  .speech-itemlist.mobile {
    display: none;
  }
  .speech-itemlist.desktop {
    display: block;
  }
}

ul.speech-itemlist {
  display: block;
  margin: 0;
  padding: 0;
  list-style-type: none;
}

ul.speech-itemlist > li {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid $color-grey;
  padding: 10px;
  list-style-type: none;
  &.current {
    background-color: $color-grey-light;
  }
}

ul.speech-itemlist > li:before {
  display: inline-block;
  position: relative;
  line-height: 32px;
  border: none;
}

ul.speech-itemlist > li:before,
ul.speech-itemlist > li:after {
  display: none;
  content: "";
  position: absolute;
  top: 9px;
  width: 32px;
  height: 32px;
  transition: opacity 200ms ease-in-out;
  background-repeat: no-repeat;
  background-position: left center;
}

ul.speech-itemlist > li:after {
  background-image: url("../gfx/icons/toggle-open.svg");
  opacity: 1;
}
ul.speech-itemlist > li.active:after {
  background-image: url("../gfx/icons/toggle-close.svg");
}

ul.speech-itemlist > li {
  .toggle-speaker-details {
    position: absolute;
    top: 8px;
    left: 7px;
  }

  &.active .toggle-speaker-details:before {
    background-image: url("../gfx/icons/toggle-close.svg");
  }
  &.active .toggle-speaker-details:after {
    background-image: url("../gfx/icons/toggle-close.svg");
  }
}

ul.speech-itemlist > li .speechnumber {
  display: block;
  padding-left: 52px;
  line-height: 32px;
  margin: 0 0 5px 0;
  min-width: 100%;
  min-height: 32px;
}

ul.speech-itemlist > li .item-content h2 {
  font-size: 18px;
  vertical-align: middle;
  display: table-cell;
}

ul.speech-itemlist > li .item-content-ajax {
}

ul.speech-itemlist > li .item-content-ajax li {
  margin-bottom: 5px;
}

ul.speech-itemlist > li .image-wrapper,
.item-content-ajax {
  display: none;
}

ul.speech-itemlist > li.active .image-wrapper,
li.active .item-content-ajax {
  display: block;
}

ul.speech-itemlist > li .image-wrapper {
  @extend .profile-image-wrapper;
  @extend .big;
  margin-bottom: 20px;
  margin-left: 0;
}

ul.speech-itemlist > li.active .item-content h2 {
  margin-bottom: 10px;
}

.item-content-ajax ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

table.speech-itemlist tr {
  background-color: #fff;
}

table.speech-itemlist tr.current,
table.speech-itemlist tr.current + tr.expanded {
  background-color: $color-grey-light;
}

table.speech-itemlist tr {
  th,
  td {
    &.first {
      text-align: center;
    }
  }
}
