.linklist {
    @include no-bullet;
    li {
        line-height: 1.4;
        margin-bottom: 30px;
    }
}

.linklist-date {
    color: $color-black;
    display: block;
    font-family: $fontSans;
    font-weight: 500;
    font-size: rem-calc(16);
    margin: 0 0 5px 0;
}
