#decisions-list {
  ul.list {
    @include no-bullet();

    .btn-link:focus-visible{
      outline: 1px solid black;
    }

    > li {
      &:last-child {
        margin-bottom: rem-calc(40);
      }
      .hidden-data {
        display: none;
      }

      // Handlebars template
      margin-bottom: 20px;
      padding-bottom: 20px;
      h2 {
        font-size: rem-calc(22);
        line-height: 1.9;
        margin-bottom: rem-calc(10);
        @media #{$small-only} {
          font-size: rem-calc(18);
        }
        a {
          color: $color-black;

          &:hover,
          &:focus {
            color: $color-red;
          }
        }
      }
      .meta {
        @include small-title();
        margin-bottom: rem-calc(10);
      }
      .content {
        @include paragraph-article();
        overflow: hidden;
        margin-bottom: rem-calc(10);
        .more-content span {
          display: none;
        }
        .more-link {
          display: inline;
        }
      }
      .date {
        font-weight: 500;
        color: #767676;
      }

      .show-more-link {
        [data-show-more-open] {
          display: block;
        }
        [data-show-more-close] {
          display: none;
        }
        &.active {
          [data-show-more-open] {
            display: none;
          }
          [data-show-more-close] {
            display: block;
          }
        }
      }
    }
  }
  .horinger-filter {
    left: 0;
  }
  .total-results {
    margin-top: rem-calc(60);
    margin-bottom: rem-calc(20);
  }
  .paging {
    margin-top: 0;
    &.paging-top {
      .total-results {
        margin-top: 0;
        margin-bottom: rem-calc(40);
      }
    }
  }
}

.decisions-filter {
  @extend .horinger-filter;
  left: 0;
}

.travel-list-top#data-decisions-filters {
  justify-content: flex-start;
  .travel-list-nav {
    @media #{$medium-up} {
      margin-right: 40px;
    }
    &:last-child {
      margin-right: 0;
    }
  }
}

ul.comittee-list {
  > li {
    > button {
      width: 100%;
    }
  }
}
