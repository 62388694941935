.publication-info {
  @include clearfix();
  margin-bottom: 30px;
  background-color: $color-grey-light;
  padding: 20px;

  @media #{$medium-up} {
    padding: 40px;
  }
  > .row {
    @media #{$large-up} {
      display: flex;
    }
  }
  > .row > .columns:not(:last-of-type) {
    margin-bottom: 30px;
    @media #{$large-up} {
      border-right: 1px solid $color-grey;
      margin: 0;
    }
  }
  h2,
  h3 {
    @include h2();
    margin-top: 0;
    margin-bottom: 10px;
  }
}

.publication-info-first {
  width: 100%;
  background-color: $color-grey;
  position: relative;
  @media #{$large-up} {
    width: 50%;
    display: table-cell;
  }
  @media #{$large-up} {
    &:after {
      content: "";
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-left: 15px solid $color-grey;
      position: absolute;
      right: -15px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .publication-info-big & {
    @media #{$large-up} {
      width: 33.33%;
    }
  }
}

.publication-info-second {
  width: 100%;
  background-color: $color-grey-light;
  p {
    margin: 0;
  }
  @media #{$large-up} {
    width: 50%;
    display: table-cell;
  }
  p > a,
  ul > li a {
    @include link-on-grey($color-grey-darker);
  }

  .block-link {
    border-color: $color-grey-darker;
  }
  .publication-info-big & {
    @media #{$large-up} {
      width: 66.66%;
    }
  }
  .row > .columns:first-child {
    margin-bottom: 50px;
    @media #{$large-up} {
      margin-bottom: 0;
    }
  }
}

.publication-info-list {
  @include no-bullet();
  margin-bottom: 20px;
}
