.custom-filter-container {
  position: relative;
}

.custom-filter {
  display: none;
  position: absolute;
  z-index: 1;
  right: 0;
  left: 0;
  background-color: #fff;
  border: 1px solid $color-grey-light;
  box-shadow: 0 0 20px rgba(105, 98, 93, 0.25);
  &.open {
    display: block;
  }
  @media #{$medium-up} {
    width: 345px;
  }
  &.custom-filter-big {
    @media #{$medium-up} {
      width: 360px;
    }
    label {
      display: block;
    }
  }
  padding: 15px;
  legend {
    margin-bottom: 5px;
    @include small-title();
  }
  .col-dropdown {
    @media #{$medium-up} {
      float: left;
    }
    + .col-dropdown {
      margin-top: 10px;
      @media #{$medium-up} {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
}

button.custom-filter-trigger {
  > span {
    @include small-title();
    font-weight: normal !important;
    text-transform: capitalize;
    &[data-selected] {
      font-weight: 700 !important;
    }
  }
  padding-right: 30px !important;
  &:after {
    top: 5px !important;
  }
  &.active:after {
    top: 3px !important;
  }
  &.custom-filter-trigger-big {
    font-weight: 300;
  }
}

ul.custom-filter-list {
  @include no-bullet();
  > li {
    margin-bottom: 5px;
    &:last-child {
      margin-bottom: 0;
    }
    > button {
      @extend .btn-link;
      border-bottom: none;
      color: $color-black;
      text-align: left;
      font-size: rem-calc(16px);
      padding: 2px 4px 1px;
      text-align: left;
      background-color: #fff;
      border: none;
      border-radius: 2px;
      font-weight: 500;
      &.active,
      &.current {
        color: $color-red;
        &:hover,
        &:focus {
          color: $color-red;
          background-color: white;
        }
      }
      &:focus,
      &:hover {
        background-color: $color-grey-light;
        color: $color-black;
      }
    }
  }
}

.custom-filter-big-label-spacer {
  @media #{$medium-up} {
    height: 24px;
  }
}
