.whats-up-block-date {
	background-color: #EEE;
	padding: 25px;
	position: absolute;
	top: 0;
	left: 15px;
}

.whats-up-title {
	@include section-title;
	// background-color: $color-grey-light;
	// padding: 20px 15px;
	// font-family: $fontSans;
	// background-color: $color-grey;
	// padding: 20px 15px;
	// margin: 0 0 20px 0;
	// color: $color-black;
	// font-size: rem-calc(22);
}

.whats-up-days {
	margin: 0 0 40px 0;
}

.whats-up-day {
	margin: 0;
	padding: 0;
	list-style-type: none;
	@media #{$large-up} {
		float: left;
		width: 20%;
	}
	&:last-child {
		.whats-up-cell {
			border-right: none;
		}
	}
}

.whats-up-day-title {
	background-color: $color-grey-light;
	padding: 10px 0;
	text-align: center;
	color: $color-black;
	font-weight: 700;
	font-size: rem-calc(14);
}

.whats-up-cell {
	padding: 15px;
	border-bottom: 1px solid #dcdddf;
	@media #{$large-up} {
		border-right: 1px solid #dcdddf;
	}
}

.whats-up-cell-title {
	font-size: rem-calc(16);
	color: #333;
	font-weight: 700;
}

.whats-up-cell-list {
	margin: 0;
	padding: 0;
	list-style-type: none;
	li {
		margin-bottom: 8px;
	}
}

.whats-up-no-hits {
	font-style: italic;
}

.whats-up-profile {
	margin-top: 20px;
	.profile-image-wrapper {
    position: relative;
		display: block;
		margin: 0 auto 15px auto;
	}
	.speech-bubble {
		position: relative;
		display: block;
		width: 100%;
		background-color: $color-grey-light;
		padding: 10px;
		text-align: center;

	}
	.block-image-wrapper {
		display: block;
		position: relative;
		width: 100px;
		height: 100px;
		border-radius: 50%;
		overflow: hidden;
		margin: 0 auto 15px auto;
	}
	.block-image-wrapper + .speech-bubble {
		&:before {
			//@include css-triangle(10px, #eee, 'bottom');
			display: inline-block;
			position: absolute;
			top: -20px;
			left: 50%;
			transform: translate3d(calc(-100% + 10px), 0, 0);
		}
	}

	.block-image-wrapper + .speech-bubble,
	.profile-image-wrapper + .speech-bubble {
		&:after{
		  content: "";
		  position: absolute;
		  width: 0;
		  height: 0;
		  border-left: 10px solid transparent;
		  border-right: 10px solid transparent;
		  border-bottom: 10px solid $color-grey-light;
		  top: -10px;
		  left: calc(50% - 10px);
		}
	}

}

.page-whats-up {
	.large-12 .block-main {
		margin-bottom: 40px;
	}
}
