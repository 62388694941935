.travel-list-top{
  display: none;

  @media #{$large-up} {
    display: flex;
    flex-wrap: wrap;
  }
  @media #{$xlarge-up} {
    flex-wrap: nowrap;
  }
  @media #{$medium-up}{
    justify-content: flex-start;
  }

  &.open{
    display: block !important;
     @media #{$medium-up} {
      display: flex !important;
      flex-wrap: wrap;
    }

  }
  > *{
    position: relative;

    @media #{$medium-up} {
      max-width: 240px;
      width: 240px;
      margin-right: 20px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
}

.travel-list-filter{
  display: none;
  &.active{
    display: block;
  }
}

.travel-list-filter-new{

  display: none;
  .active &{
    display: block;
  }

  position: absolute;
  width: 100%;
  left: 0;
  top: 50px;
  background: $color-grey-light;

  > ul{
    @include no-bullet();
    button{
      width: 100%;
      background: transparent;
      border: none;

    }
  }
}


.travel-list-tabs{
  display: flex;
}

.travel-list-tab{
  padding: 10px 20px;
  width: 33.3%;
  text-align: left;
  min-width: 220px;
  position: relative;
  background-color: $color-grey;
  &.active{
    background: $color-grey-light;
  }

}

#travel-list {

    select[data-travel-date-filter-month] > option:disabled {
        display: none;
    }

    ul.list {
        @include no-bullet();
        margin-bottom: 40px;

        > li {
            .hidden-data {
                display: none;
            }

            &:first-child {
                border-top: 1px solid $color-grey;
                padding-top: 20px;
            }
            // Handlebars template
            @media #{$medium-up} {
                display: flex;
            }

            margin-bottom: 20px;
            padding-bottom: 20px;
            border-bottom: 1px solid $color-grey;

            .class-date-container {
                flex: 1.5;
                margin-right: 40px;
                @include paragraph-article();
            }

            .class-content-container {
                flex: 3;
            }

            .class-title {
                @include paragraph-article();
                margin-bottom: 10px;

                @media #{$medium-up} {
                    margin-bottom: 0;
                }
            }

            .class-meta {
                > * {
                    display: inline-block;

                    &:first-child {
                        margin-right: 20px;
                    }
                }

                .class-budget {
                    overflow: hidden;
                    white-space: normal;
                    height: 24px;
                    position: relative;
                    width: 100%;
                }

                .budget-text-container {
                    margin-left: 10px;
                }

                .class-budget:after {
                    content: "";
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 80px;
                    height: 24px;
                    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
                }

                span {
                    font-weight: bold;
                }
            }
        }
    }
}


.travel-list-steps {
    @include clearfix();
    margin-bottom: 40px;

    > button::first-letter {
        text-transform: capitalize;
    }

    .travel-list-prev {
        float: left;
        @include icon();
        @include icon-left();
        @include icon-arrow-left();

        &:before,
        &:after {
            top: 9px;
            left: 0;
        }

        padding-left: 25px;
        margin-left: 0;
    }

    .travel-list-next {
        float: right;
        @include icon();
        @include icon-right();
        @include icon-arrow-right();

        &:before,
        &:after {
            top: 9px;
        }

        padding-right: 25px;
    }
}

.travel-list-top {
    padding: 20px 0;

    .travel-list-nav {
        margin-bottom: 20px;

        &:last-child {
            margin-bottom: 0;
        }

        @media #{$medium-up} {
            margin-right: 30px;
            margin-bottom: 0;
        }
    }

    .form-filter-travel & {
      padding: 0;
    }
}

.travel-list-sort-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 30px;
}

.travel-list-sort {
    max-width: 260px;
    width: auto;
    > div {
        margin-right: 10px;
        display: inline;

        @media #{$medium-only} {
            margin-top: 30px;
        }
    }

    button {
        border-bottom: none;
        font-size: rem-calc(16);

        &.asc,
        &.desc {
            border-bottom: 1px solid $color-red;
        }
    }

    span {
        padding-left: 10px;
        margin-right: 10px;
        line-height: 20px;
        border-right: 1px solid #D8D1CA;
    }
}

ul.comittee-list{
  @extend .custom-filter-list !optional;
}


.travel-list-comittee-filter,
.travel-list-type-filter,
.travel-list-date-filter {
    display: none;

    &.open {
        display: block;
    }

    width: 100%;

    @media #{$medium-up} {
        width: 345px;
    }

    padding: 15px;

    legend {
        margin-bottom: 5px;
        @include small-title();
    }
}

.form-filter-travel{
  @media #{$medium-up} {
    > h2{
      display: none;
    }
  }
}


// tab-filter
.filter {
    display: none;
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    background-color: #fff;
    border: 1px solid $color-grey-light;
    box-shadow: 0 0 20px rgba(105,98,93,0.25);

    .col-dropdown {
        float: left;
        margin-top: 10px;
    }

    .col-dropdown:not(:last-child) {
        margin-right: 10px;
    }
}

.filter-trigger {
    @include small-title();

    > span {
        @include small-title();
    }

    span::first-letter {
        text-transform: capitalize;
    }

    padding-right: 30px !important;

    &:after {
        top: 5px !important;
    }

    &.active:after {
        top: 3px !important;
    }
}

// Data
.data-loading{
  text-align: center;
}
.data-empty,
.data-error{
  @include h2();
  margin-top: 20px;
  margin-bottom: 40px;
}

ul.travel-header,
ul.travel-content{
  @include no-bullet();
  > li > div:first-child{
    font-weight: bold;
  }
}

ul.travel-header{
  @media #{$medium-up} {
    display: flex;
  }
  margin-bottom: 40px;
  > li{
    > div{
      display: inline;
    }
    &:first-child{
      margin-right: 20px;
    }
  }
}

ul.travel-content{
  @include no-bullet();
  > li{
    margin-bottom: 30px;
    @include paragraph-article();
    > div:first-child{
      @include h2();
    }
    p{
      @include paragraph-article();
    }
  }
}
