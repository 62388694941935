.custom-dropdown {
  position: relative;
  overflow: hidden;
  display: inline-block;
  vertical-align: middle;
  font-size: rem-calc(16);
  box-sizing: border-box;

  @media #{$small-only} {
    .no-touch & {
      font-size: rem-calc(14);
    }
  }

  &.custom-dropdown-grey {
    select {
      background-color: $color-grey;
    }
  }

  &::after {
    position: absolute;
    top: 20px;
    right: 10px;
    padding: 7px;
    content: "";
    background-color: transparent;
    pointer-events: none;
    background-image: url(../gfx/icons/arrow-right.svg);
    background-repeat: no-repeat;
    transform: rotate(90deg);
  }

  &.custom-dropdown-multiple {
    &:after {
      display: none;
    }
  }

  &.custom-dropdown-list {
    &::after {
      content: " ";
    }
  }

  &.custom-dropdown-fullwidth {
    display: block;
  }

  &.custom-dropdown-disabled::after {
    color: rgba(0, 0, 0, 0.1);
  }

  select {
    width: 100%; //130%
    min-width: 240px;
    margin: 0;
    padding: 13px 50px 13px 1.3em;
    font-family: $fontSans;
    font-size: inherit;
    color: $color-black;
    background-color: #fff;
    border: 1px solid $color-grey;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    line-height: 1.4;
    font-size: 16px;

    &:hover,
    &:focus {
      border-color: $color-grey-darker;
      &::-ms-value {
        color: inherit;
        background-color: inherit;
      }
    }
    &:focus {
      outline: none;
      border-color: $color-grey-dark;
    }

    &[disabled] {
      color: rgba(0, 0, 0, 0.3);
    }

    option {
      background-color: #fff;
      padding: 5px 0;
    }
  }
  // Hide select arrow in IE10 up
  select::-ms-expand {
    display: none;
  }
  .ie9 & {
    &::after {
      display: none !important;
    }

    select {
      width: 100%;
      padding-right: 15px;

      &.custom-dropdown-grey {
        select {
          background-color: #eee;
        }
      }
    }
  }

  &.custom-dropdown-autowidth select {
    width: auto;
    min-width: 1px;
  }
}
