.footer {
    color: #fff;
    font-family: $fontSans;
    font-weight: 500;
    font-size: 14px;
    margin-left: 20px;
    margin-right: 20px;
    padding-bottom: 20px;
    padding-top: 20px;

    p,
    a {
        font-size: 14px;
    }

    @media #{$large-up} {
        font-size: 16px;

        p,
        a {
            font-size: 16px;
        }

        margin: 0 rem-calc(20px) 0;
        padding-bottom: 20px;

        .column,
        .columns {
            &.logo {
                padding: auto;
                margin-top: 5px;
            }
        }
    }

    @media #{$xlarge-up} {
        margin: 70px rem-calc(20px) 0;
        padding-bottom: 70px;
    }

    .footer-column-title {
        color: #fff;
        font-size: rem-calc(16);
    }

    .footer-column-address {
        font-weight: 500;
        font-style: normal;
    }

    p {
        line-height: inherit;

        &:last-child {
            margin-bottom: 0;
        }
    }

    a {
        color: #fff;
        border-color: #e99faa;
        border-width: 1px;

        &:hover {
            border-color: #ffffff;
        }

        &:focus {
            border: 1px solid #FFFFFF;
            outline: none;
        }

        &:active {
            border: none;
        }
    }

    .editor-label {
        color: #fff;
    }

    .logo-wrapper {
        display: inline-block;
        border: none;
    }

    .logo {
        margin-bottom: 20px;

        img {
            width: 230px;
        }

        @media #{$large-up} {
            margin-bottom: 0;

            img {
                margin-left: 30px;
            }
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }

    > .row {
        background-color: $color-red;
        padding: 50px 0;

        @media #{$xlarge-up} {
        }

        @media (min-width: 1316px) {
            border-left: 20px solid white;
            border-right: 20px solid white;
        }
    }

    .columns {
        margin: 0 0 20px 0;

        @media #{$large-up} {
            margin: 0;
        }
    }

    .social-media-links {
        li {
            &:last-child {
                margin-bottom: 0px;
            }
        }
    }

    .social-media-links,
    .calendar-links {
        @include clearfix();
        margin-top: 20px;

        @media #{$medium-up} {
            margin-top: 5px;
        }

        li {
            margin-bottom: 7px;
            line-height: 1;
            margin-left: rem-calc(25px);
        }

        a {
            display: inline-block;
            position: relative;
        }

        .icon {
            &:before {
                background-size: 15px;
                width: 15px;
                height: 15px;
                display: inline-block;
                content: "";
                background-repeat: no-repeat;
                background-position: center center;
                position: absolute;
                left: -25px;
                top: -2px;

                @media #{$large-up} {
                    top: 0px;
                }
            }
        }

        .icon-rss {
            &:before {
                background-image: url("../gfx/icons/social-rss.svg");
            }
        }

        .icon-twitter {
            &:before {
                background-image: url("../gfx/icons/social-twitter.svg");
            }
        }

        .icon-flickr {
            &:before {
                background-image: url("../gfx/icons/social-flickr.svg");
            }
        }

        .icon-instagram {
            &:before {
                background-image: url("../gfx/icons/social-instagram.svg");
            }
        }

        .icon-facebook {
            &:before {
                background-image: url("../gfx/icons/social-facebook.svg");
            }
        }

        .icon-calendar-footer {
            &:before {
                background-image: url("../gfx/icons/social-calendar.svg");
            }
        }
    }

    .calendar-links {
        margin-top: rem-calc(29px);
    }
}
