/*
*  Pages
*/

.page {
  .page-section:last-child {
    margin-bottom: 0;
  }
}
.page-section {
  margin-bottom: 50px;

  &.page-section-border {
    margin-top: 40px;
    padding-top: 40px;
    border-top: 1px solid $color-grey;
    padding-bottom: 0px;
    border-bottom: 1px solid $color-grey;
    .columns > .block {
      margin-bottom: 40px;
    }
  }
  .columns .block-main {
    margin: 0;
  }
}

.page-home {
  padding-top: 0;

  .page-section {
    margin-bottom: 50px;
    .large-12 {
      margin-bottom: 50px;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
.row + .page.page-home {
  padding-top: 0;
}

.page.page-publication {
  padding-top: 0;
}

.content-container {
  @media #{$large-down} {
    padding-top: 00px;
  }
  @media #{$large-up} {
    min-height: 600px;
  }
  &:focus {
    outline: none;
  }
}

.page-section {
  margin-bottom: 50px;

  &.line-above,
  &.line-below {
    margin-top: 20px;
  }
  &.section-grid + .page-section.line-above {
    margin-top: -30px;
  }

  &.line-above {
    &:before {
      display: block;
      margin-right: rem-calc(20);
      margin-left: rem-calc(20);
      padding-bottom: 50px;
      content: "";
      border-top: 1px solid $color-grey;
    }
  }

  &.line-below {
    &:after {
      display: block;
      margin-right: rem-calc(20);
      margin-left: rem-calc(20);
      padding-top: 20px;
      content: "";
      border-bottom: 1px solid $color-grey;
    }
  }

  &.line-below + .line-above {
    &:before {
      display: none;
    }
  }
}

.page-section-articles {
  .columns {
    @media #{$medium-only} {
      &:nth-child(3),
      &:nth-child(5),
      &:nth-child(7),
      &:nth-child(9),
      &:nth-child(11),
      &:nth-child(13),
      &:nth-child(15) {
        clear: left;
      }
    }

    @media #{$large-up} {
      &:nth-child(4),
      &:nth-child(7),
      &:nth-child(10),
      &:nth-child(13),
      &:nth-child(16) {
        clear: left;
      }
    }
  }
}

.page-home {
  .page-section .block-article {
    margin-bottom: 50px;
  }

  .page-section.page-section-articles {
    @media #{$medium-up} {
      margin-bottom: 0;
      .block-article {
        margin-bottom: 70px;
      }
    }
  }
  .page-section {
    margin-bottom: 50px;
  }
}
.row + .page.page-home {
  padding-top: 0;
}

.page-cases {
  .page-title + p {
    margin: 0 0 40px 0;
    @include paragraph-article();
  }
  .link-list-container {
    @media #{$medium-up} {
      padding-left: 78px;
      padding-right: 78px;
    }
    @media #{$large-up} {
      padding-left: 88px;
      padding-right: 88px;
    }
  }
}

.page-find-case {
  .find-by-theme {
    margin: 0 0 30px 0;
  }
  [class*="block-grid-"] > li {
    padding-bottom: 5px;
  }
  .help-list {
    margin: 0 0 60px 0;
    background-color: #dde8f5;
    border-left: 5px solid #407cca;
    padding: 18px 35px;

    ul {
      margin: 0;
    }

    li {
      display: inline;
      margin: 0 20px 0 0;
      &:last-child {
        margin: 0;
      }
    }

    a {
      @include link;
      color: $color-black;
      border-color: $color-black;
    }
  }
  .latest-cases {
    margin: 0 0 30px 0;
    .latest-cases-title {
      font-family: $header-font-family;
      font-size: rem-calc(28);
      margin: 0 0 30px 0;
      @media #{$large-up} {
        font-size: rem-calc(38);
        margin: 0 0 50px 0;
      }
    }
  }
}

.content-container {
  .page-find-case {
    .button-group-link {
      margin-top: 15px;
      padding-right: 3.25rem;
      color: #333;
      float: right;
    }
    .latest-cases-link {
      float: left;
      margin-top: 15px;
      color: #333;
    }
  }
}

.page-voting {
  .page-subtitle {
    @media #{$large-down} {
      font-size: rem-calc(24);
    }
  }
  .page-excerpt {
    margin-bottom: 30px;
    @media #{$large-up} {
      margin-bottom: 40px;
    }
  }
}

.page-tour {
  .submit {
    float: right;
    @media #{$medium-up} {
      float: none;
    }
  }
  .tour-title {
    @media #{$medium-up} {
      float: left;
    }
  }
  .mainindent {
    clear: both;
  }

  .tour-overview {
    .custom-dropdown {
      width: 100%;
      margin: 0 0 35px 0;
      @media #{$medium-up} {
        float: right;
        width: auto;
      }
    }
  }
}
.toggle-details {
  margin: 0 0 20px 0;
}

.page-state-budget {
  .toggle-details {
    background-color: #eeeeef;
    padding: 15px;
    margin: 0 0 20px 0;
    a {
      outline: none;
      color: #333;
    }
  }
  .state-budget-details {
    display: none;
  }
  .block-case-steps .block-list .block-list-item {
    background-color: #fff;
  }
  .block-case-steps .block-title {
    padding: 0;
    margin: 10px 0 5px 20px;
    @media #{$large-up} {
      margin: 10px 0 5px 0;
    }
  }
  .block-subtitle {
    border-bottom: 1px solid #eeeeef;
    font-size: rem-calc(22);
    margin: 0 0 5px 20px;
    @media #{$large-up} {
      margin: 0 0 5px 0;
    }
  }
  .block-case-steps .block-list .block-list-item-heading {
    position: relative;
    background-color: #fff;
    z-index: 99;
    padding: 10px 0 20px 0;
    min-height: 0;
    margin: 0;
  }
  .block-case-steps .block-list .block-list-item-heading:after {
    bottom: -55px;
  }

  .block-case-steps .block-list .block-list-item-title {
    @media #{$large-down} {
      margin: 0 0 20px 0;
      vertical-align: middle;
      font-size: rem-calc(19);
    }
  }
  .case-step-content {
    margin: 0;
    padding: 0;
    list-style-type: none;
    h4 {
      margin: 15px 0 10px 0;
      font-size: rem-calc(16);
    }
  }
  .block-case-steps .block-list .case-step-content .case-step-content-item {
    margin: 0 0 10px 0;
  }
  .block-case-steps
    .block-list
    .block-list-item-status
    .case-step-content-title {
    font-size: rem-calc(22);
    @media #{$large-up} {
      width: 50%;
    }
  }
  .block-case-steps .block-list .block-list-item-title {
    @media #{$large-up} {
      margin: 0;
    }
  }
  .block-case-steps .block-list .case-step-content {
    margin: 50px 0 0 75px;
    @media #{$large-up} {
      margin: 50px 0 0 145px;
    }
  }
}

.page-abstract {
  .list-item {
    border-bottom: 2px solid #e3e2e2;
    padding-bottom: 20px;
  }
}
