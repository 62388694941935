.linkbox {
  @include h2();
  margin: 0 0 30px 0;
  @media #{$medium-up} {
    margin: 0 0 50px 0;
  }

  .button-group & {
    margin: 0 0 20px 0;
    @media #{$medium-up} {
      margin: 0 0 30px 0;
    }
  }

  a {
    border-bottom: none;
    color: $color-black;
  }

  .icon-arrow {
    display: block;
    position: relative;
    padding: 16px 30px;
    background-color: $color-grey;
    min-height: 60px;
    line-height: 27px;
    color: #000;
    transition: all 0.3s ease-in-out;
    &:before,
    &:after {
      transition: all 0.3s ease-in-out;
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      right: 15px;
      width: 32px;
      height: 32px;
      transform: translate3d(0, -50%, 0);
    }
    &:before {
      background-image: url(../gfx/icons/arrow-right.svg);
      background-repeat: no-repeat;
      background-position: center center;
    }
    &:hover,
    &:active {
      background-color: $color-grey-light;
      color: $color-red;
      &:before {
        background-repeat: no-repeat;
        background-position: center center;
      }
    }
  }
}
