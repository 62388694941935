.video {
    .video-pre-title {
        margin-bottom: 0;
        font-size: rem-calc(14);
        text-transform: uppercase;

        @media #{$small-only} {
            font-size: rem-calc(12);
        }
    }

    .video-wrapper {
        background-color: $color-grey-light;

        .alert-hearings {
            background-color: transparent !important;
        }

        .video-links {
            a {
                border-bottom: 0;
            }
        }

        @media #{$xlarge-up} {
            margin-bottom: 20px;

            .video-info {
                margin-top: 30px;
                padding-right: 30px;

                .video-pre-title {
                    font-weight: 600;
                }

                h1 {
                    margin-bottom: 30px;
                    font-size: rem-calc(26);
                    line-height: 1.3;
                }

                .video-post-title {
                    margin-bottom: 20px;
                }

                .video-links {
                    margin-top: 5px;

                    a:last-child {
                        float: right;
                    }
                }
            }
        }

        @media #{$xlarge-down} {
            .video-info {
                padding: 20px 30px;

                p.video-pre-title {
                    margin-bottom: 10px;
                }

                .video-links {
                    margin-top: 15px;

                    a:first-child {
                        display: block;
                    }
                }
            }
        }

        @media #{$small-only} {
            margin-right: rem-calc(-16);
            margin-left: rem-calc(-16);

            .video-info {
                padding-right: rem-calc(16);
                padding-left: rem-calc(16);
            }
        }
    }

    .socialheader {
        margin-top: 20px;
        @extend h2;
    }

    .social {
        margin-bottom: 20px;

        .twitter,
        .facebook,
        .gplus,
        .email {
            display: inline-block;
            width: 50px;
            height: 50px;
            background: transparent url("../gfx/icons/video-social-sprite.png") 0 0 no-repeat;
            border-bottom: none !important;
        }

        .twitter {
            background-position: 0 0;
        }

        .facebook {
            background-position: 0 -50px;
        }

        .gplus {
            background-position: 0 -100px;
        }

        .email {
            background-position: 0 -150px;
        }

        .shareTipFormula {
            display: none;

            label {
                display: block;
                margin-top: 10px;
                margin-bottom: 2px;
            }

            &.open {
                display: block;
            }

            input.button + span {
                margin-left: 20px;
            }

            .row .columns + .columns {
                padding-top: 0;
            }
        }
    }

    .video-url {
        margin-bottom: 20px;

        .video-url-picker {
            min-height: 100px;
        }
    }

    .video-start-at {
        input[type="text"] {
            width: rem-calc(100);
        }
    }

    .video-archive-scrollable-wrapper {
        position: relative;

        @media #{$large-up} {
            display: none;
        }

        .scrollbar {
            display: none;
            position: absolute;
            width: 6px;
            right: -20px;
            background: #e3e2e2;
            line-height: 0;
            height: 100%;

            .handle {
                width: 100%;
                height: 100px;
                background: #ea4744;
                cursor: pointer;
            }
        }
    }

    ol.video-archive-list {
        margin-left: 0;
        margin-bottom: 0;
        list-style-type: none;

        li {
            margin-bottom: 10px;

            a,
            div {
                display: block;
            }

            a {
                border-bottom: 0;
            }
        }
    }
}

.qbrick-video-container {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  #player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
}
