.download-dropdown-target {
  display: none;
  &.open {
    display: block;
  }
}

.download-dropdown-trigger {
  @extend .button;
  background-color: $color-blue-light;
  border-color: $color-blue-light;
  color: #fff;
  width: 200px;
  text-align: left;
  &:hover,
  &:focus {
    background-color: $color-blue-light-hover !important;
  }
  &.active {
    background-color: $color-blue-light !important;
    &:before {
      top: 50%;
      transform: translateY(-50%) rotate(180deg);
    }
    border-bottom: 1px solid #fff;
  }
  @include icon();
  @include icon-right();
  &:before {
    background: url("../gfx/icons/arrow_white_down.svg") no-repeat center center;
    display: block;
    top: 50%;
    transform: translateY(-50%);
  }
}

.download-dropdown-target {
  margin-left: 0;
  > li {
    list-style: none;
    > a {
      background-color: $color-blue-light !important;
      color: #fff;
      display: block;
      padding: 14px 20px;
      width: 200px;
      font-weight: 400;
      &:hover,
      &:focus {
        background-color: $color-blue-light-hover !important;
      }
      @include icon();

      @include icon-right();
      @include icon-small-right();
      @include icon-download-white();
      &:before,
      &:after {
        right: 15px;
        top: 15px;
      }
    }
  }
}
