.page-publication {
  .bigdoc-content {
    .strtngt_kommentar_tittel_header {
      letter-spacing: 0;
      text-align: left;
      display: block;
      line-height: inherit;
    }
  }
  /*outline-offset elements to fix anchor tags with fixed top*/
  .strtngt_kommentar::before,
  strtngt_tittel::before {
    content: "";
    display: block;
    height: 100px;
    margin-top: -100px;
    visibility: hidden;
  }

  .strtngt_kommentar {
    .strtngt_uth {
      &.type_halvfet {
        display: inline;
        margin-bottom: 0;
      }
    }
  }

  .strtngt_merknad {
    font-family: $fontSerifText;
    font-style: italic;
    font-size: rem-calc(18px);
    line-height: 1.56;
    margin-top: 0;
    margin-bottom: 30px;
  }

  h2.strtngt_tittel {
    letter-spacing: 0;
    text-align: left;
    display: block;
  }

  h3.strtngt_tittel {
    letter-spacing: 0;
    text-align: left;
    display: block;
    font-style: italic;
  }

  .strtngt_uth {
    &.type_kursiv {
      font-style: italic;
    }

    &.type_halvfet {
      font-weight: bold;
      display: block;
      margin-bottom: 30px;
    }

    &.type_sperret {
      letter-spacing: 2px;
    }
  }

  .align_center {
    text-align: center;
  }

  .align_right {
    text-align: right;
  }

  .align_left {
    text-align: left;

    .strtngt_uth.type_halvfet {
      text-align: left;
    }
  }

  .align_right {
    text-align: right;

    .strtngt_uth.type_halvfet {
      text-align: right;
    }
  }

  .strtngt_undertittel {
    display: block;
    margin-bottom: 30px;
  }

  .type_sentrert {
    text-align: left;
  }

  .strtngt_liste {
    &.type_fri {
      list-style: none;
      margin: 0;

      .strtngt_liste.type_fri {
        margin-left: 3.75rem;
      }
    }

    &.type_strek {
      > li {
        list-style-image: url(/Web/images/line.gif);
        list-style-position: outside;
      }
    }
  }

  .type_uinnrykk {
    text-align: left;

    &.align_center {
      text-align: center;
    }

    &.align_right {
      text-align: right;
    }
  }
  /* Votering */

  .strtngt_votering {
    .strtngt_tittel {
      display: block;
      text-align: left;
      letter-spacing: 2px;
      margin-left: 10%;
      margin-bottom: 30px;
    }
  }

  .strtngt_voteringer {
    .strtngt_sakdel {
      > .strtngt_tittel {
        font-style: italic;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  .strtngt_titlovvedtakstorbokstav {
    display: block;
    margin-bottom: 30px;
  }
  /* Vedtak til lov */

  .strtngt_vedtaks {
    > .strtngt_tittel {
      display: block;
      font-weight: bold;
      margin-bottom: 20px;
    }

    > .strtngt_bokstavseksjon {
      > .strtngt_tittel {
        display: block;
      }
    }
  }

  .strtngt_vedtakl {
    > .strtngt_tittel {
      display: block;
      letter-spacing: 5px;
      margin-bottom: 30px;
    }
  }

  .strtngt_vedtaktillov {
    > .strtngt_tittel {
      display: block;
      letter-spacing: 2px;
    }
  }
  /* Romertallseksjon */

  .strtngt_romertallseksjon {
    > .strtngt_tittel {
      display: block;
      letter-spacing: 2px;
    }

    .strtngt_paragraf {
      margin-bottom: 30px;

      .strtngt_tittel {
      }

      .strtngt_a {
        margin-left: 30px;
      }
    }
  }

  .strtngt_table {
    font-style: normal;
  }
  /* Forside */
  .strtngt_sign {
    margin-top: 20px;

    .strtngt_dato {
      margin-bottom: 20px;
      display: block;
      font-style: normal;
    }
  }
  /* Vedlegg */
  .strtngt_vedlegg {
    .strtngt_altsek1 {
      > .strtngt_tittel {
        margin-bottom: 30px;
      }
    }

    .strtngt_tittel {
      font-weight: bold;
      margin-bottom: 30px;

      &.h1 {
        line-height: 1.3;
      }
    }

    p.type_blanklinje {
      .strtngt_uth.type_halvfet {
        text-align: left;
      }
    }
  }
  /* Stortingstidende (REFS) */

  .strtngt_stortingstidende {
    display: block;
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 40px;

    @media #{$medium-up} {
      font-size: 40px;
    }

    @media #{$large-up} {
      font-size: 50px;
    }
  }

  .strtngt_inneholdende {
    display: block;
    margin-bottom: 20px;
  }

  .strtngt_aar,
  .strtngt_periode {
    display: block;
    margin-bottom: 20px;
    letter-spacing: 5px;
  }

  .strtngt_forhtit {
    display: block;
    margin-bottom: 60px;
    font-weight: bold;
  }

  .strtngt_fylke {
    > .strtngt_tittel {
      font-weight: bold;
      margin-bottom: 30px;
      display: block;
    }

    .strtngt_repr,
    .strtngt_varaelement {
      > .strtngt_tittel {
        font-style: italic;
        display: block;
        margin-bottom: 30px;
      }
    }
  }
  /* Sammentreden */
  .strtngt_sammentreden {
    > .strtngt_tittel {
      display: block;
      font-weight: bold;
      margin-bottom: 20px;
    }
  }
  /*Forslag fra mindretall */
  .strtngt_forslagframindretall {
    .strtngt_fraksjon,
    .strtngt_forslag {
      > .strtngt_tittel {
        display: block;
        font-weight: bold;
        margin-bottom: 30px;
      }
    }
  }
  /* Tables */

  table {
    .type_head {
      .strtngt_uth.type_kursiv {
        font-style: normal;
        font-weight: bold;
      }
    }

    .align_center {
      text-align: center;

      .strtngt_uth.type_halvfet {
        text-align: center;
      }
    }

    .align_right {
      text-align: right;

      .strtngt_uth.type_halvfet {
        text-align: right;
      }
    }
  }
}
