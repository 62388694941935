.case-steps {
  margin-bottom: 60px;

  .block-title {
    padding: 0 15px;
    margin: 10px 0 30px 0;
    font-family: $fontSerif;
    font-size: rem-calc(24);
    @media #{$large-up} {
      font-size: rem-calc(38);
      line-height: rem-calc(57);
    }
  }

  .case-steps-section-title {
    @include section-title();
    margin-bottom: 0;
  }

  .case-step-not-handled-text {
    font-size: 0.8rem;
  }

  ul.case-steps-list-content {
    display: flex;
    flex-wrap: wrap;
    li {
      width: 100%;
      @media #{$large-up} {
        width: 33.33%;
        padding: 0 20px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  div.case-steps-list-content {
    @media #{$medium-up} {
      padding-left: 78px;
    }

    @media #{$large-up} {
      padding-left: 88px;
    }
  }

  .case-steps-list-content {
    padding: 20px 40px 0 60px;
    @media #{$medium-up} {
      padding-left: 78px;
    }
    @media #{$large-up} {
      padding-left: 68px;
    }
    margin: 0 0 30px 0;
    p {
      margin-bottom: 10px;
    }
  }
}

.case-steps-list {
  margin-left: 0;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    background-image: url(../gfx/icons/dot-space-vertical.svg);
    background-repeat: repeat-y;
    background-size: 3px 10px;
    width: 4px;
    height: 100%;
    left: 30px;
    z-index: 1;
    @media #{$large-up} {
      left: 38px;
    }
  }

  li {
    margin: 0 0 30px 0;
    &.subtitle {
      margin: 0;
    }
  }
}

.case-steps-list ul {
  margin-left: 68px;
}

.case-steps-list-title {
  position: relative;
  z-index: 2;
  background-color: $color-grey-light;
  margin: 0;

  @include h2();

  padding-top: 15px;
  padding-bottom: 15px;
  padding-left: 65px;
  @media #{$medium-up} {
    padding-left: 75px;
  }
  @media #{$large-up} {
    padding-left: 85px;
  }

  .number {
    @include number();
    @include number-small();
    background-color: $color-purple;
    margin: 0 15px 0 0;
    position: absolute;
    top: 10px;
    left: 10px;
    @media #{$large-up} {
      left: 20px;
    }
    border: 1px solid transparent;

    .current & {
      background-color: $color-blue;
    }

    .future & {
      background-color: transparent;
      border-color: $color-blue;
      color: $color-blue;
    }
  }

  .subtitle & {
    background-color: #fff;
    border-top: 1px solid #d8d1ca;
    padding-left: 0;
  }
}

.case-steps-content {
  p,
  a {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

.case-steps-content-title {
  @include small-title();
}

.case-steps-list .status {
  position: relative;
  z-index: 2;
}

.case-steps-list .status .case-steps-list-content {
  background-color: $color-grey-lightest;
  margin: 0;
  padding: 30px 20px;

  @media #{$medium-up} {
    padding: 40px 78px;
  }
  @media #{$large-up} {
    padding: 40px 68px;
  }
}

.case-steps-list .status .case-steps-list-title {
  margin: 0px;
  background-color: $color-blue;
  color: #fff;
  padding-left: 20px;
}
