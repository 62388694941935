.vote-help {
	background-color: #DDE8F5;
	border-left: 5px solid #407CCA;
	padding: 20px;
	margin: 40px 0;
	@media #{$large-up} {
		padding: 40px;
	}

	&.small {
		padding: 10px 15px;
		margin: 0;
	}

	a {
		@include link;
		color: $color-black;
		&:focus,
		&:hover{
			color: $color-red;
		}
	}
}


.content-container .block-vote-help .toggle-help {
	display: block;
	color: #000;
	border: none;
	padding: 0;
	// &:after {
	// 	position: absolute;
	// 	right: 25px;
	// 	top: 15px;
	// 	font-family: 'stortinget-symbols';
	// 	color: #000;
	// 	content: '\e601';
	// }
	&:hover:after {
		color: #ea4744;
	}
	&.open:after {
		content: '\e602';
	}
	@media #{$large-up} {
		display: none;
	}
}

// .block-vote-help-list {
// 	display: none;
// 	@media #{$large-up} {
// 		display: block;
// 	}
// }

.toggle-help {
	@media #{$large-up} {
		display: none;
	}
}

.block-vote-help {
	@media #{$large-up} {
		padding: 50px 30px;
	}
}

.block-vote-help-list {
	display: none;
	margin: 20px 0 0 0;
	@media #{$large-up} {
		display: block !important;
		margin: 0;
	}

	h2 {
		display: none;
		@media #{$large-up} {
			display: block;
			margin-bottom: 10px;
		}
	}

	ul {
		margin: 0;
		li {
			@include paragraph-article();
			font-size: rem-calc(18);
			margin: 0 0 5px 0;
			@media #{$medium-up} {
				margin: 0 0 0px 0;
			}
		}
	}
}

// .block-vote-help-circle {
// 	position: relative;
// 	display: inline-block;
// 	border: 1px solid #000;
// 	border-radius: 50%;
// 	width: 55px;
// 	height: 55px;
// 	border-width: 5px;
// 	padding: 5px;
// 	margin: 0 20px 0 0;
// 	text-align: center;
// 	line-height: 40px;
// 	font-size: rem-calc(28);
// 	font-weight: 700;
// 	@media #{$large-up} {
// 		width: 90px;
// 		height: 90px;
// 		line-height: 45px;
// 		font-size: rem-calc(28);
// 		padding: 15px;
// 		margin: 0 auto;
// 		border-width: 10px;
// 		left: 50%;
// 		transform: translate3d(-50%, 0, 0);
// 		background: #fff;
// 	}
// }

// .block-vote-help-label {
// 	display: inline-block;
// 	position: relative;
// 	top: -6px;
// 	@media #{$large-up} {
// 		display: none;
// 	}
// }
