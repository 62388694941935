.caselist {

    .caselist-title {
        padding: 20px;
        background-color: $color-grey-light;
        font-size: rem-calc(34);
        font-weight: bold;

    }

    .list {
        margin: 0;
        padding: 0 0 0 20px;
        list-style-type: none;
    }

    .listitem {
        margin: 0 0 40px 0;
    }

    .listitem-type {
        font-family: $fontSans;
        font-size: rem-calc(18);
        color: $color-grey-dark;
    }

    .listitem-title {
        a {
            color: $color-black;
            @include h2();
        }
    }
}
