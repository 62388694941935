div.article-title {
    margin-bottom: 20px;

    @media #{$medium-up} {
        margin-bottom: 30px;
    }
}

h2.listitem-title {
    &.listitem-title--asking-time-less-line-height{
        line-height: 1.6;

        @media #{$medium-up} {
            line-height: 1.9;
        }
    }

    .listitem-title--asking-time__date {
        margin-right: 6px;
    }
    
    .listitem-title--asking-time__time {
        display: inline-block;
    }
}

h2.oral-question-time__title,
h2.ordinary-question-time__title {
    @include paragraph-article();
    font-weight: bold;
    line-height: 1.4 !important;
}

.ordinary-question-time {
    margin-bottom: 12px;

    &__delivered-questions-title {
        @include paragraph-article();

        margin-bottom: 4px;
        display: inline-block;
    }
}

.oral-question-time {
    &-participant-list-title {
        margin-bottom: 10px;
        display: inline-block;
    }

    &-profile-image-wrapper {
        @include profile-image-wrapper;
    
        width: 50px;
        height: 50px;

        float: left;
    }

    &-person-info-wrapper {
        padding-left: 60px;
        padding-right: 1.25rem;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        .person-name-and-party {
            line-height: 1.3;
            font-weight: bold;
            font-size: 1.08rem;
        }

        .person-government-membership-role {
            line-height: 1.3;
            font-size: 1rem;
        }
    }

    .oral-question-time-participant:not(:last-child) {
        margin-bottom: 12px;
    }
}