.ordboklenke {
  color: #000 !important;
  border-bottom-style: dashed !important;
  border-bottom-color: #999 !important;

  &:hover,
  &:focus {
    border-bottom-color: #000 !important;
  }
}

#dictionaryTooltip {
  position: absolute;
  width: 300px;
  padding: 10px 15px;
  background-color: #eee;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.35);

  .no-touch & {
    .btn-close {
      display: none;
    }
  }

  .touch & {
    &.tooltip-touch {
      position: fixed;
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    .btn-close {
      position: absolute;
      top: -16px;
      right: -16px;
      padding: 0;
      border: none;
      background-color: transparent;

      &:after {
        display: inline-block;
        padding: 8px;
        font-family: "stortinget-symbols";
        content: "\e603";
        color: #fff;
        background-color: #333;
        border-radius: 50%;
      }
    }
  }

  .div-popup-heading {
    font-weight: bold;
  }
}
