.alarmbanner {
  margin-bottom: 30px;

  @media #{$large-up} {
    margin-top: 20px;
  }

  .alarmbanner-upper,
  .alarmbanner-lower {
    padding: 15px 20px;

    * {
      + * {
        margin-top: 20px;
      }
    }

    ul,
    ol,
    dl {
      padding-left: 24px;
    }
    @include clearfix();
    color: #fff;
    background-color: $color-red;
  }
  .alarmbanner-upper {
    float: left;
    border-right: 1px solid #eee;
    * {
      font-size: rem-calc(22);
      color: #fff;
    }
  }

  &.betaplayer {
    display: none;

    &.open {
      display: block;
    }

    .alarmbanner-lower {
      @include clearfix();
      position: relative;

      @media #{$medium-up} {
        padding-right: 100px;
      }
    }

    .icon-left {
      @media #{$small-only} {
        font-size: rem-calc(18);
        line-height: 1.5;
      }

      &:hover {
        color: inherit !important;
      }
    }

    .close-button {
      @media #{$small-only} {
        float: right;
        margin-top: 10px;
        margin-right: 40px;
      }

      @media #{$medium-up} {
        position: absolute;
        margin: 0;
        top: 10px;
        right: 50px;
      }
    }
  }
}

.row + .content-container .row .columns .alarmbanner {
  margin-top: -10px;
}
