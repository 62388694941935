// Symbols
@font-face {
	font-family: 'stortinget-symbols';
	src: url('../fonts/stortinget-symbols.eot');
	src: url('../fonts/stortinget-symbols.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/stortinget-symbols.ttf') format('truetype'),
		 url('../fonts/stortinget-symbols.woff') format('woff'),
		 url('../fonts/stortinget-symbols.svg#stortinget-symbols') format('svg');
	font-weight: normal;
	font-style: normal;
}

@import 'berlingske.scss';
