﻿.issue-notifications-registration-page-url,
.issue-notifications-login-page-url {
    margin-bottom: 30px;
    display: inline-block;
    font-size: 1.125rem;
}

.logged-in-notification-admin {
    .show-loading-animation,
    .loading-contact {
        display: flex;
        align-items: center;

        > img {
            margin-right: 10px;
        }
    }

    .loading-contact p {
        margin-bottom: 0;
    }

    .working-gif {
        width: 22px;
        height: 22px;
    }

    .feedback > div:not(:last-child) .panel {
        margin-bottom: 12px;   
    }
}

.issue-notification-cta-container {
    display: inline-block;

     button:focus-visible {
        outline-offset: -3px;
        outline: 2px solid white;
    }

    .loader-gif {
        display: block;
        width: 45px;
        height: 45px;
        margin: auto;
        margin-bottom: 20px;
    }
}





