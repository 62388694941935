.paging {
  margin-top: 60px;
  margin-bottom: 20px;

  ul {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    @include clearfix();

    li {
      display: block;
      float: left;
      margin-right: 10px;
      @media #{$small-only} {
        margin-bottom: 5px;
      }

      &.active,
      &.disabled {
        a {
          color: $color-black;
          border-bottom: none;
          cursor: default;
        }
      }

      &:not(.prev):not(.next):not(.first):not(.last) {
        > a:link,
        > a:visited,
        > span {
          padding-right: 4px;
          padding-left: 4px;
          font-weight: 500;

          @media #{$small-only} {
            margin-right: 10px;
            margin-bottom: 10px;
            min-width: 34px;
            padding-left: 5px;
            padding-right: 5px;
          }

          &.active {
            color: #fff;
            background-color: $primary-color;
          }

          &.inactive {
            min-width: 0;

            color: #333;
            background-color: transparent;

            &:hover,
            &:focus {
              color: #000;
              cursor: default;
            }
          }
        }
      }

      &.first,
      &.prev,
      &.next,
      &.last {
        a {
          position: relative;
        }
      }
    }
  }
}
