.nett-tv {
  .nett-tv-wrapper {
    .nett-tv-info {
      span.icon {
        display: block;
      }
    }

    .player-container {
      .player-video {
        position: relative;
        height: 0;
        padding-bottom: 56.25%;
        overflow: hidden;
        z-index: 0;

        object {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          div {
            position: absolute !important;
            width: 100% !important;
            height: 100% !important;
          }
        }

        &.player-video-timeshift {
          > div {
            position: absolute;
            top: 0;
            left: 0;
            width: 100% !important;
            height: 100% !important;
          }
        }
      }

      .player-overlay {
        position: absolute;
        z-index: 10;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        padding-bottom: 56.25%;
        cursor: pointer;

        .ie9 & {
          background-color: rgba(255, 255, 255, 0.01);
        }

        @media #{$small-only} {
          display: none;
        }
      }
    }

    @media screen and (max-width: 1024px) {
      .touch & {
        #player,
        #player #player_playerWrapper {
          position: relative;
          width: 100%;
          height: 100%;
        }
        #player #player_videoContainer {
          height: 0;
          padding-bottom: 56.25%;
          overflow: hidden;
        }

        #player #player_video {
          height: auto;
        }
      }
    }
  }

  .player-wrapper {
    position: relative;

    margin-bottom: 50px;

    @media #{$small-only} {
      .hide-on-small-touch {
        display: none;
      }
    }

    &.fullscreen {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1000;
      background-color: #000;

      .player-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }
    }

    .player-ctrl {
      position: absolute;
      z-index: 11;
      right: 0;
      bottom: -50px;
      left: 0;
      height: 50px;
      padding: 0;
      background-color: $color-black;

      .channels-label-mobile {
        display: none;
      }

      @media #{$small-only} {
        @include clearfix;
        padding-bottom: 10px;

        .channels-label {
          display: block;
          margin-bottom: 3px;
        }
      }

      .player-ctrl-play,
      .player-ctrl-volume,
      .player-ctrl-channels,
      .player-ctrl-fullscreen {
        position: relative;
      }
      .player-ctrl-play {
        float: left;
        width: 50px;
        height: 50px;
        margin-left: 20px;
      }
      .player-ctrl-volume {
        @media #{$small-only} {
          display: none;
        }
        .touch &,
        .ie9 &,
        .lt-ie9 & {
          display: none;
        }
        @media #{$medium-up} {
          width: 200px;
          float: left;
        }
      }
      .player-ctrl-channels {
        @media #{$small-only} {
          position: absolute;
          right: 50px;
          left: 58px;
        }
        @media only screen and (max-width: 25em) {
          .channels-label {
            display: none;
          }
          .channels-label-mobile {
            display: block;
          }
        }
        @media only screen and (max-width: 23em) {
          right: 50px;
        }
        @media #{$medium-up} {
          float: left;
        }
      }
      .player-ctrl-fullscreen {
        @media #{$small-only} {
        }
        @media only screen and (max-width: 23em) {
        }
        @media #{$medium-up} {
          width: 50px;
          float: right;
          height: 50px;
        }
      }

      button,
      label {
        display: block;
        margin: 0;
        font-family: inherit;
        font-size: rem-calc(14);
        font-weight: normal;
        line-height: 1.5;
        color: #fff;
        -webkit-font-smoothing: antialiased;
      }
      label {
        padding: 0;
      }
      button {
        background-color: transparent;
        border: none;
        outline: none;
        height: 50px;

        &[class^="btn-"]:before {
          position: absolute;
          bottom: 0;
          display: inline-block;
          content: "";
          width: 32px;
          height: 32px;
        }
      }
      .btn-volume-wrapper {
        position: relative;
        margin-top: 13px;

        .ie9 & {
          .btn-fallback {
            display: inline-block;
            width: auto;
            margin-left: 10px;
            padding: 0;

            &:first-child {
              margin-left: 0;
            }
          }
        }
      }
      .btn-volumebar-wrapper {
        margin-right: 42px;
        margin-left: 38px;
        position: absolute;
        top: 0px;
        left: 0;
        right: 0;

        input.btn-volume {
          width: 100%;
          display: block;
          height: 25px;
          padding: 0;

          .ie9 & {
            position: absolute;
            top: -999em;
            left: -999em;
          }
        }
      }
      .player-ctrl-column {
        float: right;
      }

      .btn-volume-down,
      .btn-volume-up {
        position: absolute;
        top: -2px;
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url(../gfx/icons/question.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      .btn-volume-down {
        background-image: url(../gfx/icons/volume_low.svg);
        &:focus,
        &:hover {
          background-image: url(../gfx/icons/volume_low_on.svg);
        }
        left: 0;
      }

      .btn-volume-up {
        background-image: url(../gfx/icons/volume_high.svg);
        &:focus,
        &:hover {
          background-image: url(../gfx/icons/volume_high_on.svg);
        }
        right: 0;
      }

      .channels-wrapper {
        .custom-dropdown {
          margin-bottom: 0;
          height: 50px;
          margin-left: 20px;
          background: $color-black;
          color: #fff;
          &:after {
            background-image: url(../gfx/icons/arrow_white_down.svg);
            transform: none;
            top: 40%;
          }

          select {
            background: #111820;
            color: #fff;
            border: none;
            padding: 15px 50px 11px 15px;
            border-left: 1px solid grey;
            border-right: 1px solid grey;
            border-bottom: 3px solid $color-black;
            height: 50px;
            &:focus,
            &:hover {
              border-bottom: 3px solid $color-red;
            }
            option {
              background-color: $color-black;
            }
          }
        }
      }
    }
  }

  @media #{$large-up} {
    margin-bottom: 20px;
  }

  .nett-tv-timeshift-wrapper {
    .nett-tv-info {
      padding: 0;
    }
  }

  @media #{$large-down} {
    .nett-tv-info {
      padding: 0;
      margin-top: 30px;

      p {
        margin-bottom: 10px;
      }
    }
  }

  .nett-tv-info ul {
    li {
      display: block;
      margin-bottom: 20px;

      a {
        &:hover,
        &:focus {
          span {
            color: darken($color-red, 10%);
          }
        }
      }
      .info-text {
        margin-left: 35px;
        display: block;
      }
      .icon {
        &.icon-question {
          top: -4px;
        }
      }
    }
  }

  input[type="range"] {
    -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
    width: 100%; /* Specific width is required for Firefox. */
    background: transparent; /* Otherwise white in Chrome */
  }

  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type="range"]:focus {
    outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
  }

  input[type="range"]::-ms-track {
    width: 100%;
    cursor: pointer;

    /* Hides the slider so custom styles can be added */
    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  /* Special styling for WebKit/Blink */
  input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $color-red;
    cursor: pointer;
    margin-top: -7px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
  }

  /* All the same stuff for Firefox */
  input[type="range"]::-moz-range-thumb {
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $color-red;
    cursor: pointer;
  }

  /* All the same stuff for IE */
  input[type="range"]::-ms-thumb {
    border: 1px solid #000000;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    background: $color-red;
    cursor: pointer;
    margin-top: 0px;
  }

  /* Track */
  input[type="range"]::-webkit-slider-runnable-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $color-grey;
    border-radius: 1.3px;
  }

  input[type="range"]:focus::-webkit-slider-runnable-track {
    background: #fff;
  }

  input[type="range"]::-moz-range-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    background: $color-grey;
    border-radius: 1.3px;
  }

  input[type="range"]::-ms-track {
    width: 100%;
    height: 2px;
    cursor: pointer;
    border-color: transparent;
    border-width: 16px 0;
    color: transparent;
  }

  input[type="range"]::-ms-fill-lower {
    background: #fff;
    border: 0.2px solid $color-grey;
    border-radius: 2.6px;
  }

  input[type="range"]:focus::-ms-fill-lower {
    background: #fff;
  }
  input[type="range"]::-ms-fill-upper {
    background: #fff;
    border: 0.2px solid $color-grey;
    border-radius: 2.6px;
  }
  input[type="range"]:focus::-ms-fill-upper {
    background: #fff;
  }
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
}

.btn-play,
.btn-pause {
  display: block;
  width: 32px;
  height: 32px;
  background-image: url(../gfx/icons/question.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.btn-play {
  background-image: url(../gfx/icons/nett_tv_play.svg);
  &:focus,
  &:hover {
    background-image: url(../gfx/icons/nett_tv_play_on.svg);
  }
}

.btn-pause {
  background-image: url(../gfx/icons/nett_tv_pause.svg);
  &:focus,
  &:hover {
    background-image: url(../gfx/icons/nett_tv_pause_on.svg);
  }
}

.btn-fullscreen {
  display: block;
  width: 50px;
  height: 50px;
  background-image: url(../gfx/icons/maximere.svg);
  background-repeat: no-repeat;
  background-position: center;
  &:focus,
  &:hover {
    background-image: url(../gfx/icons/maximere_on.svg);
  }
}

// Timeshift player
.player-ctrl-timeshift {
  margin-top: 20px;

  @media #{$medium-up} {
    margin-bottom: 40px;
  }

  .channel-tabs {
    margin-bottom: 0;
    margin-left: 0;
    padding-left: 0;
    list-style: none;

    li {
      display: inline-block;
      margin-right: 12px;
      margin-bottom: 20px;

      &:last-child {
        margin-right: 0;
      }
    }

    .btn {
      display: block;
      padding: 8px 12px 7px;
      font-family: $fontSans;
      line-height: 1;
      border: 1px solid #d8d1ca;
      background-color: #f5f3f2;
      border-radius: 30px;
      font-size: 16px;
      @media #{$small-only} {
        padding: 0.5em 15px;
      }

      &:hover,
      &:focus {
        background-color: #ddd;
      }

      &.selected {
        position: relative;
        color: #fff;
        border-color: $color-blue;
        background-color: $color-blue;

        &:before {
          position: absolute;
          top: -20px;
          left: 50%;
          display: block;
          width: 0;
          height: 0;
          margin: 0 0 0 -10px;
          content: "";
          border: inset 10px;
          border-color: transparent transparent $color-blue transparent;
          border-top-style: solid;
        }
      }
    }
  }
}
