.action-bar {
  display: none;
  @media #{$large-up} {
    display: block;
    padding: 20px 0;
    @include clearfix();
    display: flex;
  }
}

.action-margin {
  margin-bottom: 20px;
}

.breadcrumb {
  font-family: $fontSans;
  font-size: rem-calc(14);
  line-height: 1.4;
  flex: 2;
  margin-right: 40px;

  .breadcrumb-label {
    float: left;
    font-size: rem-calc(12);
    margin: 0;
    font-weight: 500;
    font-family: $fontSans;
    color: $color-grey-dark;
    margin-right: 3px;
  }

  ul {
    margin-bottom: 0;
    float: left;
    li {
      position: relative;
      float: left;
      padding-bottom: 2px;

      &:after {
        content: "-";
        color: $color-grey-dark;
        margin-right: 4px;
        display: block;
        float: right;
        margin-left: 4px;
      }

      &:last-child:after {
        content: none;
      }

      a {
        display: inline-block;
        line-height: 1em;
        color: $color-grey-dark;
        border-bottom: 1px solid $color-grey;
        font-weight: 500;
        &:hover,
        &:active {
          color: $color-red;
        }
      }
      &.sectional {
        font-weight: 500;
        color: $color-black;
        margin-right: 7px;
        font-size: rem-calc(14);
        font-weight: 500;
        &:after {
          content: none;
        }
        &.breadcrumb-label {
          padding: 0;
        }
      }
      &.current {
        color: $color-grey-dark;
        font-weight: 500;
      }
    }
  }
}

.page-actions {
  float: right;
  font-family: $fontSans;
  margin-bottom: 0;
  margin-top: -5px;
  > li {
    float: left;
    margin: 0 20px 0 0;
    a {
      display: block;
      font-size: rem-calc(14);
      color: $color-grey-dark;
      border: none;
      position: relative;
      margin: 0;

      &:before,
      &:after {
        width: 16px;
        width: 16px;
      }

      &:focus,
      &:hover,
      &.active {
        color: $color-red;
      }
    }
    &:last-child {
      margin: 0;
    }
  }
}
