.stoweb-flexible-tiles {
  &.stortingsvalg-2017 {
    .flexible-tile {
      &:not(.is-big-container) {
        min-height: 190px;
      }
    }
  }
  hr {
    margin: 0;
    display: none;
    @media #{$medium-up} {
      display: block;
    }
  }
  .flexible-tiles-header {
    display: flex;
    margin: 0 0 10px 0;
    text-align: center;
    h2 {
      flex: 0 1 auto;
      font-size: 44px;
      padding: 0 10px;
    }
    hr {
      flex: 1 1 auto;
      margin: 2em 0;
    }
  }
  .flexible-tiles-block {
    display: none;
    flex-flow: row;
    flex-wrap: nowrap;
    margin-bottom: 50px;
    @media #{$medium-up} {
      display: flex;
    }
    &.with-small-bottom-spacing {
      margin-bottom: 25px;
      ~ hr {
        display: none;
      }
    }
    &.only-image-blocks {
      .flexible-tiles-column {
        &:nth-child(1) {
          order: 1;
        }
        &:nth-child(2) {
          order: 3;
        }
        &:nth-child(3) {
          order: 2;
        }
      }
      .flexible-tile {
        display: none;
      }
      .has-image.is-big-container {
        display: flex;
      }
    }
    .flexible-tiles-column {
      display: flex;
      flex: 0 0 33.3333%;
      width: 33.3333%;
      flex-wrap: wrap;
      overflow: hidden;
    }
  }
  a.flexible-tile {
    &:hover {
      .tile-content-wrapper {
        transform: translateY(-10px);
        transition: transform 0.4s ease-in-out;
      }
    }
    &.has-image.is-big-container {
      &:hover {
        .background-image {
          transform: scale(0.92);
          transition: transform 0.4s ease-in-out;
        }
      }
    }
    &.has-red-background {
      p {
        border-bottom: 1px solid rgba(white, 0.5);
      }
    }
    p {
      border-bottom: 1px solid rgba(#711b45, 0.5);
    }
  }
  .flexible-tile {
    position: relative;
    display: flex;
    flex: 0;
    overflow: hidden;
    flex-basis: 100%;
    width: 100%;
    align-items: flex-end;
    border-bottom: 0;
    padding: 40px 80px 40px 40px;
    margin: 0;
    background-color: #d8d1ca;
    color: #711b45;
    &:nth-child(1) {
      order: 1;
    }
    &:nth-child(2) {
      order: 2;
    }
    &:nth-child(3) {
      order: 3;
    }
    &:nth-child(4) {
      order: 4;
    }
    &:nth-child(5) {
      order: 5;
    }
    &:nth-child(6) {
      order: 6;
    }
    @media #{$medium-up} {
      padding: 40px 40px 20px 20px;
    }
    @media #{$large-up} {
      padding: 40px 80px 40px 40px;
    }
    .background-image {
      height: 110%;
      width: 110%;
      position: absolute;
      top: -5%;
      left: -5%;
      z-index: 0;
    }
    .tile-content-wrapper {
      flex: 1 1 auto;
      z-index: 1;
    }
    p {
      font-family: "Berlingske Serif";
      margin: 0;
      padding: 0;
      display: inline;
      text-decoration: none;
      font-size: 34px;
      letter-spacing: 0;
      line-height: 40px;
      border: 0;
      @media #{$medium-up} {
        font-size: 28px;
        line-height: 35px;
      }
      @media #{$large-up} {
        font-size: 32px;
        line-height: 40px;
      }
    }
    &.has-red-background {
      background-color: #cf0a2c;
      color: white;
    }
    &.has-image {
      background-color: #bab2aa;
      .background-image {
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
      }
    }
    &.is-big-container {
      min-height: 300px;
      padding-top: 20px;
      .background-image {
        background-size: cover;
      }
    }
  }
  .flexible-tiles-carousel {
    position: relative;
    @media #{$medium-up} {
      display: none;
    }
    &.reset-padding {
      margin-left: -1.25rem;
      margin-right: -1.25rem;
    }
    .owl-nav {
      > div {
        width: 50px;
        height: 50px;
        position: absolute;
        top: calc(50% - 25px);
        &:after {
          content: "";
          width: 20px;
          height: 20px;
          top: 14px;
          position: absolute;
          background-size: contain;
          background-repeat: no-repeat;
        }
      }
      .owl-prev {
        left: 0;
        background-color: rgba(#111820, 0.4);
        transition: all 0.2s ease-in-out;
        &:hover {
          background-color: rgba(#111820, 0.8);
        }
        &:after {
          left: 12px;
          background-image: url("../gfx/icons/arrow_white_down.svg");
          transform: rotate(90deg);
        }
      }
      .owl-next {
        top: 0;
        height: calc(100% - 20px);
        width: 12%;
        right: 0;
        &:hover {
          &:after {
            background-color: rgba(#111820, 0.8);
          }
        }
        &:after {
          transition: all 0.2s ease-in-out;
          width: 50px;
          height: 50px;
          background-color: rgba(#111820, 0.4);
          right: 0;
          top: calc(50% - 25px);
          background-image: url("../gfx/icons/arrow_white_down.svg");
          background-size: 20px 20px;
          background-position: center;
          transform: rotate(-90deg);
        }
      }
    }
    .owl-dots {
      text-align: center;
      background-color: white;
      margin-top: 20px;
      .owl-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #b7ada5;
        margin-right: 15px;
        &.active {
          background-color: #cf0a2c;
        }
        &:last-child() {
          margin: 0;
        }
      }
    }
    .flexible-tile {
      height: 400px;
    }
  }
  /**
   * Owl Carousel v2.2.1
   * Copyright 2013-2017 David Deutsch
   * Licensed under  ()
   */
  /*
   *  Owl Carousel - Core
   */
  .owl-carousel {
    display: none;
    width: 100%;
    -webkit-tap-highlight-color: transparent;
    /* position relative and z-index fix webkit rendering fonts issue */
    position: relative;
    z-index: 1;
  }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
  }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);
  }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
  }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%;
  }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none;
  }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    cursor: hand;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel.owl-loaded {
    display: block;
  }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block;
  }
  .owl-carousel.owl-hidden {
    opacity: 0;
  }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden;
  }
  .owl-carousel.owl-drag .owl-item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab;
  }
  .owl-carousel.owl-rtl {
    direction: rtl;
  }
  .owl-carousel.owl-rtl .owl-item {
    float: right;
  }

  /* No Js */
  .no-js .owl-carousel {
    display: block;
  }

  /*
   *  Owl Carousel - Animate Plugin
   */
  .owl-carousel .animated {
    animation-duration: 1000ms;
    animation-fill-mode: both;
  }

  .owl-carousel .owl-animated-in {
    z-index: 0;
  }

  .owl-carousel .owl-animated-out {
    z-index: 1;
  }

  .owl-carousel .fadeOut {
    animation-name: fadeOut;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }

  /*
   * 	Owl Carousel - Auto Height Plugin
   */
  .owl-height {
    transition: height 500ms ease-in-out;
  }

  /*
   * 	Owl Carousel - Lazy Load Plugin
   */
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease;
  }

  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d;
  }

  /*
   * 	Owl Carousel - Video Plugin
   */
  .owl-carousel .owl-video-wrapper {
    position: relative;
    height: 100%;
    background: #000;
  }

  .owl-carousel .owl-video-play-icon {
    position: absolute;
    height: 80px;
    width: 80px;
    left: 50%;
    top: 50%;
    margin-left: -40px;
    margin-top: -40px;
    background: url("../gfx/owl.video.play.png") no-repeat;
    cursor: pointer;
    z-index: 1;
    -webkit-backface-visibility: hidden;
    transition: transform 100ms ease;
  }

  .owl-carousel .owl-video-play-icon:hover {
    -ms-transform: scale(1.3, 1.3);
    transform: scale(1.3, 1.3);
  }

  .owl-carousel .owl-video-playing .owl-video-tn,
  .owl-carousel .owl-video-playing .owl-video-play-icon {
    display: none;
  }

  .owl-carousel .owl-video-tn {
    opacity: 0;
    height: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    transition: opacity 400ms ease;
  }

  .owl-carousel .owl-video-frame {
    position: relative;
    z-index: 1;
    height: 100%;
    width: 100%;
  }
}
