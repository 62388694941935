$brighter-link-color: #fc6f6c;
$bright-font-color: #757576;
$bright-on-gray-font-color: #6a6a6c;
$alert-color-dark: darken($alert-color, 13%);

.h1 {
  @extend h1;
}
.h2 {
  @extend h2;
}
.h3 {
  @extend h3;
}

.nowrap {
  white-space: nowrap;
}

@media print {
  a[href],
  abbr[title] {
    &:after {
      content: normal;
    }
  }
}

.content-container .large-4 .block a {
  &[href^="mailto:"],
  &[href^="href:"] {
    -ms-word-break: break-all;
    word-break: break-all; // Be VERY careful with this, breaks normal words wh_erever
    word-break: break-word; // Non standard for webkit
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
  }
}

.breadcrumbs {
  font-family: $header-font-family-italic;
  font-style: italic;

  @media #{$large-up} {
    display: block;
    padding-right: 250px;
    width: 100%;
  }

  .label {
    margin-top: 0;
    margin-right: 10px;
    font-weight: 500;
    min-width: 70px;
  }

  > ul {
    li {
      font-family: inherit;
      font-style: inherit;
      color: inherit;

      &.sectional + li:before {
        content: "";
        margin-left: 0;
      }
    }

    @media #{$medium-up} {
      display: inline;
    }
  }
}

.browsehappy {
  position: relative;
  z-index: 1000;
  padding: 0.4em 20px;
  text-align: center;
}

@media #{$large-up} {
  .small-screen-only {
    display: none;
  }
}
@media #{$large-down} {
  .large-screen-only {
    display: none;
  }
}
.js {
  .no-js-only,
  .javascripthidden {
    display: none;
  }
}

.accessibility-hidden {
  @extend .visuallyhidden;
}

.row {
  &.space-above {
    margin-top: 20px;
  }
  .row.space-above {
    margin-top: 20px;
  }
}

[class*="column"] + [class*="column"]:last-child {
  float: left;
}

[class*="column"] + [class*="column"].block-vote-content:last-child {
  float: right;
}

blockquote cite:before {
  content: ""; //\2014 \0020
}

ol.no-number {
  margin-left: 0 !important;
  padding-left: 0;
  list-style: none;
}
