.seat-location-link {
  display: block;
  background-color: $color-purple;
  padding: 20px;
  text-align: center;
  transition: all 300ms ease-in-out;
  @media #{$medium-only} {
    padding-bottom: 40px;
    padding-top: 40px;
  }
  img {
    width: 40%;
    display: block;
    margin: 0 auto 20px auto;
    @media #{$medium-only} {
      margin-bottom: 30px;
    }
  }
  span {
    @include link();
    color: $color-grey-light;
    text-align: center;
    font-family: $fontSerif;
    font-size: 20px;
    line-height: 1.5;
    @media #{$medium-up} {
      font-size: 30px;
    }
    @media #{$large-up} {
      font-size: 20px;
    }
    @media #{$xlarge-up} {
      font-size: 24px;
    }
  }

  &:hover {
    background-color: #62173c;
    span {
      color: $color-grey !important;
      text-decoration: none;
      border: none;
    }
  }
}
