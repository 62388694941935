.bigdoc-navigation.bigdoc-navigation-old {
  background-color: $color-grey;
  border: none;
  border-left: 5px solid $color-purple;

  padding: 0 20px;

  margin-right: rem-calc(-20px);
  margin-left: rem-calc(-20px);
  @media #{$large-up} {
    margin-left: 0;
    margin-right: 0;
    padding: 20px 40px;
  }

  .border-right {
    border-right: 1px solid $color-grey-darker;
  }

  .custom-dropdown {
    @media #{$medium-up} {
      margin-bottom: 20px;
    }
  }

  .bigdoc-info-toggle-small {
    .columns:last-child {
      padding-bottom: 20px;
      @media #{$large-up} {
        padding-bottom: 0;
      }
    }
  }
}
