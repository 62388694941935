ul.social-sharing-list-mobile {
  margin-top: 40px;
  text-align: center;
  li {
    display: inline-block;
    margin-right: 20px;
    &:last-child {
      margin-right: 0;
    }
    a {
      border: none;
      color: $color-grey-dark;
      @include icon();
      @include icon-left();
      margin-bottom: 10px;

      &.facebook {
        @include icon-small-facebook();
        @include icon-no-animation();
      }
      &.twitter {
        @include icon-small-twitter();
        @include icon-no-animation();
      }
      &.email {
        @include icon-small-email();
        @include icon-no-animation();
      }
    }
  }
}
