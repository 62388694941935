.page-home {
  .alert-wrapper {
    .alert-hearings {
      margin-bottom: -10px !important;
    }
  }
}

.alert-hearings {
  background-color: $color-grey-lightest;
  padding: rem-calc(30px);
  @media #{$medium-up} {
    padding: rem-calc(30px) rem-calc(40px);
  }
  margin-bottom: 40px;
  border-left: 5px solid $color-red;
  &:not(.block-events-alert) {
    p {
      @include paragraph-article();
    }
  }

  p {
    line-height: 1.6 !important;
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.whats-up-cell > .alert-hearings {
  border-left: 5px solid $color-red;
}
