

@mixin h1(){
  font-family: $fontSerif;
  font-size: rem-calc(26px);
  line-height: 1.3;
  @media #{$medium-up} {
    font-size: rem-calc(44px);
  }
}

@mixin h2(){
  font-family: $fontSerif;
  font-weight: 700;
  font-size: rem-calc(20);
  line-height: 1.4;
  @media #{$medium-up} {
    font-size: rem-calc(22);
  }
}

@mixin h3(){
  font-family: $fontSerif;
  font-size: 18px;
  line-height: 1.4;
  font-weight: 700;
  @media #{$medium-up} {
    font-size: rem-calc(19);
  }
}


@mixin visually-hidden(){
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

@mixin block-title(){
  font-family: $fontSerif;
	margin-top: 0;
  margin-bottom: 15px;
  font-size: rem-calc(20);
  @media #{$medium-up} {
     font-size: rem-calc(22);
  }
	&.block-title-small{
		font-size: rem-calc(18);
	}
}

@mixin section-title(){
  font-family: $fontSerif;
  font-weight: 700;
  font-size: rem-calc(24px);
  margin-bottom: 30px;
  @media #{$medium-up} {
    font-size: rem-calc(34px);
  }
}

@mixin listing-group-title(){
    @include section-title();
    margin-bottom: rem-calc(30);
		padding: 8px 20px 5px;

		background-color: $color-grey-light;

		&:not(:first-of-type) {
			margin-top: rem-calc(40px);
		}
    @media #{$medium-up} {
      margin-left: -20px;
      margin-right: -20px;
    }
    &.clean{
			@media #{$small-only} {
				padding: 0;
			}
			background-color: transparent;
		}
}

@mixin paragraph-article(){
  font-family: $fontSerifText;
  font-size: rem-calc(18px);
  line-height: 1.6;
  @media #{$medium-up} {
    font-size: rem-calc(19px);
    line-height: 2;
  }
}

@mixin meta-title(){
  font-family: $fontSans;
  font-size: rem-calc(15px);
  font-weight: 500;
  line-height: 1.4;
  color: $color-grey-dark;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

@mixin small-title(){
  font-family: $fontSans;
  font-size: rem-calc(16px);
  color: $color-black;
  line-height: 1.5;
  font-weight: 700;
}

@mixin medium-title(){
  font-family: $fontSans;
  font-size: rem-calc(18px);
  @media #{$medium-up} {
    font-size: rem-calc(19px);
  }
}

@mixin link( $border-color: #e3e2e2) {
    color: #B20019;
    text-decoration: none;
    //  line-height: inherit;
    padding-bottom: 0px;
    border-bottom: 1px solid $border-color;
    border-bottom-color: rgba(200,200,200, 0.8);
    font-weight: 400;

    &:hover,
    &:focus {
        color: darken(#B20019, 10%);
    }
}



@mixin link-on-grey($theColor : $color-grey){
  @include link($theColor);
}


@mixin no-bullet(){
  list-style: none;
  margin: 0;
  padding: 0;

  > li{
    list-style: none;
  }
}

@mixin number(){
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  color: #fff;
  font-weight: bold;
  font-family: $fontSerif;
}

@mixin number-large(){
  width: 55px;
  height: 55px;
  padding: 10px;
  font-size: rem-calc(24);
  line-height: 1.6;
  @media #{$large-up} {
    width: 60px;
    height: 60px;
    font-size: rem-calc(34);
    line-height: 42px;
  }
}


@mixin number-small(){

  font-size: rem-calc(28);
  line-height: 42px;
  width: 40px;
  height: 40px;
  font-weight: 500;
  font-family: $fontSerifText;
}

@mixin profile-image-wrapper(){
  position: relative;
  width: 75px;
  height: 75px;
  display: inline-block;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto;
  img{
    width: 100%;
  }

  &.big{
    width: 120px;
    height: 120px;
    display: block;
    border: 1px solid $color-grey;
  }
}


@mixin button-circular(){
  display: block;
  position: relative;
  z-index: 1;
  width: 56px;
  height: 56px;
  padding: 0;
  border-bottom: none;
  outline: none;
  background: #fff;
  border-radius: 50%;

  @media #{$extra-small-only} {
	  width: 40px;
	  height: 40px;
  }

  &:before,
  &:after{
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 56px;
      height: 56px;
      position: absolute;
      content: "";

	  @media #{$extra-small-only} {
		width: 40px;
		height: 40px;
	  }
  }
  
}

@mixin panel(){
  display: block;
	padding: 20px;
	@media #{$medium-up} {
		padding: 40px;
  }
  p {

		@include paragraph-article();
		font-size: rem-calc(17px);
		line-height: 1.8;
		@media #{$large-up} {
			line-height: 1.8;
		}
		&:last-child{
			margin: 0;
		}
	}
}