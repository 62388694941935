// Old style wide table wrapping
.tblwrp-wrapper-trigger {
  display: block;
  margin: 0;
  padding: 0;
  font-family: inherit;
  line-height: inherit;
  color: $color-red;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  @include link();
  padding: 0;
  line-height: 1.1;
  margin-bottom: 10px;

  &:hover,
  &:focus {
    background-color: transparent;
    color: darken($color-red, 10%);
  }
}

.tblwrp-wrapper {
  position: relative;
  margin-bottom: 20px;

  &.tblwrp-minified {
    .tblwrp-scrollarea {
      width: 100%;
      overflow-x: scroll;
      overflow-y: auto;
    }

    table {
      border-right: 90px solid transparent;
    }

    .tblwrp-fader {
      position: absolute;
      top: 0;
      right: -1px;
      bottom: 0;
      width: 50px;
      background: -moz-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -webkit-gradient(
        linear,
        left top,
        right top,
        color-stop(0%, rgba(255, 255, 255, 0)),
        color-stop(100%, rgba(255, 255, 255, 1))
      );
      background: -webkit-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -o-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: -ms-linear-gradient(
        left,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 1) 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 );
    }
  }
}

// New style wide table wrapping (big) documents
.tablewrapper {
  position: relative;
  margin-bottom: 20px;

  &.wide-table {
    overflow: hidden;
  }

  table {
    margin-top: 10px;
  }
}

.tablewrapper-fader {
  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  width: 12px;
  background: -moz-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ddd 100%);
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(0%, rgba(255, 255, 255, 0)),
    color-stop(100%, #ddd)
  );
  background: -webkit-linear-gradient(
    left,
    rgba(255, 255, 255, 0) 0%,
    #ddd 100%
  );
  background: -o-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ddd 100%);
  background: -ms-linear-gradient(left, rgba(255, 255, 255, 0) 0%, #ddd 100%);
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, #ddd 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#dddddd',GradientType=1 );
}

.page-publication .bigdoc-content,
.tablewrapper-lightbox {
  table {
    th,
    td,
    p {
      margin-bottom: 0;
      font-family: $fontSans;
      font-size: rem-calc(16);
    }
  }
}

body.lightbox-open {
  overflow: hidden;
}

.btn-zoom.icon-left {
  display: block;
  margin: 0;
  margin-top: 10px;
  padding: 6px 10px 2px 40px;
  font-family: $fontSans;
  font-size: rem-calc(14);
  line-height: inherit;
  color: #000;
  background-color: #f5f3f2;
  border: 1px solid #e6e1dd;
  outline: none;

  &:before,
  &:after {
    top: 2px;
    left: 5px;
    background-size: 60%;
  }

  &:hover,
  &:focus {
    background-color: #e5e2e1;
  }

  + img {
    margin-top: 10px;
  }

  + .tablewrapper {
    margin-top: 0 !important;

    table {
      margin-top: 20px;
    }
  }
}
.page-publication .bigdoc-content,
.article-content {
  img + .btn-zoom {
    margin-top: 10px;
  }
}

.tablewrapper-lightbox-wrapper {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.2);

  .tablewrapper-lightbox {
    position: absolute;
    top: 10px;
    right: 10px;
    left: 10px;
    overflow: hidden;
    padding: 60px 20px 20px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 0 200px #333;
    box-shadow: 0 0 200px rgba(0, 0, 0, 0.7);

    @media only screen and (min-width: 670px) {
      top: 60px;
      right: 20px;
      left: 20px;
      padding-top: 90px;
    }

    .tablewrapper-lightbox-inner {
      position: relative;
      overflow: auto;
      display: inline-block;
      max-width: 100%;
      max-height: 100%;

      &:focus {
        outline: none;
      }

      img:focus {
        outline: none;
      }

      table {
        margin-top: 0;
        margin-bottom: 0;
      }

      .tablewrapper-fader {
        display: none;
      }
    }
  }

  .close-lightbox {
    display: block !important;
    position: absolute;
    top: 10px;
    right: 10px;
    margin-top: 0;

    @media only screen and (min-width: 670px) {
      top: 35px;
      right: 20px;
    }
  }
}
