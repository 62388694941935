.panel.infobanner {
  margin-bottom: 30px;
  border-left: 5px solid $color-blue-light;
  background-color: $color-blue-lightest;

  @media #{$large-up} {
    margin-top: 20px;
  }

  @media #{$large-down} {
    margin-right: rem-calc(-20);
    margin-left: rem-calc(-20);
  }
}
