.block-find-representatives {
  position: relative;
  background-color: $color-grey-light;
  padding: 40px;
  overflow: hidden;

  form {
    margin-bottom: 30px;
  }

  .clear-search {
    display: none;
    position: absolute;
    top: 0px;
    right: 0px;
    border: none !important;
    font-size: rem-calc(24);
    color: #e2e1e1 !important;
    padding: 12px 20px !important;
  }

  .form-area {
    position: relative;
  }

  .form-area .find-representatives-query {
    background-color: #fff;
    margin: 0 0 10px 0;
  }

  .form-area label.show-historical {
    font-size: rem-calc(16);
    color: #222;
    float: right;
  }

  .form-area label.show-historical:before {
    color: #fff;
    background-color: #fff;
  }

  .submit {
    width: 25%;
    border: none;
    background: $color-red;
    color: #fff;
    padding: 11px;
    @media #{$large-up} {
      width: 15%;
    }
  }

  .total-representatives {
    float: left;
    font-style: italic;
    color: #222;
    font-size: rem-calc(14);
  }

  .block-scrollable-wrapper {
    margin-top: 60px;
  }

  .block-scrollable {
    clear: both;
    width: 98%;
    height: 280px;
    overflow: hidden;
    margin-bottom: 15px;
    @media #{$large-up} {
      margin-bottom: 0;
      height: 310px;
      width: 94%;
    }
  }

  .block-scrollable .block-scrollable-items {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
  }
  .block-scrollable .block-scrollable-items li {
    float: left;
    width: 100%;
    padding: 0;
    padding: 10px 0;

    &:last-child {
      margin: 0;
    }
  }

  .prev-page,
  .next-page {
    float: left;
    width: 47%;
    margin-top: 25px;
    margin-right: 6%;
    border: none;
    background: $color-red;
    outline: none;
    color: #fff;
    padding: 15px 0;
    &:last-child {
      margin-right: 0;
    }
    &.disabled {
      opacity: 0.4;
    }
    @media #{$xlarge-up} {
      display: none;
    }
    &:focus {
      border: black 1px solid;
    }
  }

  /* Scrollbar */
  .scrollbar {
    position: absolute;
    right: 40px;
    width: 3px;
    height: 280px;
    background: #e3e2e2;
    line-height: 0;
    @media #{$large-up} {
      width: 6px;
      right: 40px;
    }
  }
  .scrollbar .handle {
    width: 100%;
    height: 100px;
    background: $color-red;
    cursor: pointer;
  }
  .scrollbar .handle .mousearea {
    position: absolute;
    top: 0;
    left: -12px;
    width: 30px;
    height: 100%;
  }

  .block-image-wrapper {
    background-color: #fff;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    width: 120px;
    height: 120px;
    display: block;
    border: 1px solid $color-grey;

    @media #{$medium-up} {
      float: left;
    }

    img {
      width: 100%;
    }
  }

  .block-profile-content {
    padding: 12px 0;
    text-align: center;
    @media #{$medium-up} {
      margin-left: 173px;
      text-align: left;
    }
  }

  .block-profile-name {
    display: inline;
    margin: 0 0 10px 0;
    padding-bottom: 1px;
    line-height: 1;
    text-transform: capitalize;

    .block-profile-number {
      text-transform: none;
    }
  }

  //hide number from government memebers except the prime minister (seat 170)
  @for $i from 171 through 191 {
    .block-profile-seat-#{$i} {
      display: none;
    }
  }

  .block-profile-party {
    display: block;
    font-weight: bold;
    line-height: 1;
    margin: 10px 0 15px 0;
    font-size: rem-calc(14);
  }
  .block-profile-county,
  .block-profile-title {
    display: block;
    font-size: rem-calc(14);
    line-height: 1;
    margin: 0 0 10px 0;
  }
  .block-profile-party + .block-profile-county,
  .block-profile-title {
    margin-top: -5px;
  }
  .block-profile-position {
    display: block;
    font-size: rem-calc(14);
    line-height: 1;
    margin: 0;
  }
}
