.bigdoc-table-of-content {
  display: none;
  position: absolute;
  overflow-y: scroll;
  z-index: 2;
  right: 20px;
  left: 20px;
  margin-top: -2px;
  padding: 20px 30px 30px;
  background-color: #fff;
  box-shadow: 0 2px 10px 1px rgba(0, 0, 0, 0.25);

  .h1 {
    margin-bottom: rem-calc(30);
  }

  @media #{$small-only} {
    top: 88px;
  }

  @media #{$large-up} {
    left: 20px;
  }

  &.open {
    display: block;

    @media #{$large-down} {
      z-index: 1000;
    }
  }

  ul {
    margin-left: 0;
    list-style: none;
    padding-left: 0;
    font-size: rem-calc(16);

    ul {
      display: none;
      list-style: none;
      margin-top: rem-calc(10);
      margin-left: 0;

      &.open {
        display: block;
        padding-left: rem-calc(35);
      }
    }

    li {
      position: relative;
      margin-top: rem-calc(10);
    }

    a {
      display: inline-block;
      margin-left: rem-calc(35);
      padding-right: 28px;
      line-height: 1.5;
      border-bottom: none;

      span {
        display: inline;
      }

      &.present {
        font-weight: 700 !important;
      }
    }
  }

  > ul > li {
    margin-bottom: rem-calc(10);
  }

  .bigdoc-item-toggler {
    position: absolute;
    top: -2px;
    left: -5px;
    width: 28px;
    height: 28px;
    padding: 0;
    background-color: transparent;
    border: none;
    outline: none;

    &:after {
      position: absolute;
      top: 2px;
      left: 0;
      width: 24px;
      line-height: 22px;
      font-family: "stortinget-symbols";
      font-size: 10px;
      font-style: normal;
      text-align: center;
      content: "\e606";
      color: #bcbdbd;
      background-color: #fff;
      border: 1px solid #bcbdbd;
      border-radius: 50%;
    }
    &:hover,
    &:focus {
      &:after {
        color: #666;
        border-color: #666;
      }
    }
    &.active {
      &:after {
        content: "\e605";
      }
    }

    &.persist:after {
      color: $color-red;
      border-color: $color-red;
    }

    .label {
      position: absolute;
      left: -999em;
    }
  }
}
