.article-content table {
  td {
    font-size: rem-calc(18px);

    p {
      margin-top: 0;
      margin-bottom: 0;
      font-size: rem-calc(18px);
      line-height: inherit !important;

      + p {
        margin-top: 30px;
      }
    }
  }
}

.table-mobile-overflow-container {
  overflow-x: auto;
}

.rss-table-row {
  th > a {
    color: #b20019;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5;
    font-family: "Berlingske Serif Text";
    &:focus,
    &:hover {
      color: #7f0012;
    }
  }
}

table {
  margin-bottom: rem-calc(40);
  border: none;
  width: 100%;

  caption {
    margin-bottom: rem-calc(10);
    text-align: left;
    font-family: $fontSans;
    font-size: rem-calc(19px);
    font-weight: 700;
    font-size: rem-calc(17px);
  }

  th,
  td {
    padding: 10px 0;
    padding-right: 20px;
    &:last-child {
      padding-right: 0;
    }
    text-align: left;
    vertical-align: top;
    font-weight: 400;

    border: none;
    @include paragraph-article();

    .article-content {
      @include paragraph-article();
    }
  }
  th {
    line-height: 1.35;
  }
  td {
    font-family: $fontSerifText;
    line-height: 1.5;
  }

  thead td,
  th {
    @include small-title();
    a {
      color: $color-black;
      font-weight: 700;
      &:hover,
      &:focus {
        color: $color-red;
      }
    }
  }

  tr {
    border-bottom: 1px solid $color-grey;
  }
}

tr.listing-imitation {
  border-bottom: none;
  h2.listing-imitation-group-title {
    @include listing-group-title();
    margin-bottom: 10px;
  }
}

.table-heading {
    font-size: 19px;
    font-family: "Berlingske Serif";
}