[data-carousel-id="owl-demo"] {
  position: relative;
  width: 100%;
  margin-bottom: 10px;
  background-color: $color-grey-light;
  -ms-touch-action: pan-y;

  .js & {
    display: none;
  }

  .carousel-no-js {
    background-color: #fff;
  }

  .owl-wrapper {
    display: none;
    position: relative;
    -webkit-transform: translate3d(0px, 0px, 0px);

    &:after {
      content: ".";
      display: block;
      clear: both;
      visibility: hidden;
      line-height: 0;
      height: 0;
    }
  }

  .owl-wrapper,
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  .owl-wrapper-outer {
    overflow: hidden;
    position: relative;
    width: 100%;

    &.autoHeight {
      -webkit-transition: height 500ms ease-in-out;
      -moz-transition: height 500ms ease-in-out;
      -ms-transition: height 500ms ease-in-out;
      -o-transition: height 500ms ease-in-out;
      transition: height 500ms ease-in-out;
    }
  }

  .owl-item {
    float: left;
    visibility: hidden;
    &.active {
      visibility: visible;
    }
  }
}

.owl-item-inner {
  @include clearfix();
  position: relative;

  .owl-image-wrap {
    display: block;

    @media #{$large-up} {
      float: left;
      width: 66%;
      height: 0;
      padding-bottom: 44.5% !important;
    }

    a {
      display: block;
      text-align: center;
      border-bottom: none !important;
    }

    @media #{$large-down} {
      img {
        height: 400px !important;
      }
    }

    @media #{$small-only} {
      img {
        height: 0 !important;

        .active & {
          height: auto !important;
        }
      }
    }
  }

  .owl-text {
    margin-top: 20px;

    @media #{$large-down} {
      display: none;
      height: auto !important;
      margin-right: 30px;
      margin-left: 30px;

      .active & {
        display: block;
      }
    }

    @media #{$large-up} {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      width: 27%;
      margin-right: 3%;
      padding-right: 16px;

      .owl-text-inner {
        position: absolute;
        bottom: 0;
        min-height: 60%;
      }
    }

    h3 {
      @extend h1;
      font-size: rem-calc(24);
      line-height: 1.3;

      @media #{$small-only} {
        font-size: rem-calc(22);
      }
    }
  }
}

[data-carousel-id="owl-demo"] .owl-nav {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  .owl-page,
  .owl-buttons div {
    cursor: pointer;
  }

  .owl-prev,
  .owl-next {
    display: block;
    position: absolute;
    z-index: 1;
    width: 56px;
    height: 56px;
    margin: -32px 0 0;
    padding: 0;
    border-bottom: none;
    outline: none;

    @media #{$large-down} {
      top: 210px;
    }

    @media #{$small-only} {
      top: 110px;
    }

    @media #{$large-up} {
      top: 50%;
    }

    &:before,
    &:after {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 56px;
      height: 56px;
      content: "";
      background-color: transparent;
      background-repeat: no-repeat;
      background-image: url("../gfx/icons/old/owl-nav-sprite.svg");
      background-size: 56px 266px;
      border: 3px solid transparent;
      border-radius: 60px;

      .no-svg & {
        background-image: url("../gfx/icons/old/owl-nav-sprite.png");
      }
    }

    &:before {
      opacity: 1;
    }

    &:after {
      opacity: 0;
      border: 3px solid;
    }

    &:hover,
    &:focus {
      &:after {
        opacity: 1;
      }

      &:before {
        border-color: $color-black;
      }

      background: transparent;
    }
  }

  .owl-next {
    @media #{$large-down} {
      right: -15px;
    }

    @media #{$large-up} {
      left: 63%;
    }

    @media #{$xlarge-up} {
      left: 64%;
    }

    &:before {
      background-position: -3px -3px;
    }

    &:after {
      background-position: -3px -73px;
    }
  }

  .owl-prev {
    @media #{$large-down} {
      left: -15px;
    }

    @media #{$large-up} {
      left: -20px;
    }

    @media #{$xlarge-up} {
      left: -30px;
    }

    &:before {
      background-position: -3px -143px;
    }

    &:after {
      background-position: -3px -213px;
    }
  }
}

// mouse grab icon
.grabbing {
  cursor: url("../gfx/grabbing.png") 8 8, move;
}
