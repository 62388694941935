.hall-placement {
  text-align: center;
}

.hall-placement-image {
  width: 243px;
  display: block;
  margin: 0 auto 30px auto;
}

.hall-placement-link {
  background-color: $color-purple;
  border: none;
  line-height: rem-calc(38);
  color: $color-grey;
  width: 70%;
  display: inline-block;
  border-bottom: none;
  padding: 30px 0px;
  transition: all 300ms ease-in-out;

  @media #{$medium-up} {
    padding: 95px 0px;
  }

  .hall-placement-text {
    width: 60%;
    margin: 0 auto;
  }

  span {
    @include link;
    font-family: $fontSerif;
    color: $color-grey;
    font-size: 20px;
    line-height: 1.5;

    @media #{$medium-up} {
      font-size: 35px;
    }
  }

  &:focus,
  &:hover {
    border: none;
    background-color: #62173c;

    span {
      color: $color-grey !important;
      text-decoration: none;
    }

    .hall-placement-text {
      width: 60%;
      margin: 0 auto;
      border: white 1px solid;
    }
  }
}
