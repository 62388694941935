.panel {
  @include panel();

  margin: 0 0 40px 0;
  &.notice {
    border-left: 5px solid $color-blue-light;
    background-color: $color-blue-lightest;
  }

  &.alarmbanner {
    border-left: 5px solid $color-red;
    background-color: $color-grey-lightest;
  }

  .panel-title {
    @include block-title();
  }

  &.no-arrow {
    &:after {
      content: none;
    }
  }

  &.space-above {
    margin-top: 40px;
  }
}
