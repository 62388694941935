.article-title {
  margin-bottom: 30px;
}

.article-title h1 {
  @include h1();
  margin: 0;
}

.article-pre-title {
  margin-bottom: 0;
  font-weight: bold;

  @media #{$medium-up} {
    font-size: rem-calc(18);
  }
}

.article-excerpt {
  font-size: rem-calc(20px);
  font-family: $fontSerifText;
  line-height: 1.6;
  margin-bottom: 30px;
  @media #{$medium-up} {
    font-size: rem-calc(28px);
  }
  p {
    margin-top: 0;
    font-size: rem-calc(20px);
    font-family: $fontSerifText;
    line-height: 1.6;
    @media #{$medium-up} {
      font-size: rem-calc(28px);
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article-mainimage {
  @extend .article-content-image;
  @media #{$large-up} {
    clear: right;
    float: right;
    margin-right: -175px;
    margin-left: 30px;
  }
}
