/* General Icon styles */

@mixin icon() {
    line-height: 32px;
    &::before,
    &::after {
        content: "";
        background-position: center center;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
    }
    & a,
    a & {
        display: inline-block;
        border-bottom: none;
    }
    > * {
        @include link();
    }
}

@mixin icon-small-right() {
    &::before,
    &::after {
        width: 16px;
        height: 16px;
        background-size: 16px;
        position: absolute;
        right: 4px;
        top: 2px;
    }
    padding-right: 26px;
    position: relative;
    line-height: inherit;
}

@mixin icon-small-left() {
    &::before,
    &::after {
        width: 16px;
        height: 16px;
        background-size: 16px;
        left: -22px;
        top: 2px;
        position: absolute;
    }

    margin-left: 26px;
    position: relative;
    line-height: inherit;
}

@mixin icon-right() {
    &::before,
    &::after {
        position: absolute;
        right: 3px;
        top: 0;
    }
    position: relative;
    padding-right: 39px;
}

@mixin icon-left() {
    display: inline;
    &::before,
    &::after {
        position: absolute;
        left: -38px;
    }
    position: relative;
    margin-left: 35px;
}

.icon-left,
.icon-right {
    @include icon();
}

.icon-left {
    @include icon-left();
}

.icon-right {
    @include icon-right();
}

.icon-small-right {
    @include icon-small-right();
}

/* Icons */

@mixin icon-file($filepath, $filepath-hover: $filepath) {
    &::before {
        background-image: $filepath;
        opacity: 1;
    }
    &::after {
        background-image: $filepath-hover;
        opacity: 0;
    }
    &:hover,
    &.active,
    &:focus {
        &::before {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &::after {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
    }
}

@mixin icon-agenda() {
    @include icon-file(
        url("../gfx/icons/document.svg"),
        url("../gfx/icons/document-hover.svg")
    );
}
@mixin icon-find-similar() {
    @include icon-file(
        url("../gfx/icons/search.svg"),
        url("../gfx/icons/search-hover.svg")
    );
}

@mixin icon-print() {
    @include icon-file(
        url("../gfx/icons/print-grey.svg"),
        url("../gfx/icons/print-grey-hover.svg")
    );
}

@mixin icon-search() {
    @include icon-file(
        url("../gfx/icons/search.svg"),
        url("../gfx/icons/search-hover.svg")
    );
}

@mixin icon-report() {
    @include icon-file(
        url("../gfx/icons/report.svg"),
        url("../gfx/icons/report-hover.svg")
    );
}

@mixin icon-arrow-down() {
    @include icon-file(
        url("../gfx/icons/arrow-down.svg"),
        url("../gfx/icons/arrow-down.svg")
    );
    &::before,
    &::after {
        height: 10px;
        width: 10px;
        background-size: 10px;
        // margin-left: 10px;
        // margin-top: 9px;
    }
}

@mixin icon-arrow-right() {
    @include icon-file(
        url("../gfx/icons/arrow-right.svg"),
        url("../gfx/icons/arrow-right.svg")
    );
    &::before,
    &::after {
        height: 10px;
        width: 10px;
        background-size: 8px;
        // margin-left: 10px;
        // margin-top: 9px;
    }
}

@mixin icon-arrow-left() {
    @include icon-file(
        url("../gfx/icons/arrow-left.svg"),
        url("../gfx/icons/arrow-left.svg")
    );
    &::before,
    &::after {
        height: 10px;
        width: 10px;
        background-size: 8px;
    }
}

@mixin icon-no-animation() {
    &::before,
    &::after {
        transition: none;
    }
    &:hover,
    &.active,
    &:focus {
        &::before,
        &::after {
            transition: none;
        }
    }
}

@mixin icon-toggle-menu() {
    @include icon-file(
        url("../gfx/icons/hamburger.svg"),
        url("../gfx/icons/hamburger.svg")
    );
}

@mixin icon-speech() {
    @include icon-file(
        url("../gfx/icons/speechlist.svg"),
        url("../gfx/icons/speechlist-hover.svg")
    );
}

@mixin icon-video() {
    @include icon-file(
        url("../gfx/icons/video-archive.svg"),
        url("../gfx/icons/video-archive-hover.svg")
    );
}

@mixin icon-download() {
    @include icon-file(
        url("../gfx/icons/download.svg"),
        url("../gfx/icons/download-hover.svg")
    );
}

@mixin icon-download-white() {
    @include icon-file(
        url("../gfx/icons/download-white.svg"),
        url("../gfx/icons/download-white.svg")
    );
}

@mixin icon-sharing() {
    @include icon-file(
        url("../gfx/icons/share.svg"),
        url("../gfx/icons/share-hover.svg")
    );
}

@mixin icon-email() {
    @include icon-file(
        url("../gfx/icons/email.svg"),
        url("../gfx/icons/email-hover.svg")
    );
}

@mixin icon-info() {
    @include icon-file(
        url("../gfx/icons/info.svg"),
        url("../gfx/icons/info-hover.svg")
    );
}

@mixin icon-question() {
    @include icon-file(
        url("../gfx/icons/question.svg"),
        url("../gfx/icons/question-hover.svg")
    );
}

@mixin icon-plus() {
    @include icon-file(
        url("../gfx/icons/toggle-open.svg"),
        url("../gfx/icons/toggle-open.svg")
    );
}

@mixin icon-minus() {
    @include icon-file(
        url("../gfx/icons/toggle-close.svg"),
        url("../gfx/icons/toggle-close.svg")
    );
}

@mixin icon-vote() {
    @include icon-file(
        url("../gfx/icons/votering.svg"),
        url("../gfx/icons/votering-hover.svg")
    );
}

@mixin icon-maximize() {
    @include icon-file(
        url("../gfx/icons/maximize.svg"),
        url("../gfx/icons/maximize-hover.svg")
    );
}

@mixin icon-category() {
    @include icon-file(
        url("../gfx/icons/category.svg"),
        url("../gfx/icons/category-hover.svg")
    );
}

@mixin icon-small-facebook() {
    @include icon-file(
        url("../gfx/icons/social-small-facebook.svg"),
        url("../gfx/icons/social-small-facebook.svg")
    );
}

@mixin icon-small-twitter() {
    @include icon-file( 
        url("../gfx/icons/social-logo-x-black.svg"), 
        url("../gfx/icons/social-logo-x-black.svg") 
    );
}

@mixin icon-small-email() {
    @include icon-file(
        url("../gfx/icons/social-small-email.svg"),
        url("../gfx/icons/social-small-email.svg")
    );
}

@mixin icon-zoom-in() {
    @include icon-file(
        url("../gfx/icons/plus.svg"),
        url("../gfx/icons/plus-hover.svg")
    );
}

@mixin icon-zoom-out() {
    @include icon-file(
        url("../gfx/icons/minus.svg"),
        url("../gfx/icons/minus-hover.svg")
    );
}

@mixin icon-closing() {
    @include icon-file(
        url("../gfx/icons/closing.svg"),
        url("../gfx/icons/closing-hover.svg")
    );
}

@mixin icon-toggle() {
    &::before {
        background-image: url("../gfx/icons/toggle-open.svg");
        opacity: 1;
    }
    &::after {
        background-image: url("../gfx/icons/toggle-open-hover.svg");
        opacity: 0;
    }
    &:hover,
    &:focus {
        &::before {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &::after {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
    }
    &.active {
        &::before {
            background-image: url("../gfx/icons/toggle-close.svg");
            opacity: 1;
        }
        &::after {
            background-image: url("../gfx/icons/toggle-close-hover.svg");
            opacity: 0;
        }
    }
}

.icon-agenda,
.icon-document {
    @include icon-agenda();
}

.icon-find-similar {
    @include icon-find-similar();
}

.icon-print {
    @include icon-print();
}

.icon-search {
    @include icon-search();
}

.icon-report {
    @include icon-report();
}

.icon-arrow-down {
    @include icon-arrow-down();
}

.icon-arrow-right {
    @include icon-arrow-right();
}

.icon-toggle-menu {
    @include icon-toggle-menu();
}

.icon-closing {
    @include icon-closing();
}

.icon-calendar {
    position: relative;
    display: block;
    line-height: 32px;
    border-bottom: none;
    &::before,
    &::after {
        content: "";
        position: absolute;
        right: 3px;
        top: 0;
        background-position: center center;
        background-repeat: no-repeat;
        width: 32px;
        height: 32px;
    }

    &::before {
        background-image: url("../gfx/icons/calendar.svg");
        opacity: 1;
    }
    &::after {
        background-image: url("../gfx/icons/calendar-hover.svg");
        opacity: 0;
    }

    &:hover,
    &.active,
    &:focus {
        &::before {
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
        }
        &::after {
            opacity: 1;
            transition: opacity 0.2s ease-in-out;
        }
    }
    &a {
        border-bottom: none;
    }

    a {
        line-height: 1.2;
    }
}

.icon-speech {
    @include icon-speech();
}

.icon-video {
    @include icon-video();
}

.icon-download {
    @include icon-download();
}

.icon-download-white {
    @include icon-download-white();
}

.icon-sharing {
    @include icon-sharing();
}

.icon-email {
    @include icon-email();
}

.icon-info {
    @include icon-info();
}

.icon-question {
    @include icon-question();
}

.icon-exclamation {
    @include icon-info();
}

.icon-plus {
    @include icon-plus();
}

.icon-minus {
    @include icon-minus();
}

.icon-vote {
    @include icon-vote();
}

.icon-small-facebook {
    @include icon-small-facebook();
}

.icon-small-twitter {
    @include icon-small-twitter();
}

.icon-small-email {
    @include icon-small-email();
}

.icon-toggle {
    @include icon-toggle();

    &:focus {
        outline: none;
    }
}

a.icon-toggle {
    &:focus {
        outline: none;

        &::before, &::after {
            outline: 2px solid black;
        }
    }
}

.icon-maximize {
    @include icon-maximize();
}

.icon-category {
    @include icon-category();
}

.icon-zoom-in {
    @include icon-zoom-in();
}

.icon-zoom-out {
    @include icon-zoom-out();
}

.icon-notification {
    @include icon-file(
        url("../gfx/icons/notification.svg"),
        url("../gfx/icons/notification-hover.svg")
    );

    display: inline-block;
    font-family: $fontSans;
    font-size: 1rem;

    &.icon-right {
        display: inline-block;
        padding-right: 29px;
    }

    &.icon-left {
        margin-left: 26px;

        &::before,
        &::after {
            left: -25px;
        }
    }

    &::before,
    &::after {
        width: 17px;
    }
}

.icon-add {
    @include icon-file(
        url("../gfx/icons/add.svg")
    );
}

/* ? */

.link-download {
    position: relative;
}

.btn-text.icon-toggle {
    line-height: 32px;
    &::before,
    &::after {
        top: 0px;
        left: 0;
        position: absolute;
    }
}
