.block-main-content-alert {
  border-left: 5px solid $color-red;

  @media #{$large-up} {
    border-left: 0;
    > div {
      padding-left: rem-calc(30px);
      padding-right: rem-calc(30px);
      border-left: 5px solid $color-red;
    }
  }
}
