.article {
  .block-main,
  .large-12 .block-main {
    margin-bottom: 40px;
  }
}

.article-byline-item {
  + .article-byline-item {
    margin-top: -15px;
  }

  span {
    display: block;
  }

  @media #{$medium-up} {
    span {
      display: inline;
    }
  }
}

.article-download {
  margin-top: rem-calc(-10);
}
.article-title + .article-download {
  margin-top: 30px;
}

.article-content {
  font-family: $fontSerifText;
  line-height: 2;
  font-size: rem-calc(18px);
  @media #{$medium-up} {
    font-size: rem-calc(19);
  }

  p {
    @include paragraph-article();
    margin-bottom: 30px;
  }

  .expand {
    display: block;
    font-family: $fontSerif;
    font-size: rem-calc(50);
    padding: 5px 0 0;
    margin-right: 5px;
    float: left;
    line-height: 1;
    text-align: center;
    @media #{$medium-up} {
      font-size: rem-calc(71);
    }
    @media #{$large-up} {
      margin-right: 15px;
    }
  }

  .imagetext {
    margin-bottom: rem-calc(30);
    padding: 30px;
    font-size: rem-calc(16);
    line-height: 1.6;
    background-color: $color-grey-light;
    p,
    > span {
      display: block;
      margin-bottom: rem-calc(30);
      color: #000;
      @media #{$small-only} {
        margin-bottom: 10px;
        &:first-child {
          margin-top: -20px;
        }
      }
    }
  }
  p + .imagetext {
    margin-top: -30px;
  }

  ul,
  ol {
    margin-bottom: 30px;
    display: block;
    margin-left: rem-calc(40);
    @media #{$large-up} {
      margin-left: rem-calc(60);
    }
    li {
      margin-bottom: 5px;
    }
  }
  ul {
    list-style: none;
    li {
      list-style: none;
      position: relative;
      background: url("../gfx/icons/bullet.svg") no-repeat left 15px;
      padding-left: 15px;
      margin-left: -15px;
    }
  }

  ul.no-bullet,
  ol.no-bullet {
    margin-left: 0;
    li {
      margin-bottom: 0;
      background: none;
      &:before {
        display: none;
      }
    }
  }

  .video-embed-wrapper {
    position: relative;
    margin-bottom: rem-calc(30);
    padding-bottom: 56.25%;
    height: 0;
    overflow: hidden;
    max-width: 100%;
    clear: both;

    iframe,
    object,
    embed {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    iframe {
      border: none;
    }
  }

  .box-grey-float {
    .block-content {
      margin: 0;
      clear: both;
      margin-bottom: rem-calc(30);
      font-size: rem-calc(16);
      background-color: $color-grey-light;
      transition: width 400ms ease-in-out;
      padding: 30px;

      p {
        font-size: inherit;
        font-family: $fontSerifText;
        &:first-child {
          margin-top: 0;
        }
      }

      @media #{$medium-up} {
        padding: 40px;
      }
      @media #{$large-up} {
        clear: left;
        float: left;
        width: 400px;
        margin-left: -175px;
        margin-right: 30px;
        margin-bottom: 20px;
        padding: 50px;
      }

      @media #{$xlarge-up} {
        width: 500px;
        margin-left: -220px;
      }
    }

    &.right {
      float: none !important;
      .block-content {
        @media #{$large-up} {
          clear: right;
          float: right !important;
          margin-left: 30px;
          margin-right: -175px;
        }
      }
    }
  }
}

.block-main-content.pattern {
  background-image: url("../gfx/icons/pattern.svg");
  background-size: 61px;
  background-position: center;
  blockquote {
    background-color: transparent;
  }
}

.blockquote-wrapper blockquote {
  background-image: url("../gfx/icons/pattern.svg");
  background-size: 61px;
  background-position: center;
}

blockquote {
  margin: 0;
  clear: both;
  margin-bottom: rem-calc(30);
  font-size: rem-calc(18);
  background-color: $color-grey-light;
  transition: width 400ms ease-in-out;

  span {
    font-family: $fontSerifText;
    font-size: rem-calc(16px);
  }

  p {
    font-size: rem-calc(20px);
    font-family: $fontSerifText;
    font-style: italic;

    &:first-child {
      margin-top: 0;
    }
  }

  footer {
    font-family: $fontSerifText;
    font-size: rem-calc(16px);
    font-style: italic;
  }
  footer + span {
    display: block;
    margin-top: 10px;
  }
}

.blockquote-wrapper {
  blockquote {
    padding: 30px;
    @media #{$medium-up} {
      padding: 40px;
    }
    @media #{$large-up} {
      clear: left;
      float: left;
      width: 400px;
      margin-left: -175px;
      margin-right: 30px;
      margin-bottom: 20px;
      padding: 50px;
    }

    @media #{$xlarge-up} {
      width: 500px;
      margin-left: -220px;
    }
  }

  &.right {
    float: none !important;
    blockquote {
      @media #{$large-up} {
        clear: right;
        float: right !important;
        margin-left: 30px;
        margin-right: -175px;
      }
    }
  }

  p {
    font-size: rem-calc(20px);
    font-family: $fontSerifText;
    font-style: italic;

    &:first-child {
      margin-top: 0;
    }
  }

  footer {
    font-size: rem-calc(16px);
  }
}

.question-answer {
  img {
    margin-top: 10px;
  }

  @media #{$small-only} {
    h3 {
      margin-top: 10px;
    }
  }
}

.article-content-block {
  @extend .article-content-image;
  margin-bottom: 25px;
  padding: 30px !important;
  &.block-color-lightgrey {
    background-color: $color-grey-light;
  }

  .article-content & {
    @media #{$large-down} {
      margin-bottom: 30px;
    }
  }

  .block-excerpt {
    font-family: $fontSans;
    font-size: rem-calc(16);
    line-height: 1.5;

    ul,
    ol {
      margin-left: rem-calc(16);
      margin-bottom: rem-calc(20);
      li {
        margin-bottom: 10px;
        padding: 0;
      }
    }
    ul {
      list-style: initial;
      margin-left: rem-calc(16px);
      list-style-position: outside;
      font-family: inherit;

      li {
        background: url("../gfx/icons/bullet.svg") no-repeat left 0.5rem;
        padding-left: 15px;
        margin-left: -15px;
      }
    }
    p {
      font-family: $fontSans;
      font-size: rem-calc(16);
      line-height: 1.7;
      margin-bottom: rem-calc(20);
    }
    *:last-child {
      margin-bottom: 0;
    }
  }
}

.block-content {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.article-qa-info {
  p {
    @include paragraph-article();
  }
}

.epos-summary {
  p {
    @include paragraph-article();
  }
}

.last-updated {
  color: $color-grey-dark;
}
