.page-seatlocation {
  li.custom-dropdown {
    @media #{$large-up} {
      max-width: 100%;
      width: 22.5%;
      padding-right: 0 !important;
      padding-left: 0 !important;
      margin-left: 2.66666% !important;

      &:first-child {
        margin-left: 0 !important;
      }

      select {
        min-width: 0;
      }
    }
  }
  .seat-location-representative-container {
    padding-bottom: 0;
    .custom-dropdown {
      display: block;
    }
    @media #{$large-up} {
      width: 24.5%;
      padding-left: 0 !important;
      margin-left: 2.66666% !important;
    }

    .form-area {
      display: none;
      @media #{$large-up} {
        display: block;
        position: relative;

        input[type="text"] {
          width: 100%;
          margin: 0;
          padding: 14px 16px 15px;
          border: none;
          background-color: #eee;
          border-bottom: 2px solid rgba(0, 0, 0, 0.15);
          background-color: #fff;
        }
        .clear-search {
          display: none;
          position: absolute;
          top: 0px;
          right: 0px;
          border: none !important;
          font-size: 1.5rem;
          color: #e2e1e1 !important;
          padding: 5px 20px !important;
        }
      }
    }
  }

  .seatlocation-sort-title {
    margin-bottom: 30px;
  }

  .custom-dropdown {
    margin-bottom: 0;
    width: 100%;
    @media #{$large-up} {
      padding-bottom: 0;
      margin-bottom: 0;
      width: inherit;
    }
  }

  .seat-location-representative {
    display: block;
    @media #{$large-up} {
      display: none;
    }
  }

  .block-find-representatives {
    .find-representatives-query {
      display: none;
    }

    .clear-search {
      display: none !important;
    }

    #show-historical + label {
      display: none;
    }

    .block-scrollable-wrapper {
      margin-top: 0;
      @media #{$large-up} {
        margin-top: 40px;

        .block-scrollable {
          height: 430px;
        }
      }
    }
  }
}

.block-seat-location {
  @media #{$large-up} {
    min-height: 550px;
    .block-inner {
      height: 540px;
    }
  }

  .svg-container {
    height: 0;
    padding-top: 73.79%;
    position: relative;

    #svg {
      height: 100%;
      display: block;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
    text {
      font-size: 12px;
      font-family: $body-font-family;
      font-weight: 500;
      width: 400px;

      &.hidden-number {
        display: none;
      }
    }
    [data-trigger="findperson"] text {
      font-size: 10px;
    }
  }

  g {
    position: relative;
    outline: none;
    -webkit-transition: fill 0.8s ease, -webkit-transform 0.3s;
    transition: fill 0.8s ease, transform 0.3s;
    -webkit-transform-origin: 50% 50%;
    -ms-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
    fill: #dbdada;
    .no-touch & {
      &:hover {
        fill: $color-red;

        text {
          fill: #fff;
        }
        rect {
          z-index: 1000;
        }
      }
    }
    &.active {
      -webkit-transition: fill 0.8s ease;
      transition: fill 0.8s ease;
      fill: $color-red;
      text {
        fill: #fff;
      }
    }
    &.checked {
      -webkit-transition: fill 0.8s ease;
      transition: fill 0.8s ease;
      fill: #b43633;

      text {
        fill: #fff;
      }
    }
    &:focus > rect {
      border: 1px solid #000;
    }

    &.government-seat-hover {
      fill: $color-red;
    }
  }
  .seat-location-mobile {
    .touch & {
      margin-bottom: 10px;
      display: block;
    }

    .no-touch & {
      display: block;
      @media #{$medium-up} {
        display: none;
      }
    }
  }
}

.seatlocation-reset-all {
  margin-top: 20px;
  margin-bottom: 20px;

  @media #{$large-up} {
    margin-bottom: 0;
    text-align: right;
  }
}

.is-government {
  float: right;
}
