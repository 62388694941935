.large-12 .block-main.block-live-main {
  .block-main-ajax-live {
    margin-top: 5px;
  }

  .block-main-insert-title + .block-main-title,
  .block-main-attendees,
  .block-main-url {
    margin-top: 0;
  }

  @media #{$large-up} {
    > .block-main-media {
      width: 50%;
    }

    .block-main-content {
      width: 50%;
      padding: 10px 40px 70px 50px;
      vertical-align: middle;
    }
    .block-main-list {
      position: absolute;
      left: 50px;
      right: 50px;
      bottom: 10px;
    }

    .block-main-ajax {
      min-height: 0;
      padding-bottom: 10px;
      > .block-main-title,
      > .block-main-title-small,
      > .block-main-insert-title {
        display: block;
      }
    }
  }
}

.live-video-container {
  position: relative;
  display: block;
  a {
    padding: 0;
    border: none;
    display: block;

    img {
      display: block;
    }
  }
}

.live-video-meta {
  &.time,
  &.live,
  &.rec {
    display: none;
    &.active {
      display: block;
    }
  }
  &.time {
    color: $body-font-color;
    background-color: $color-grey-darker;
    &:before {
      content: "";
      width: 18px;
      height: 18px;
      display: block;
      margin-right: 5px;
      float: left;
      background: url("../gfx/icons/clock.svg") center center no-repeat;
    }
  }
  &.live {
    background-color: $color-red;
    color: #fff;
  }
  &.rec {
    background-color: $color-grey-darker;
    color: #000;
  }
  position: absolute;
  right: 10px;
  top: 10px;
  text-transform: uppercase;
  padding: 8px 12px 4px;
  font-weight: bold;
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 0.3px;

  .block-small-media & {
    right: 0px;
    top: 0px;
  }
}

ul.block-live-profiles {
  margin: 0;
  padding: 0;
  list-style: none;

  margin-top: 5px;
  @extend .clearfix;
  > li {
    float: left;
    margin-right: 20px;
    margin-bottom: 10px;
    margin-top: 0;
    display: table;
    @extend .clearfix;

    .block-profile-image {
      padding-right: 10px;
      display: table-cell;
    }

    .block-image-wrapper {
      position: relative;
      width: 40px;
      height: 40px;
      display: block;
      border-radius: 50%;
      overflow: hidden;
      border: 3px solid #fff;
    }
    .block-profile-content {
      margin-top: 0;
      display: table-cell;
      vertical-align: middle;
    }
  }
}

a.video-play-button {
  position: relative;
  &:after {
    content: "";
    width: 70px;
    height: 70px;
    background: url("../gfx/icons/play.svg") 0 0 no-repeat;
    background-position: center;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    background-size: 100%;
  }

  &:hover,
  &:focus {
    &:after {
      background-image: url("../gfx/icons/play-hover.svg");
    }
  }
}
