.content-container .tour-list {
    margin: 0;
    padding: 0;
    clear: both;

    ul {
        background-color: $color-grey-lightest;
        margin: 0;
        padding: 0;
        list-style-type: none;
        border-top: 1px solid $color-grey;
        padding: 20px 15px 20px 100px;
        display: none;

        > li {
            overflow: hidden;
            margin: 0 0 15px 0;

            @media #{$medium-up} {
                margin-bottom: 10px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    > li {
        border-top: 1px solid #d7d7d7;
        @include clearfix();

        a {
            @include clearfix();
            display: block;
            border: none;

            &:link,
            &:visited {
                .tour-time {
                    color: $body-font-color;
                }
            }

            &:hover,
            &:focus {
                .tour-time {
                    color: $color-red;
                    cursor: pointer;
                }
            }
        }
    }

    li.tour-closed {
        padding: 13px 5px;
        line-height: 32px;
        padding-left: 42px;

        span:not(.tour-booking) {
            display: block;
            font-size: rem-calc(14);
            line-height: 48px;

            @media screen and (min-width: 380px) {
                display: inline-block;
            }

            @media #{$medium-up} {
                font-size: rem-calc(16);
                padding-top: 5px;
            }
        }
    }

    .tour-link {
        padding: 13px 5px;
        line-height: 32px;
        padding-left: 47px;
    }

    &.non-expandable > li.tour-closed,
    &.non-expandable > li.tour-link,
    &.non-expandable > li {
        padding-left: 0;

        a {
            padding-left: 0;
            margin: 0;
        }
    }

    .tour-toggle {
        display: block;
        font-size: rem-calc(18);
        color: #333;
        border: none;
        padding: 13px 5px;
        line-height: 32px;
        @include clearfix();

        &:before,
        &:after {
            top: 21px;
        }

        &:hover {
            border: none;

            .tour-time {
                border: none;
                padding: 0;
            }
        }

        &.icon-minus {
            background-color: #fff;
        }
    }

    .tour-time {
        display: block;
        font-size: rem-calc(14);
        padding: 0;
        border: none;
        line-height: 48px;

        @media screen and (min-width: 380px) {
            display: inline-block;
        }

        @media #{$small-only} {
            margin-right: 20px;
        }

        @media #{$medium-up} {
            font-size: rem-calc(16);
        }
    }

    .tour-booking {
        position: relative;
        background-color: $color-green-new;
        border: 1px solid $color-green-new;
        color: #fff;
        font-size: rem-calc(14);
        font-weight: 700;
        padding: 7px 13px;

        @media screen and (min-width: 380px) {
            float: right;
        }

        @media #{$small-only} {
            height: 42px;
        }

        @media #{$medium-up} {
            float: right;
            padding: 7px 21px;
            font-size: rem-calc(16);
            margin-left: 20px;
            min-width: 100px;
            text-align: center;
            display: inline-block;
        }

        &.full {
            color: $color-grey-dark;
            background-color: #fff;
            border-color: $color-grey;
        }

        &.closed {
            color: #fff;
            background-color: $color-grey-dark;
            border-color: $color-grey-dark;
        }

        &:focus {
            border: 2px solid black;
        }
    }
}
