.twtr-header {
  @include section-title();
  margin-bottom: 0;
  padding: 20px;
  color: $color-black;
  background-color: $color-grey-light;
}

.twtr-wrapper {
  padding: 20px 20px 40px;

  .twtr-icon {
    position: relative;
    display: block;
    width: 70px;
    height: 70px;
    background-color: #eee;
    border-radius: 35px;

    @media #{$large-down} {
      margin-bottom: 20px;
      margin-left: 40px;
    }

    @media #{$large-up} {
      margin-top: 10px;
      margin-left: 24%;
    }

    &:after {
      content: "";
      position: absolute;
      top: 50%;
      left: 50%;
      width: 32px;
      height: 32px;
      margin-top: -16px;
      margin-left: -16px;
      background: url("../gfx/icons/icons.svg") no-repeat 0 -1408px;
    }
  }
}

.twtr-section {
  margin-top: 20px;

  @media #{$large-up} {
    margin-top: 30px;
  }

  &:first-child {
    margin-top: 0;
  }
}

.twtr-subheader {
  margin-bottom: 20px;

  .twtr-subheader-text {
    @include block-title();
    margin-right: 10px;
  }

  .twtr-subheader-link {
    font-size: rem-calc(16);
    border-bottom: none !important;

    @media #{$large-down} {
      display: block;
    }
  }

  @media #{$medium-up} {
    font-size: rem-calc(28);
  }
}

.twtr-tweet-wrap {
  margin-bottom: 40px;

  @media #{$large-down} {
    &:nth-child(2n + 1) {
      clear: left;
    }
  }

  @media #{$large-up} {
    &:nth-child(3n + 1) {
      clear: left;
    }
  }
}

.twtr-tweet-text {
  p {
    margin-bottom: 0;
  }
}
.twtr-timestamp {
  font-style: normal;
}
