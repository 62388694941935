.block-article {
  &.block {
    overflow: visible;
  }

  &-mobile {
    margin-bottom: 40px;
  }

  @media #{$large-up} {
    text-align: left;
    margin-bottom: 50px;
  }

  .block-image-wrapper {
    margin: 0 0 20px 0;

    img {
      width: 100%;
      height: auto;
    }

    .video-embed-wrapper {
      position: relative;
      width: 100%;
      height: 0;
      padding-bottom: 60%;
    }

    .video-embed-wrapper iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
    }
  }

  .block-title {
    margin: 0 0 20px 0;
  }

  > a:first-child {
    + .block-content {
      margin-top: 15px;

      + .block-list {
        margin-top: 15px;
      }
    }
  }

  .block-excerpt {
    margin: 0;
    color: #333;
    font-family: $fontSans;
    line-height: rem-calc(24);

    p {
      margin: 0 0 15px 0;

      &:last-child {
        margin: 0;
      }
    }
  }

  .block-article-links {
    margin: 0;

    li {
      margin: 0 0 5px 0;
    }
  }
}

.page-section .block-article {
  margin-bottom: 40px;
  @media #{$large-up} {
    margin-bottom: 50px;
  }
}

@media #{$large-up} {
  .large-12 {
    .block-article {
      overflow: hidden;
      background-color: #eee;

      .video-embed-wrapper,
      .block-image-wrapper,
      .block-content {
        float: left;
        width: 50%;
        margin: 0;
      }

      .block-image-wrapper {
        position: relative;
      }

      .video-embed-wrapper {
        position: relative;
      }

      .block-content {
        padding: 30px;
      }

      .block-list {
        float: left;
        margin: 0 0 0 30px;
      }
    }
  }
}
