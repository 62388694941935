[data-bigdoc-nav-wrapper] {
  &.scroll-to-fixed-fixed + div {
    margin-bottom: 40px;
  }

  @media #{$large-down} {
    position: relative;

    .touch & {
      z-index: 999;
    }

    &:not(.scroll-to-fixed-fixed) + div {
      display: none !important;
    }
  }
}

@media only screen and (max-height: 25em) {
  .bigdoc-page .header {
    z-index: 2;
  }

  body:not(.searchMatches) {
    [data-bigdoc-nav-wrapper] {
      position: relative !important;
      z-index: 1 !important;
      top: auto !important;
      width: 100% !important;

      &.scroll-to-fixed-fixed {
        + div {
          display: none !important;
        }
      }
    }
  }

  body.searchMatches {
    [data-bigdoc-nav-wrapper] {
      overflow: auto;
    }
  }
}

.bigdoc-navigation {
  margin-bottom: 40px;
  padding: 20px 0;
  background-color: $color-grey-light;

  p:last-child {
    margin-bottom: 0;
  }

  a {
    border-color: $color-grey-darker;
  }

  &.bigdoc-navigation-upper,
  &.bigdoc-navigation-lower {
    @include clearfix();
    margin-right: rem-calc(-20px);
    margin-left: rem-calc(-20px);
    background-color: $color-grey;
    border: none;
    border-left: 5px solid $color-purple;

    @media #{$large-up} {
      margin-right: 0;
      margin-left: 0;
    }
  }

  &.bigdoc-navigation-lower {
    padding: 0 20px 20px;

    @media #{$large-up} {
      padding: 0 40px 20px;
    }

    > .row {
      padding-top: 20px;
      border-top: 1px solid $color-grey-darker;
    }
  }

  &.bigdoc-navigation-upper {
    padding: 0 20px 0;

    @media #{$large-up} {
      padding: 20px 40px 20px;
    }

    + .bigdoc-navigation-lower {
      margin-top: -40px;
    }
  }

  .scroll-to-fixed-fixed & {
    margin-bottom: 0;

    &.bigdoc-navigation-upper {
      + .bigdoc-navigation-lower {
        margin-top: 0;
      }
    }
  }

  @media #{$medium-only} {
    padding: 30px rem-calc(40);
  }

  @media #{$small-only} {
    padding: 20px rem-calc(20);
  }

  @media #{$large-up} {
    padding: 40px 0;

    &.bigdoc-navigation-lower {
      .columns.end {
        padding-left: rem-calc(20);
      }
    }
  }

  @media #{$large-down} {
    &.bigdoc-navigation-upper {
      position: relative;
    }

    &.bigdoc-navigation-lower {
      position: relative;
      z-index: 999;
    }
  }

  &.space-below {
    margin-bottom: 20px;
  }

  .bigdoc-navigation-inner {
    padding-top: 1.2em;
    padding-bottom: 0.5em;

    @media #{$large-down} {
      max-width: rem-calc(660);
      margin-right: auto;
      margin-left: auto;
      padding-right: rem-calc(15);
      padding-left: rem-calc(15);
    }

    .bigdoc-navigation-selector {
      display: inline-block;
      margin-bottom: 1em;
    }
  }

  label {
    display: block;
    margin-top: 0.5em;
    margin-bottom: 5px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;

    @media #{$large-down} {
      margin-bottom: 5px;
      font-size: rem-calc(14);
    }

    &.bigdoc-search-label {
      visibility: hidden;

      @media #{$small-only} {
        margin-top: 0;
      }
    }
  }
  // fix for empty labels in navigation
  @media #{$large-up} {
    label.bigdoc-search-label,
    label.bigdoc-selector-label {
      &.empty {
        @include visually-hidden();
      }
    }
  }

  label.bigdoc-search-label.empty {
    @media #{$medium-up} {
      margin-top: 7px;
      height: 0;
    }
  }

  .bigdoc-navigation-actions.empty {
    padding-top: 0;
  }
  // fix end

  .bigdoc-info {
    margin-bottom: 10px;
    font-weight: 500;
  }

  .bigdoc-info-toggler-small {
    position: relative;
    font-size: 18px;
    text-align: left;
    color: #000;
    background-color: transparent;
    overflow: visible;

    @include icon();
    @include icon-right();
    @include icon-arrow-down();
    margin-left: 12px;
    padding: 10px !important;
    padding-right: 40px !important;

    &:before,
    &:after {
      right: 20px;
      top: 20px;
      background-image: url("../gfx/icons/arrow-down.svg");
    }

    &.active {
      &:before,
      &:after {
        background-image: url("../gfx/icons/arrow-up.svg");
      }
    }

    &:focus {
      outline: 1px dotted #cf0a2c;
    }

    @media #{$large-up} {
      @include block-title();
      margin: 0;
      padding: 10px 0 !important;

      &:before,
      &:after {
        right: -20px;
      }
    }
  }

  .bigdoc-info-toggle-small {
    display: none;

    &.open {
      display: block;
    }
  }

  @media #{$large-down} {
    .bigdoc-info-toggler-small-wrapper {
      z-index: 1000; //2
      margin: 0 rem-calc(-20);
      padding-top: 13px;
      padding-bottom: 14px;
    }

    .bigdoc-info-toggler-icon {
      position: absolute;
      top: 15px;
      left: 15px;
    }

    .bigdoc-info-toggle-small .no-bullet {
      margin-bottom: 30px;
    }
  }

  @media #{$large-up} {
    .bigdoc-info-toggler-small-wrapper .bigdoc-info-toggler-icon {
      display: none;
    }
  }

  .bigdoc-info-toggler {
    text-align: left;
    font-size: 18px;
    color: $color-black;
    background-color: transparent;
    cursor: default;
    padding: 0 !important;
    margin-bottom: 10px;

    @media #{$large-down} {
      display: none;
    }

    &.scroll-to-fixed-fixed {
      cursor: pointer;

      &:hover,
      &:focus {
        .bigdoc-info-toggler-icon:before {
          opacity: 1;
        }

        .bigdoc-info-toggler-icon:after {
          opacity: 0;
        }
      }
    }

    .bigdoc-info-toggler-text {
      @extend .block-title;
    }
  }

  .custom-dropdown {
    margin-bottom: 0;
    vertical-align: middle;
  }
  .bigdoc-to-top {
    font-size: rem-calc(16);
    position: absolute;
    top: 30px;
    right: 20px;
    padding: 0 0 2px !important;
    transition: opacity 0.3s linear 0s;
    display: none;

    .scroll-to-fixed-fixed & {
      display: block;
      &:focus {
        outline: 1px solid black;
      }
    }

    @media #{$large-up} {
      top: 20px;
      right: 40px;
    }
  }

  .bigdoc-search-wrapper {
    position: relative;

    @media #{$small-only} {
      margin-top: -10px;
    }

    @media #{$large-down} {
      margin-bottom: 20px;
    }
  }
  .bigdoc-search-submit {
    position: absolute;
    top: 8px;
    right: 10px;
    width: 32px;
    height: 32px;
    margin-bottom: 0;
    padding: 0;
    background-color: transparent;
    border: none;

    &:before,
    &:after {
      right: 0;
    }

    &:focus {
      border: 1px solid black;
    }

    &:active {
      outline: none;
    }
  }

  @media #{$large-up} {
    .bigdoc-navigation-formula > .columns {
      border-right: 1px solid $color-grey-darker;
    }
  }

  .bigdoc-navigation-actions {
    margin-bottom: 20px;

    @media #{$large-up} {
      padding-top: 34px;
      margin-bottom: 0px;
    }
    @extend .no-bullet;
  }
  .js & {
    .button-bigdoc-table-of-content {
      padding: 16px 32px 13px 16px !important;
      font-style: italic;
      border: 1px solid $color-grey;

      &:after {
        font-style: normal;
      }

      &:hover {
        border-color: $color-grey-darker;
      }

      &:focus {
        border-color: $color-grey-dark;
      }
    }
  }

  .searchMatches & {
    .js & {
      .button-bigdoc-table-of-content {
        padding-right: 40px !important;
      }
    }
  }

  .no-js & {
    .button-bigdoc-table-of-content {
      cursor: default;

      &:after {
        content: "";
      }
    }
  }

  .longdoc-match-found,
  .longdoc-match-pointer {
    position: absolute;
    top: -3px;
    right: 0;
    display: inline-block;
    background-color: #fdf6de;
  }

  .longdoc-match-found + .longdoc-match-pointer {
    right: -26px;
  }

  .longdoc-match-found {
    padding: 2px 7px;
  }

  .longdoc-match-pointer {
    margin-left: 0 !important;
    padding-right: 0 !important;

    &:after {
      display: inline-block;
      padding: 6px 6px 5px;
      font-family: "stortinget-symbols";
      font-size: 12px;
      font-style: normal;
      content: "\e600";
      color: $primary-color;
    }
  }
}

.bigdoc-navigation-small {
  padding: 50px 60px 50px;
  @media #{$large-down} {
    display: none;
  }
}

.bigdoc-matches,
.bigdoc-search-paging,
.bigdoc-determinate-search {
  display: inline-block;
}
.bigdoc-matches {
  margin-bottom: 0;

  @media #{$small-only} {
    position: absolute;
    left: -999em;
  }
}
@media #{$large-down} {
  .bigdoc-determinate-search {
    float: right;
    margin-right: rem-calc(20);
  }
}
.bigdoc-matches {
  margin-right: 12px;
}
@media #{$large-up} {
  .bigdoc-determinate-search {
    margin-right: rem-calc(20);
  }
}

.bigdoc-search-prev,
.bigdoc-search-next {
  &:disabled {
    color: #999;
  }
}
.bigdoc-search-prev {
  margin-right: 10px;
}
.bigdoc-search-next {
  margin-left: 10px;
}

.bigdoc-highlight {
  margin-right: -2px;
  margin-left: -2px;
  padding-right: 2px;
  padding-left: 2px;
  background-color: $color-highlight-yellow;
  font-weight: 600;

  &.current {
    border-bottom-color: #000;
  }

  &:focus {
    outline: 1px solid black;
  }
}

.bigdoc-index,
.bigdoc-title-wrapper {
  margin-top: 2em;
}
.bigdoc-index {
  ul > li {
    margin-bottom: 5px;
  }
}

.bigdoc-navigation-lower + .bigdoc-title-wrapper,
.bigdoc-navigation-lower + div + .bigdoc-title-wrapper {
  margin-top: 0;
}

.touch .bigdoc-index ul li {
  margin-bottom: 10px;
}
