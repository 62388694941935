@import "normalize";
@import "html5boilerplate";

@import "fonts";

@import "foundation/settings";
@import "foundation/visibility";
@import "foundation/grid";
@import "foundation/block-grid";
@import "foundation/type";

@import "colors";

@import "masonry";

@import "overrides";

@import "mixins";

@import "typography";
@import "icons";

@import "datepicker";
@import "alarmbanner";
@import "infobanner";

@import "buttons";
@import "tables";

@import "article";

@import "bigdocs_2015";
@import "bigdocs";

@import "video";

@import "hearings";
@import "custom-filter";

@import "gallery";
@import "calendar";

@import "blocks";

@import "pages";
@import "forms";
@import "content-target-group";
@import "custom-dropdown";
@import "paging";
@import "tabs";
@import "panel";
@import "lists";
@import "biography";
@import "nett-tv";
@import "tour-list";
@import "seat-location";
@import "tour";
@import "education";
@import "documents";
@import "dictionary";
@import "sticky-elements";

@import "table-wrapper";
@import "view-modal";

@import "layout-helpers";
@import "aboutQuestionTimePage";

@import "recaptcha-v3";

@import "proceedingPage";

@import "issue-notifications";

@import "hoering-uttalelse";

@import "proceeding-voting-details";

@import "main-contact-form-library";
// Components
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ActionBar/ActionBar.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Alert/Alert.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ApenDataFooter/ApenDataFooter.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ApenDataHeader/ApenDataHeader.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ArticleContentImage/ArticleContentImage.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ArticleIntro/ArticleIntro.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ArticleLink/ArticleLink.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BigDocNav/BigDocNav.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BigDocNavHeader/BigDocNavHeader.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BigDocNavOld/BigDocNavOld.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BigDocTableOfContent/BigDocTableOfContent.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BigDocTableOfContentSearch/BigDocTableOfContentSearch.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BiographyFrame/BiographyFrame.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockArticle/BlockArticle.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockArticleBox/BlockArticleBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockArticleInlineBox/BlockArticleInlineBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockArticleListBox/BlockArticleListBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockContactBox/BlockContactBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockElectionBox/BlockElectionBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockEvents/BlockEvents.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockExtraFacts/BlockExtraFacts.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockFindRepresentatives/BlockFindRepresentatives.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockFindRepresentativesFrontpage/BlockFindRepresentativesFrontpage.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockIconList/BlockIconList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockList/BlockList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockLiveMain/BlockLiveMain.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockLiveSmall/BlockLiveSmall.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMain/BlockMain.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainAlert/BlockMainAlert.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainEnglish/BlockMainEnglish.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainImage/BlockMainImage.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainOneLink/BlockMainOneLink.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainQuote/BlockMainQuote.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockMainVideo/BlockMainVideo.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockNews/BlockNews.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockNewsletter/BlockNewsletter.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockOrganizationalChart/BlockOrganizationalChart.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/BlockRss/BlockRss.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/CaseList/CaseList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/CaseOverview/CaseOverview.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/CaseSteps/CaseSteps.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Committee/Committee.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/CountyDistribution/CountyDistribution.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/DataListing/DataListing.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/DecisionsListFilters/DecisionsListFilters.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/DownloadDropdown/DownloadDropdown.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Feedback/Feedback.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Footer/Footer.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/FormElements/Input/Input.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/FormElements/Label/Label.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/FormElements/Select/Select.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/GlobalSearch/GlobalSearch.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/HallPlacement/HallPlacement.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Header/Header.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/LanguageSelector/LanguageSelector.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/LinkBox/LinkBox.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/LinkList/LinkList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/NavPrimary/NavPrimary.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/NavSecondary/NavSecondary.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/NetigateSurvey/NetigateSurvey.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/PageSummary/PageSummary.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/PageTitle/PageTitle.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/PartyDistribution/PartyDistribution.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/PortraitsCarousel/PortraitsCarousel.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Profile/Profile.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/ProfileImage/ProfileImage.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/PublicationInfo/PublicationInfo.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/SeatLocationLink/SeatLocationLink.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/SocialSharing/SocialSharing.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/SocialSharingList/SocialSharingList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/SpeechListWrapper/SpeechListWrapper.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/TourList/TourList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/TravelListFilters/TravelListFilters.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/TwitterContainer/TwitterContainer.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Votering/BlockVote/BlockVote.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Votering/BlockVoteHelp/BlockVoteHelp.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Votering/BlockVoteList/BlockVoteList.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/Votering/BlockVoteStatus/BlockVoteStatus.scss";
@import "C:/source/Stortinget/Stortinget.Web/Stortinget.Presentation/Frontend/main/src/components/WhatsUpDays/WhatsUpDays.scss";

.tour-forms-search {
  position: relative;
  &:after {
    content: "";
    position: absolute;
    right: 0px;
    top: 13px;
    width: 32px;
    height: 32px;
    background-image: url(../gfx/icons/search.svg);
    background-repeat: no-repeat;
  }
}

.flex-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-item-50 {
  width: 100%;
  @media #{$large-up} {
    width: 50%;
  }
}

.flex-item-25 {
  width: 100%;
  @media #{$large-up} {
    width: 25%;
  }
}

main:focus {
  outline: none;
}

.icon-link-list {
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  margin-left: 0;
  padding-left: 0;
  list-style: none;

  @media #{$large-down} {
    margin-top: 20px;
    margin-bottom: 10px;
  }

  li {
    margin-top: 5px;

    &:first-child {
      margin-top: 0;
    }

    > a {
      margin-bottom: 0;
      display: inline-block;
      border-bottom: none;
      > span {
        border-bottom: 1px solid $color-grey-darker;
      }

      > .icon-text {
        margin-top: 0;
      }
    }
  }
}

// Align

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}
.align-right {
  text-align: right;
}

.medium-align-center {
  text-align: left;
  @media #{$medium-up} {
    text-align: center;
  }
}

.large-align-center {
  text-align: left;
  @media #{$large-up} {
    text-align: center;
  }
}

.no-margin {
  margin: 0;
}

// Lists

.block-link {
    border: 1px solid $color-grey;
    border-color: rgba(200, 200, 200, 0.8);
    color: #B20019;
    padding: 5px 15px !important;
    margin-top: 20px;
    display: inline-block;

    &:hover {
        background-color: $color-grey-light;
    }
}

ul.block-list {
  > li {
    margin-bottom: 7px;
  }
}

.link-list-center {
  text-align: left;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;

  @media #{$medium-up} {
    text-align: center;
    margin-top: 20px;
  }
  > li {
    list-style: none;
    @media #{$medium-up} {
      display: inline-block;
    }
    margin-bottom: 10px;
    margin-right: 10px;
    &:last-child {
      margin-right: 0;
    }
  }
  .block-link {
    margin-top: 0;
  }
}

/**
* Components
**/

.box-grey {
  background-color: $color-grey-light;
  padding: 20px;
  margin-bottom: 00px;
  @media #{$medium-up} {
    padding: 40px;
  }
  > .section-title {
    margin-top: 0;
  }
}

.chases > ul {
  margin-left: 0;
  margin-bottom: 10px;
  padding-left: 0;
  list-style: none;

  > li {
    margin-bottom: 5px;
  }
}

.button-group-title {
  @include block-title();
}

.section-grid-3 {
  margin-top: 20px;
  .linkbox {
    margin-bottom: 20px;
    @media #{$medium-up} {
      margin-bottom: 50px;
    }
    @media #{$large-up} {
      margin-bottom: 0;
    }
  }
}

.vspacer8 {
  margin-bottom: 10px;
}

.sitemap {
  ul > li > a {
    color: $color-black;
    &:focus,
    &:hover {
      color: $color-red;
    }
  }
  > ul > li {
    @include h2();
    &:before {
      top: -3px;
    }

    ul li a {
      @include paragraph-article();
    }
  }
  li {
    margin-top: 5px;
  }
  > ul > li > ul > li > ul {
    margin-bottom: 20px;
  }
}

#stRecaptcha {
  margin-bottom: 20px;
  .masonry-item & {
    @media screen and (min-width: 642px) and (max-width: 793px) {
      transform: scale(0.77);
      transform-origin: 0 0;
    }
    @media screen and (min-width: 1024px) and (max-width: 1292px) {
      transform: scale(0.77);
      transform-origin: 0 0;
    }
  }
}

//generic classes for spacing
.air-top {
  margin-top: 30px !important;
}

.air-bottom {
  margin-bottom: 30px !important;
}

#app {
  position: relative;
}

.confirmation {
  background-image: url(../gfx/icons/confirmation.svg);
  background-position: left center;
  background-repeat: no-repeat;
  min-height: 45px;
  padding-left: 50px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 20px;
}

hr {
  border-top: 1px solid $color-grey-light;

  &.darker {
    border-top-color: $color-grey-darker;
  }
}

.number-prefix {
  margin-bottom: 10px;
  .number,
  .number-placeholder {
    width: 40px;
    min-width: 40px;
  }
  .number {
    @include number();
    @include number-small();
    background-color: $color-purple;
    margin-bottom: 10px;
  }
  @media #{$medium-up} {
    display: flex;
    .number,
    .number-placeholder {
      margin-right: 20px;
      margin-bottom: 0;
    }

    > .number-content {
      flex: 2;
    }
  }
}

// To completely hide tags in dom

.hidden-tag {
  display: none;
  visibility: hidden;
}
