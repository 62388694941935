.no-csstransforms3d {
    .nav-primary {
        @media #{$large-down} {
            top: -1000px;

            &.active {
                top: 60px;
            }
        }
    }
}

.nav-primary {
    @media #{$large-down} {
        position: fixed;
        top: 80px;
        bottom: 0;
        padding-bottom: 80px;
        width: 100%;
        padding-bottom: 60px;
        background-color: #fff;
        opacity: 0;
        visibility: hidden;
        transform: translate3d(-100%, 0, 0);
        transition: opacity 400ms ease-in-out;

        &.active {
            visibility: visible;
            left: 0;
            transition: opacity 400ms ease-in-out;
            transform: translate3d(0, 0, 0);
            opacity: 1;
            overflow: scroll;
            -webkit-overflow-scrolling: touch;
            border-top: 1px solid $color-grey;
        }

        .close-menu {
            display: none;
        }

        .page-actions {
            display: flex;
            float: none;
            justify-content: center;
            padding: 1.5em 1em 1em;

            #startpage & {
                display: none;
            }
        }

        .social-sharing-list.level-2 {
            a {
                margin-top: 2px;
                margin-bottom: 2px;
                margin-left: 25px;
                padding-left: 6px;
            }
        }
    }

    @media #{$large-up} {
        float: left;
        display: inline;
        margin-right: 50px;

        .level-1.language-selector-wrapper {
            display: none;
        }

        .close-wrapper {
            @media #{$large-up} {
                @include clearfix();
                border-top: 1px solid $color-grey;
                position: relative;
                clear: both;
                bottom: -60px;
                margin: 0 20px;
                padding-top: 35px;
                margin-bottom: 30px;

                .close-button {
                    margin-top: 0;
                }
            }
        }

        .close-menu {
            float: right;
        }

        .page-actions {
            display: none;
        }
    }
    // Navigation fix
    @media (max-width: 1300px) {
        margin-right: 40px;
    }

    @media (max-width: 1200px) {
        margin-right: 10px;
    }

    @media (max-width: 1100px) {
        margin-right: 5px;
    }

    @media #{$xlarge-up} {
        margin-right: 70px;
    }

    ul {
        margin: 0;
        padding: 0;

        @media #{$large-up} {
            &.level-2 {
                margin-top: 30px;
            }

            &.level-3 {
                margin-top: 5px;
            }
        }
    }

    a,
    .sub-title {
        display: block;
        color: $color-black;
        border-bottom: 1px solid $color-grey;

        @media #{$large-down} {
            font-weight: 400;
        }

        @media #{$large-up} {
            border-bottom: none;
            font-weight: 500;
        }
    }

    a {
        &:hover,
        &:active {
            color: $color-red;
        }

        &.active {
            color: $color-red;
        }
    }

    .level-1 {
        @media #{$large-up} {
            display: inline-block;
        }

        > li {
            @media #{$large-down} {
                position: relative;

                &.active {
                    border-bottom: 1px solid $color-grey;

                    > a {
                        color: $color-red;
                    }
                }

                a:focus {
                    border: 1px solid black;
                    outline: none;
                }

                &:active {
                    border: none;
                    outline: none;
                }
            }

            @media #{$large-up} {
                display: inline-block;

                > a {
                    position: relative;
                    @include meta-title();
                    color: $color-black;

                    &:hover,
                    &.active {
                        color: $color-red;
                        outline: none;
                        border: none;
                        &:after {
                            background-image: url(../gfx/icons/arrow-red-down.svg);
                        }
                    }

                    a:focus {
                        border: 1px solid black;
                        outline: none;
                    }

                    &.active:after {
                        background-image: url(../gfx/icons/arrow-red-up.svg);
                    }

                    &::after {
                        position: absolute;
                        top: 3px;
                        right: 10px;
                        padding: 0;
                        content: "";
                        background-color: transparent;
                        pointer-events: none;
                        background-image: url(../gfx/icons/arrow-down.svg);
                        background-repeat: no-repeat;
                        background-size: 10px;
                        background-position: center center;
                        content: "";
                        width: 10px;
                        height: 10px;

                        @media (max-width: 1150px) {
                            top: 3px;
                        }
                    }
                    // Fix for menu
                    @media (max-width: 1150px) {
                        font-size: 14px;
                        margin-right: 0px;
                        padding-left: 8px;

                        &:after {
                            top: 2px;
                        }
                    }
                }
            }

            &.language-selector-mobile {
                @media #{$large-up} {
                    display: none;
                }
            }

            > a {
                @media #{$large-down} {
                    padding: 15px rem-calc(25) 15px rem-calc(15);
                    @include meta-title();
                    color: $color-grey-dark;
                    font-size: 15px;
                    position: relative;

                    &:after {
                        position: absolute;
                        content: "";
                        width: 10px;
                        height: 10px;
                        background-image: url(../gfx/icons/arrow-grey-down.svg);
                        background-size: 10px;
                        background-position: center center;
                        background-repeat: no-repeat;
                        right: 20px;
                        top: 50%;
                        transform: translateY(-50%);
                    }

                    &:focus {
                        border: 1px solid black;
                        outline: none;
                    }

                    &:active {
                        border: none;
                        outline: none;
                    }

                    &.active:after {
                        background-image: url(../gfx/icons/arrow-red-up.svg);
                    }
                }

                @media #{$large-up} {
                    padding: 0px 25px 0 15px;
                    margin-right: 5px;
                    // Fix for menu
                    @media (max-width: 1150px) {
                        font-size: 14px;
                        margin-right: 0px;
                        padding-left: 8px;
                    }
                }

                @media #{$xlarge-up} {
                    margin-right: 10px;
                }
            }

            @media #{$large-up} {
                &:last-child > a {
                    border: none;
                }
            }
        }
    }

    .level-2 {
        @media #{$large-up} {
            font-size: rem-calc(15);
            font-family: $fontSans;
            padding: 0;
        }

        > li > a {
            position: relative;

            @media #{$large-up} {
                display: inline-block;
                @include icon();
                @include icon-right();
                @include icon-small-right();
                @include icon-arrow-right();

                &:before,
                &:after {
                    right: 6px;
                    top: 6px;
                    margin-top: 0;
                }
            }

            @media #{$large-down} {
                padding-left: 0;
                margin-left: 45px;

                &:focus {
                    border: 1px solid black;
                    outline: none;
                }

                &:active {
                    border: none;
                    outline: none;
                }
            }
        }

        &.language-selector > li > a.current {
            position: relative;

            &.current:before {
                display: inline-block;
                position: absolute;
                left: 20px;
                font-family: "stortinget-symbols";
                content: "\e607";
            }
        }
    }

    .level-2 > li > .subtitle {
        font-weight: 500;
    }

    .level-3 {
        @media #{$large-up} {
            font-size: rem-calc(14);

            li {
                margin-top: 9px;
                margin-bottom: 9px;
                line-height: 1.2;
            }
        }

        a {
            font-weight: 500;

            @media #{$large-down} {
                padding: 15px rem-calc(25) 15px 80px;
                border-bottom: none;
                font-weight: 400;
                font-size: 15px;
            }

            &:focus {
                border: 1px solid black;
                outline: none;
            }

            &:active {
                border: none;
                outline: none;
            }
        }

        li:last-child a {
            @media #{$large-down} {
                padding: 15px rem-calc(25) 15px 35px;
                border-bottom: 1px solid $color-grey;
                margin-left: 45px;
            }
        }
    }
}

.level-2,
.level-3 {
    display: none;

    @media #{$large-up} {
        li.active & {
            display: block;
        }
    }

    @media #{$large-down} {
        &.open {
            display: block;
        }
    }

    > li > a {
        @media #{$large-down} {
            padding: 15px rem-calc(25) 15px rem-calc(45);
        }
    }
}

.level-2 > li > a {
    font-size: 15px;
    @media #{$large-up} {
        display: inline;
        color: $color-black;
    }
}

.level-3 > li > a {
    @media #{$large-up} {
        border: none;
        background-color: inherit;
        color: $color-red;
        padding: 0px;
    }
}

.section-link {
    @media #{$large-down} {
        display: none;

        &.open {
            display: block;
        }
    }

    a {
        color: $color-black;
        @media #{$large-up} {
            font-family: $fontSerif;
        }

        @media #{$large-down} {
            position: relative;
            padding: 15px rem-calc(25) 15px 0;
            margin-left: 45px;
            font-size: 15px;
        }
    }
}

.sub-title {
    @media #{$large-down} {
        position: relative;
        display: block;
        padding: 15px rem-calc(25) 15px 0;
        margin-left: 45px;
        cursor: pointer;
        font-size: 15px;

        &:after {
            position: absolute;
            content: "";
            width: 10px;
            height: 10px;
            background-image: url(../gfx/icons/arrow-grey-down.svg);
            background-size: 10px;
            background-position: center center;
            background-repeat: no-repeat;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover {
            color: $color-red;
        }

        &.active {
            color: $color-red;

            &:after {
                background-image: url(../gfx/icons/arrow-red-up.svg);
            }
        }
    }
}

.col-container {
    @media #{$large-down} {
        .col.last {
            > ul:last-child > li > a,
            > ul:last-child > li > .sub-title {
                border-bottom: none;
            }
        }
    }

    @media #{$large-up} {
        @include clearfix();
        @include grid-row;
        opacity: 0;
        position: absolute;
        right: 0;
        left: -999em;
        padding: 50px 0 60px; //100px 0 60px
        transition: opacity 200ms 0ms ease-in-out;
        top: 131px;
        background-color: #fff;

        .col {
            @include grid-column($columns: 3);
        }

        .section-link {
            display: none;

            a {
                position: relative;
                display: inline-block;
                margin-right: rem-calc(15);
                margin-left: rem-calc(20);
                line-height: 1.2;
                font-size: rem-calc(22);
                @include icon();
                @include icon-right();
                @include icon-small-right();
                @include icon-arrow-right();
                &:before,
                &:after {
                    right: 4px;
                    top: 5px;
                }
            }
        }   

        &.open {
            left: 0;
            opacity: 1;
            transition: opacity 200ms 200ms ease-in-out;
            .col,
            .section-link,
            .close-menu {
                display: block;
            }

            .close-wrapper {
                display: block;
            }
        }
    }
}

.mobile-social-sharing {
    @media #{$large-up} {
        display: none;
    }
}
