.article-content-image {
  font-size: rem-calc(14);
  line-height: 1.4;
  margin: 0;
  padding: 10px 0 20px;
  transition: width 400ms ease-in-out;
  @media #{$small-only} {
    padding: 0;
    &.right {
      float: none !important;
    }
    img {
      max-width: 100%;
    }
  }
  @media #{$medium-only} {
    padding-top: 20px;
    padding-bottom: 50px;
    &.right {
      float: none !important;
    }
    img {
      max-width: 100%;
    }
  }
  @media #{$large-up} {
    clear: left;
    float: left;
    width: 400px;
    margin-right: 40px;
    margin-left: -175px;
    padding: 0;
    font-size: rem-calc(16);
    &.right {
      clear: right;
      float: right;
      margin-right: -175px;
      margin-left: 40px;
    }
    &.zoom {
      width: 100%;
      margin-right: 0;
      margin-left: 0;
    }
    img {
      width: 100%;
    }
  }
  @media #{$xlarge-up} {
    width: 500px;
    margin-left: -220px;
  }
  figcaption {
    margin-bottom: rem-calc(30);
    padding: 30px;
    background-color: $color-grey-light;
    font-family: $fontSerifText;
    font-size: 14px;
    line-height: 1.8;
    p {
      display: block;
      margin-bottom: rem-calc(30);
      color: #000;
      @media #{$small-only} {
        margin-bottom: 10px;
        &:first-child {
          margin-top: -20px;
        }
      }
    }
    span {
      font-weight: 700;
    }
  }
}
