.editor{

  h4{
    margin-bottom: 5px;
    margin-top: 20px;
    font-size: rem-calc(16);
  }

  p{
      margin: 5px 0;
  }
}
