.page-publication {
    position: relative;

    [class*="column"] + [class*="column"]:last-child {
        float: right;

        &.end {
            float: left;
        }
    }

    .publication-info {
        margin-top: 1em;

        @media #{$large-up} {
            margin-top: 2em;

            .border-right {
                position: relative;
                padding-right: 40px;

                &:after {
                    position: absolute;
                    top: 0;
                    right: 20px;
                    bottom: 0;
                    width: 10px;
                    content: "";
                    background-color: #eee;
                }
            }

            .block-inner :last-child {
                margin-bottom: 0;
            }
        }
    }

    .published {
        margin-top: rem-calc(20px);
        padding-top: 0px;
        font-size: rem-calc(17px);

        > * {
            font-size: rem-calc(17px);
            margin-top: 0;
        }
    }

    .link-more {
        &:after {
            position: relative;
            top: 2px;
            margin-left: 10px;
            font-family: "stortinget-symbols";
            content: "\e604";
        }
    }

    .nav-link-next,
    .nav-link-prev {
        @media #{$small-only} {
            margin-top: 2em;
            margin-bottom: 2em;
        }

        @media #{$large-up} {
            &.nav-fixed {
                position: fixed;
                bottom: 270px;
                z-index: 1;
            }
        }

        @media (max-height: 850px) {
            &.nav-fixed {
                bottom: 70px;
            }
        }

        @media (max-height: 750px) {
            &.nav-fixed {
                bottom: 20px;
            }
        }

        .touch & {
            top: auto !important;
        }

        a {
            display: block;
            position: relative;
            border-bottom: none;
            color: $color-black;

            &:focus,
            &:hover {
                color: $color-red;
            }

            > span,
            &:before,
            &:after {
                content: "";
                position: absolute;
                top: 50%;
                transform: translateY(-55%);
                width: 44px;
                height: 44px;
                background-repeat: no-repeat;
                background-position: center center;
                border-radius: 50%;
                border: 3px solid transparent;
            }

            &:before {
            }

            &:after {
                display: none;
            }

            &:focus,
            &:hover {
                &:before {
                    display: none;
                }

                &:after {
                    display: block;
                    border: 3px solid $color-black;
                }

                > span {
                    border: 3px solid transparent;
                }
            }

            > span {
                border: 1px solid $color-grey-darker;
            }
        }
    }

    .nav-link-next {
        float: right;

        @media #{$large-up} {
            &.nav-fixed {
                text-align: right;
            }
        }

        a {
            padding: 20px 55px 20px 0;
            text-align: right;

            > span,
            &:before,
            &:after {
                right: 0;
            }

            &:before {
                background-image: url("../gfx/icons/arrow-grey-right.svg");
                background-size: 8px;
            }

            &:after {
                background-image: url("../gfx/icons/arrow-red-right.svg");
                background-size: 8px;
            }

            &:hover,
            &:focus {
                &:after {
                    animation: animateNext 0.3s forwards;
                }
            }

            @keyframes animateNext {
                0% {
                    right: 0;
                }

                50% {
                    right: -5px;
                }

                100% {
                    right: 0;
                }
            }
        }
    }

    .nav-link-prev {
        float: left;

        a {
            padding: 20px 0 20px 55px;

            > span,
            &:before,
            &:after {
                left: 0;
            }

            &:before {
                background-image: url("../gfx/icons/arrow-left.svg");
                background-size: 8px;
            }

            &:after {
                background-image: url("../gfx/icons/arrow-red-left.svg");
                background-size: 8px;
            }

            &:hover,
            &:focus {
                &:after {
                    animation: animatePrev 0.3s forwards;
                }
            }

            @keyframes animatePrev {
                0% {
                    left: 0;
                }

                50% {
                    left: -5px;
                }

                100% {
                    left: 0;
                }
            }
        }
    }

    .article {
        .article-title {
            margin-bottom: 0;

            h1 {
                margin-top: 30px;
                font-size: rem-calc(34px);
            }
        }
    }

    .bigdoc-content {
        padding-top: 15px;

        @media #{$large-up} {
            padding-top: 20px;
            padding-bottom: 20px;
        }

        font-size: rem-calc(19px);
        line-height: 2;
        font-family: $fontSerifText;

        h2:not(:first-child),
        h3,
        h4,
        h5,
        h6 {
            margin-top: rem-calc(30);
        }

        h2 {
            margin-bottom: 0;
            line-height: 2;
        }

        h3 {
            margin-bottom: 0px;
            line-height: 2;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            a {
                color: inherit;
                cursor: default;
                border-bottom: none;

                &:link,
                &:visited {
                    @include link();
                }
            }
        }

        p,
        .p {
            &.information-about-break-in-debate {
                font-style: italic;
            }

            font-family: $fontSerifText;
            font-size: rem-calc(18px);
            line-height: 1.56;
            margin-top: 0;
            margin-bottom: 30px;
        }

        div {
            font-size: rem-calc(18px);
        }

        ul,
        ol {
            padding: 0;
            margin-left: rem-calc(60px);
            font-family: $fontSerifText;
            font-size: rem-calc(18px);
            line-height: 1.56;

            li {
                margin-bottom: 5px;
            }
        }

        figure {
            display: table;
            margin: 0 0 20px 0;

            img {
                border: 0;
                width: 100%;
                height: auto;
            }

            figcaption {
                display: table-caption;
                caption-side: bottom;

                padding: 30px;
                background-color: #ece8e5;
                font-family: "Berlingske Serif Text", "Georgia";
                font-size: 1.125rem;
                line-height: 1.5rem;

                p {
                    margin-bottom: 0;
                    font-family: "Berlingske Serif Text", "Georgia";
                    font-size: 1.125rem;
                    line-height: 30px;
                }
            }
        }

        blockquote {
            font-size: 19px;
            line-height: 2.2;
            margin: 0;
            clear: both;
            color: $color-black;
            background-color: transparent;
            margin-bottom: rem-calc(30);
            padding: 0 50px;
            font-style: italic;

            &:before {
                content: "";
            }

            @media #{$small-only} {
                padding-right: 10px;
                padding-left: 15px;
            }
        }
        /* Include file for Minutes (Referater) */
        div.mainregion {
            /* no minium height for minutes (2col index) */
            min-height: 0;
        }

        span {
            &.ref-presidenten {
                font-weight: bold;
            }

            &.ref-merknad {
                margin-right: 5px;
                margin-left: 5px;

                + span.ref-merknad {
                    margin-left: 0;
                }
            }

            &.ref-bold {
                font-weight: bold;
            }

            &.ref-italic {
                font-style: normal;
            }

            &.strtngt_navn {
                font-weight: bold;
            }

            &.strtngt_endring {
                font-style: italic;
            }
        }

        a.ref-innlegg-navn {
            color: #333;
            font-weight: bold;
        }

        div.mainbody a.ref-innlegg-navn {
            text-decoration: none;
        }

        div.ref-blokksitat-minnrykk {
            margin-top: 1em;
            margin-left: 3em;
            margin-bottom: 1em;
        }

        ul {
            &.ref-list-lower-alpha {
                list-style-type: lower-alpha;
                list-style-position: inside;
            }

            &.ref-list-upper-alpha {
                list-style-type: upper-alpha;
                list-style-position: inside;
            }

            &.ref-list-decimal {
                list-style-type: decimal;
                list-style-position: inside;
            }

            &.ref-list-lower-roman {
                list-style-type: lower-roman;
                list-style-position: inside;
            }

            &.ref-list-upper-roman {
                list-style-type: upper-roman;
                list-style-position: inside;
            }

            &.ref-list-fri {
                list-style-type: none;
                list-style-position: inside;
            }

            &.ref-list-opprams {
                list-style-position: inside;
            }

            &.ref-refliste {
                list-style-type: none;
                margin-left: 0;
                padding-left: 2em;
                list-style-position: inside;
            }

            &.ref-speakers {
                padding: 0em;
                margin: 0em;
                list-style-type: none;
            }

            &.ref-speakers li {
                padding-bottom: 0.2em;
            }

            &.ref-speakers-replies {
                padding: 0.3em 0em 0.3em 1em;
                list-style-type: none;
            }
        }
        /* NEW Expand Control START */
        .ref-speakers-div .showAll {
            float: right;
            margin-right: 10px;
        }

        ul {
            &.ref-speakers2,
            &.ref-speakers2 ul {
                list-style: none;
                margin: 0;
                padding: 0;
            }

            &.ref-speakers2 li {
                padding-bottom: 0.2em;
            }

            &.ref-speakers2 li a {
                color: #008080;
                margin-right: 0.4em;
            }

            &.ref-speakers2 li a.toggleClick {
                background: url(/Web/Images/leftmenu_expand.gif) no-repeat center left;
                margin: 0;
                padding-right: 10px;
                cursor: pointer;
            }

            &.ref-speakers2 li a.open {
                background: url(/Web/Images/leftmenu_collapse.gif) no-repeat center left;
            }

            &.ref-speakers2 li a.toggleClick:hover {
                text-decoration: none;
            }

            &.ref-speakers2 li ul {
                padding: 0.2em 0 0.3em 1em;
            }

            &.ref-speakers2 li ul li a {
                margin-left: 0;
            }
        }
        /* NEW Expand Control END */
        span {
            &.ref-innlegg-type {
                color: #333;
            }
        }

        td {
            &.ref-cell-align-Left {
                text-align: left;
            }

            &.ref-cell-align-Right {
                text-align: right;
            }

            &.ref-cell-align-Center {
                text-align: center;
            }

            &.ref-cell-align-Justify {
                text-align: justify;
            }

            &.ref-cell-align-Char {
                text-align: left;
            }
        }
    }

    .bigdoc-content--no-padding-top-bottom {
        padding-top: 0;
        padding-bottom: 0;

        @media #{$large-up} {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.page-temporary .bigdoc-content {
        table {
            caption {
                display: none;
            }
        }

        span.President,
        span.Replikk,
        span.Taler1 {
            font-weight: 700;
        }

        ol.chapter {
            margin-left: 0;
            padding-left: 0;
            list-style-type: none;

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                text-rendering: auto;
            }
        }

        ol li > div {
            display: inline-block;
            list-style-type: none;
        }

        ol li.chapter:before,
        ol li ol li.chapter:before {
            font-size: 1.7em;
            font-weight: bold;
            font-family: Arial;
            list-style-type: none;
        }

        ol li ol li.chapter:before {
            font-size: 1.3em;
            list-style-type: none;
        }
    }
}
