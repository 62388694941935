.gallery-carousel {
    .gallery-carousel-text {
        a:focus{
            outline:2px solid black;
        }
        @media #{$large-up} {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 33.33%;
        }

        padding: 50px 20px;

        @media #{$medium-up} {
            padding: 50px 30px;
        }

        @media #{$large-up} {
            padding: 0 50px;
        }

        h2 {
            .normal {
                font-weight: normal;
                font-family: 'Berlingske Serif Text';
            }
        }

        span {
            font-weight: 700;
            margin-right: 5px;
        }

        .snl {
            margin-top: 20px;

            span {
                @include h3();
                margin-bottom: 3px;
                display: block;
            }
        }

        .gallery-is-multiple {
            margin-top: 17px;
            margin-bottom: 20px;
        }

        .gallery-series {
            @include h3();
            @include icon();
            @include icon-left();
            border-bottom: 1px solid $color-grey;
            color: $color-black;
            margin-bottom: 5px;
            display: block;
            border: none;

            &:before {
                background-image: url('../gfx/icons/series.svg');
                opacity: 1;
            }
        }
    }

    .gallery-carousel-image-wrapper {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 10px;
        background-color: $color-grey;

        @media #{$large-up} {
            width: 66.66%;
        }

        .gallery-video-tumbnail-and-play-button-wrapper {
            position: relative;

            .gallery-video-overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                background: linear-gradient(transparent 25%, rgba(0, 0, 0, 0.5));
            }

            .gallery-video-play-button {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                cursor: pointer;
                opacity: 0.8;
                background: transparent;

                &:before {
                    width: 0;
                    height: 0;
                    content: "";
                    display: block;
                    border-radius: 2px;
                    border-top: 30px solid transparent;
                    border-bottom: 30px solid transparent;
                    border-left: 65px solid white;
                }

                &:focus {
                    border: 2px solid $color-red;
                }

                &:active {
                    border: none;
                }

                &:hover,
                &:focus {
                    opacity: 1;
                }
            }

            .gallery-video-tumbnail-image {
                width: 100%;
            }
        }
    }

    .gallery-carousel-top {
        overflow: hidden;

        @media #{$medium-up} {
            overflow: visible;
        }

        @media #{$large-up} {
            display: flex;
        }

        margin-bottom: 20px;
        background-color: $color-grey-light;

        .image {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 400px;

            @media #{$large-up} {
                height: auto;
                height: 470px;
            }
        }

        .gallery-carousel-image-inner {
            margin: 0 auto;
            opacity: 1;
            transition: all 1s ease;

            @keyframes fadein {
                0% {
                    opacity: 0;
                }

                100% {
                    opacity: 1;
                }
            }
        }

        img {
            display: block;
            margin: 0 auto;
            max-width: none;
        }
    }

    .gallery-carousel-bottom {
        .gallery-carousel-extra {
            @include panel();
            position: relative;
            display: flex;
            flex-direction: column;

            @media #{$medium-up} {
                flex-direction: row;
            }

            &.closed {
                height: 150px;
                overflow: hidden;
                cursor: cursor;

                &.no-content {
                    height: 0px;
                    padding: 0px;
                }

                .gallery-carousel-extra-fade {
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    display: block;
                    width: 100%;
                    height: 100px;

                    @media #{$medium-up} {
                        height: 120px;
                    }

                    background-image: linear-gradient( to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.1) 10%, rgba(236, 232, 229, 0.9) 60%, rgba(236, 232, 229, 1) 70%, rgba(236, 232, 229, 1) 100% );
                }
            }

            background-color: $color-grey-light;
        }

        .gallery-carousel-info-text {
            @media #{$medium-up} {
                width: 70%;
                margin-right: 20px;
            }

            @media #{$large-up} {
                margin-right: 60px;
            }

            > div {
                @include paragraph-article();
            }
        }

        .gallery-carousel-info-photo {
            &.has-text {
                margin-top: 40px;
            }

            @media #{$medium-up} {
                width: 30%;

                &.has-text {
                    margin-top: 0;
                }
            }
        }
    }

    .image {
        position: relative;
    }

    .gallery-carousel-navigation {
        display: flex;
        justify-content: center;
        justify-content: space-between;
        width: 100%;
        margin-top: 10px;
        position: relative;

        button,
        a {
            margin-right: 5px;
        }
    }

    .gallery-carousel-center-buttons {
        display: flex;

        .social-sharing-container a {
          outline-width: 2px; 
        }
    }

    .gallery-carousel-full-screen,
    .gallery-carousel-download,
    .gallery-carousel-share
     {
        @include button-circular();

        &:after,
        &:before {
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
        }
        &:hover,
        &:focus{
            border:black 3px solid;
            border-radius:60px;
        }

        @media #{$extra-small-only} {
            &:after,
            &:before {
                background-size: 16px;
                width: 16px;
                height: 16px;
            }
        }
    }

    .gallery-carousel-download {
        @include icon();
        @include icon-download();
    }

    .gallery-carousel-full-screen {
        @include icon();
        @include icon-maximize();
    }

    .gallery-carousel-share {
        @include icon();
        @include icon-sharing();
    }

    .gallery-carousel-prev,
    .gallery-carousel-next {
        width: 56px;
        height: 56px;
        padding: 0;
        outline: none;
        background: none;
        position: relative;
        border: 3px solid white;
        border-radius: 50%;
        background-color: white !important;
        color: transparent;

        &:hover{
          border: 3px solid black;
        }

        &:focus-visible{
          border: 3px solid black;
        }

        @media #{$extra-small-only} {
            width: 40px;
            height: 40px;
        }

        &:before,
        &:after {
            display: block;
            position: absolute;
            top: 1px;
            left: 1px;
            right: 1px;
            bottom: 1px;
            width: calc(100% - 2px);
            height: calc(100% - 2px);
            content: '';
            background-color: transparent;
            background-repeat: no-repeat;
            background-image: url('../gfx/icons/old/owl-nav-sprite.svg');
            background-position: -4px -4px;
            background-size: 56px 266px;
            border-radius: 50%;

            @media #{$extra-small-only} {
                width: calc(100% - 2px);
                height: calc(100% - 2px);
                background-position: -4px -4px;
                background-size: 40px 190px;
            }

            .no-svg & {
                background-image: url('../gfx/icons/old/owl-nav-sprite.png');
            }
        }

        &:before {
            opacity: 1;
        }

        &:after {
            opacity: 0;
        }

        &:hover,
        &:focus {
            &:after {
                opacity: 1;
            }
            background: transparent;
        }
    }

    .gallery-carousel-hidden-button {
        visibility: hidden;
    }

    .gallery-carousel-next {
        &:after {
          background-position: -4px -74px;

          @media #{$extra-small-only} {
            background-position: -4px -54px;
          }
        }
    }

    .gallery-carousel-prev {
        &:before {
            background-position: -4px -144px;

            @media #{$extra-small-only} {
                background-position: -4px -104px;
            }
        }

        &:after {
            background-position: -4px -214px;

            @media #{$extra-small-only} {
                background-position: -4px -154px;
            }
        }
    }
}

.gallery-carousel {
  /* Open seadragon test */
  .slider-fullscreen {
    $mobile-breakpoint: 737px;

    display: none;
    background: $color-black;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99999;
  }
  .slider-fullscreen.open {
    display: block;
  }

  .ocd-div {
    width: 100%;
    height: 100%;
    margin: 0 auto;
  }

  .ocd {
    height: 100%;
  }

  .ocd,
  .openseadragon {
    width: auto;
    max-width: 100%;
    margin: 0 auto;
  }

  .openseadragon {
    height: calc(100% - 76px);
  }

  .ocd-div {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding: 10px;

    @media #{$medium-up} {
      padding: 20px;
    }
  }

  .ocd-loader {
    position: absolute;
    top: calc(50% - 32px);
    left: calc(50% - 32px);
    width: 64px;
  }

  .ocd-buttons {
    display: flex;
    position: relative;
    justify-content: space-between;
    padding-top: 20px;
  }

  .ocd-center-buttons {
    display: flex;
  }

  .ocd-button {
    @include button-circular();
    @include icon();

    width: 56px;
    height: 56px;

    margin: 0 3px;

    &:after,
    &:before {
      left: 50%;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
    }

    &[disabled] {
      display: none !important;
    }
  }

  .ocd-button-download {
    @include icon-download();
    
    &:hover,
    &:focus-visible {
      border: 3px solid black;
    }

    @media #{$medium-up} {
      margin-left: 40px;
    }
  }
  
  .ocd-button-close {
    @include icon-closing();
    
    &:hover{
      border: 3px solid black;
    }
    
    position: absolute;
    top: 10px;
    right: 10px;

    @media #{$medium-up} {
      top: 20px;
      right: 20px;
    }
  }

  .ocd-button-zoom-in {
    @include icon-zoom-in();

    &:hover,
    &:focus-visible{
      border: 3px solid black;
    }
  }

  .ocd-button-zoom-out {
    @include icon-zoom-out();
      
    &:hover,
    &:focus-visible{
      border: 3px solid black;
    }
  }

  .openseadragon-container {
    width: 100% !important;
    height: 100% !important;
    position: absolute;
  }

  .fullpage .openseadragon-container {
    width: 100% !important;
    height: 100% !important;
  }

  .navigator-wrapper {
    position: fixed;
    left: 5px;
    bottom: 5px;
    background: #000;
    overflow: hidden;
    width: 166px;
    height: 178px;
    border: solid 0px #ffffff;
    z-index: 3100;
  }

  #navigator {
    width: 166px;
    height: 178px;
    position: absolute;
    top: 0;
  }

  #navigator-displayregion {
    border-width: 1px !important;
    border-color: #d53015 !important;
    z-index: 4500 !important;
    background: rgba(0, 0, 0, 0.2) !important;
  }

  .ocd-toolbar {
    width: 30px;
    display: block;
    position: fixed;
    top: 50%;
    right: 1px;
    list-style: none;
    z-index: 110;
    padding: 0;
  }

  .ocd-toolbar > li {
    list-style: none;
  }

  .ocd-toolbar a {
    width: 30px;
    height: 30px;
    font-height: 30px;
    display: block !important;
    background: rgba(0, 0, 0, 0.6);
    color: #fff;
    margin-bottom: 1px;
    cursor: pointer;
    text-align: center;
  }

  .ocd-toolbar a:hover {
    background: rgba(158, 46, 0, 0.6);
    text-decoration: none;
  }

  .ocd-toolbar a > i {
    display: block;
    width: 30px;
    height: 30px;
    line-height: 30px;
    font-size: 15px;
    padding-top: 2px;
  }
}

/* endregion */

/******
    *** Grid
    *******/

.gallery-loading-container {
  text-align: center;
  img {
    margin: 0 auto;
    &.show-more-clicked {
      width: 256px;
      height: 256px;
      position: fixed;
      bottom: 0px;
      left: 50%;
      margin-left: -128px; /* Negative half of width. */
    }
  }
}

.gallery-list-info {
    margin-bottom: 20px;

    .filter-trigger {
        width: 200px;
    }

    @media #{$medium-up} {
        display: flex;
        justify-content: space-between;
    }

    .gallery-sort {
        position: relative;
        margin-top: 20px;
        margin-bottom: 40px;

        @media #{$medium-up} {
            margin-top: 0;
            margin-bottom: 0;
        }
        
        .travel-list-comittee-filter{
          padding: 0;
          border-color: rgb(216, 209, 202);

          li {
            margin: 0;
          }

          button{
            border-radius: 0;
            height: 39px;
            padding: 10px 20px;

            @media (min-width: 1151px) {
              height: 29px;
              padding-block: 5px;
            }

            &:focus-visible{
              outline: 2px solid black;
              outline-offset: -2px;
              background-color: transparent;
            }
        
            &:hover{
              background-color: #ece8e5;
            }
          }
        }
    }

    .travel-list-comittee-filter {
        left: auto;
        padding-inline: 0 !important;

        button{
          padding-inline: 15px;
        }
    }

    & + .grid-outer {
        // overflow: hidden on this element was seemingly not doing anything, and cutting outline on focus
        // reinstate if it turns out it did do something meaningful
        overflow-x: initial;
    }
}

.gallery-more {
  margin-top: 40px;
  margin-bottom: 20px;
  text-align: center;
}

a.gallery-list-item {
    margin-right: 20px;
    margin-bottom: 40px;
    color: $color-black;
    border: none;
    display: block;

    .item-title {
        // title
        font-weight: 700;
        margin-bottom: 3px;
        border-bottom: 1px solid $color-grey;
        display: inline;
    }

    &:focus{
        outline: 2px solid black;

    }

    &:focus,
    &:hover {
        .item-title {
            color: $color-red;
        }
    }

    .gallery-list-item-text {
        margin-top: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.grid-outer {
    $padding: 20px;
    $items-per-row: 4;

    body {
        overflow-y: scroll;
    }

    .grid-outer {
        overflow-x: hidden;
    }

    .grid {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        width: calc(100% + #{$padding});
    }

    .container {
        flex-grow: 0;
        flex-shrink: 0;
    }

    .container-inner {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: $color-grey;

        > a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }

    .btn-link {      

        &:focus,
        &:hover {
            outline: #000 solid 2px;
        }
    }
}

/* */

.big-toggler-trigger {
    background-color: $color-grey;
    padding-top: 10px;
    padding-bottom: 10px;
    text-align: center;

    > button {
        @include h2;
        margin: 0 auto;
        padding-right: 36px;

        &:before {
            opacity: 1;
            right: 0;
            top: -2px;
        }

        &:after {
            border: 3px solid;
            opacity: 0;
            right: 0;
            top: -2px;
            border-radius:60px;
        }

        &:hover,
        &:focus {
            &:after {
                opacity: 1;
            }

            &:before {
                border-color: $color-black;
            }

            background: transparent;
        }       
        
    }
}

/************
******* Category info
************/

.section-gallery-category-info {
  margin-top: -30px;
  .gallery-category-info {
    background-color: $color-grey-light;
    @include panel();
    display: block;
    position: relative;
    &.open {
      display: block;
    }
    &.closed {
      height: 150px;
      overflow: hidden;
      .gallery-category-info-fade {
        position: absolute;

        bottom: 0;
        left: 0;

        display: block;

        width: 100%;
        height: 100px;

        @media #{$medium-up} {
          height: 120px;
        }

        background-image: linear-gradient(
          to bottom,
          rgba(255, 255, 255, 0.1) 0%,
          rgba(255, 255, 255, 0.1) 10%,
          rgba(236, 232, 229, 0.9) 60%,
          rgba(236, 232, 229, 1) 70%,
          rgba(236, 232, 229, 1) 100%
        );
      }
    }
    .gallery-category-info-text {
      & > div {
        @include paragraph-article();
      }
      @media #{$medium-up} {
        width: 70%;

        margin-right: 20px;
      }
      @media #{$large-up} {
        margin-right: 60px;
      }
    }
  }
}

/************
******* Explorer
************/

.gallery-explorer {
  background-color: $color-grey-light;
  @include panel();
  display: none;
  &.open {
    display: block;
  }
  .tabs{
      li{
          a{
              &:focus,
              &:hover{
                  border:black 3px solid;
                  border-radius:60px;
              }
          }
      }
  }

  .gallery-explorer-content {
    margin-top: 20px;

    > div {
      display: none;
      &.active {
        display: block;
      }
    }
  }

  .gallery-explorer-button-wrapper {
    @media #{$medium-up} {
      position: absolute;
      right: 0;
      bottom: 20px;
    }
  }

  .input-range {
    width: calc(100% - 20px) !important;
    margin: 0 auto;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 8px;
    margin-bottom: 50px;
    .input-range__slider {
      position: relative;
      &:focus {
        box-shadow: #333 2px 2px 2px;
      }
    }
    .input-range__slider-container .input-range__label-container {
      background: #fff;
      padding: 7px 11px;
      padding-bottom: 6px;
      bottom: -45px;
      position: relative;

      &:after {
        content: '\25b2';
        padding-left: 0.5em;
        position: absolute;
        color: white;
        top: -15px;
        left: 13px;
      }
    }
    .input-range__label--min,
    .input-range__label--max {
      display: none;
    }

    // overwrites
    .input-range__track {
      background-color: $color-grey;
    }
    .input-range__track--active {
      background-color: $color-red;
    }
    .input-range__slider {
      background-color: #fff;
      border: none;
      box-shadow: #999 2px 2px 2px;
    }
    .input-range__label-container {
      color: $color-black;
    }
  }
}

.gallery-explorer-categories {
    display: flex;
    flex-wrap: wrap;
    margin-right: -30px;
    // was seemingly not doing anything, and cutting outline on focus
    // reinstate if it turns out it did do something meaningful
    //overflow: hidden;
    //padding: 2px; // make room for outline

    a.gallery-explorer-category {
        border: none;
        display: block;
        margin-bottom: 30px;
        margin-right: 30px;
        width: 50%;
        width: calc(50% - 30px);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: black;

        &:focus{
            outline: 2px solid black;
        }

        @media #{$medium-up} {
            width: 33.33%;
            width: calc(33.33% - 30px);
        }

        @media #{$large-up} {
            width: 25%;
            width: calc(25% - 30px);
        }

        .image {
            display: block;
            margin-bottom: 10px;
            position: relative;
            width: 100%;
            background-color: $color-grey;
            height: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media #{$medium-up} {
                height: 250px;
            }

            .image-inner {
                align-items: center;
                display: flex;
                justify-content: center;
            }

            img {
                max-width: 100%;
                max-width: 100%;
                max-height: 150px;

                @media #{$medium-up} {
                    flex-shrink: 0;
                    max-height: 250px;
                }

                width: auto;
                height: auto;
            }

            &:before,
            &:after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                opacity: 0;
            }

            &:before {
                background-color: #000;
            }

            &:after {
                transform: scale(1);
                background-repeat: no-repeat;
                background-position: center center;
            }
        }

        .text {
            @include h2;
            font-size: 14px;

            @media #{$medium-up} {
                font-size: 18px;
            }

            @media #{$large-up} {
                @include h2;
            }

            @include icon();
            @include icon-left();
            border-bottom: 1px solid $color-grey;
            color: $color-black;

            &:before {
                background-image: url('../gfx/icons/category.svg');
                opacity: 1;
            }

            &:after {
                background-image: url('../gfx/icons/category-hover.svg');
                opacity: 0;
            }
        }

        &:hover,
        &:focus {
            .text {
                color: $color-red;

                &:before {
                    opacity: 0;
                    transition: opacity 0.2s ease-in-out;
                }

                &:after {
                    opacity: 1;
                    transition: scale 0.35s ease-in-out;
                }
            }
        }

        &.selected {
            .image {
                &:before {
                    opacity: 0.6;
                    filter: alpha(opacity=60); /* msie */
                    transition: opacity 0.2s ease-in-out;
                }

                &:after {
                    background-image: url('../gfx/icons/check-thin.svg');
                    opacity: 1;
                    background-size: 30px 20px;
                    transform: scale(1);
                }
            }

            &:hover {
                .image:after {
                    background-image: url('../gfx/icons/check-thin-x.svg');
                }
            }
        }
    }
}
.gallery-explorer-content {
    .btn-link {
        margin-top: 30px;
        &:focus,
        &:hover{
        outline: #000 solid 2px;
        }
    }
}

/* plugin */

.input-range__slider {
  appearance: none;
  background: #3f51b5;
  border: 1px solid #3f51b5;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  margin-left: -0.5rem;
  margin-top: -0.65rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  width: 1rem;
}
.input-range__slider:active {
  transform: scale(1.3);
}
.input-range__slider:focus {
  box-shadow: 0 0 0 5px rgba(63, 81, 181, 0.2);
}
.input-range--disabled .input-range__slider {
  background: #cccccc;
  border: 1px solid #cccccc;
  box-shadow: none;
  transform: none;
}

.input-range__slider-container {
  transition: left 0.3s ease-out;
}

.input-range__label {
  color: #aaaaaa;
  font-family: 'Helvetica Neue', san-serif;
  font-size: 0.8rem;
  transform: translateZ(0);
  white-space: nowrap;
}

.input-range__label--min,
.input-range__label--max {
  bottom: -1.4rem;
  position: absolute;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: absolute;
  top: -1.8rem;
}

.input-range__label-container {
  left: -50%;
  position: relative;
}
.input-range__label--max .input-range__label-container {
  left: 50%;
}

.input-range__track {
  background: #eeeeee;
  border-radius: 0.3rem;
  cursor: pointer;
  display: block;
  height: 0.3rem;
  position: relative;
  transition: left 0.3s ease-out, width 0.3s ease-out;
}
.input-range--disabled .input-range__track {
  background: #eeeeee;
}

.input-range__track--background {
  left: 0;
  margin-top: -0.15rem;
  position: absolute;
  right: 0;
  top: 50%;
}

.input-range__track--active {
  background: #3f51b5;
}

.input-range {
  height: 1rem;
  position: relative;
  width: 100%;
}

/*# sourceMappingURL=index.css.map */

.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-target {
  position: relative;
  direction: ltr;
}
.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}
/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}
.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}
/* Offset direction
 */
html:not([dir='rtl']) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}
/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}
.noUi-horizontal .noUi-origin {
  height: 0;
}
.noUi-handle {
  position: absolute;
}
.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}
.noUi-state-drag * {
  cursor: inherit !important;
}
/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 18px;
}
.noUi-horizontal .noUi-handle {
  width: 34px;
  height: 28px;
  left: -17px;
  top: -6px;
}
.noUi-vertical {
  width: 18px;
}
.noUi-vertical .noUi-handle {
  width: 28px;
  height: 34px;
  left: -6px;
  top: -17px;
}
html:not([dir='rtl']) .noUi-horizontal .noUi-handle {
  right: -17px;
  left: auto;
}
/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #fafafa;
  border-radius: 4px;
  border: 1px solid #d3d3d3;
  box-shadow: inset 0 1px 1px #f0f0f0, 0 3px 6px -5px #bbb;
}
.noUi-connects {
  border-radius: 3px;
}
.noUi-connect {
  background: #3fb8af;
}
/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}
.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}
.noUi-handle {
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  cursor: default;
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ebebeb, 0 3px 6px -3px #bbb;
}
.noUi-active {
  box-shadow: inset 0 0 1px #fff, inset 0 1px 7px #ddd, 0 3px 6px -3px #bbb;
}
/* Handle stripes;
 */
.noUi-handle:before,
.noUi-handle:after {
  content: '';
  display: block;
  position: absolute;
  height: 14px;
  width: 1px;
  background: #e8e7e6;
  left: 14px;
  top: 6px;
}
.noUi-handle:after {
  left: 17px;
}
.noUi-vertical .noUi-handle:before,
.noUi-vertical .noUi-handle:after {
  width: 14px;
  height: 1px;
  left: 6px;
  top: 14px;
}
.noUi-vertical .noUi-handle:after {
  top: 17px;
}
/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #b8b8b8;
}
[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}
/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
.noUi-pips {
  position: absolute;
  color: #999;
}
/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}
.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}
/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #ccc;
}
.noUi-marker-sub {
  background: #aaa;
}
.noUi-marker-large {
  background: #aaa;
}
/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}
.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}
.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}
.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}
.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}
.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}
/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}
.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}
.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}
.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}
.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}
.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}
.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}
.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}
.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

/// CUSTOM

.noUi-target {
  height: 4px;
  box-shadow: none;
  border: none;
  background: $color-grey;
  width: calc(100% - 20px) !important;
  margin: 12px auto 50px;
}

.noUi-connect {
  background: $color-red;
}

.noUi-horizontal .noUi-handle {
  top: -13px;
}

.noUi-handle {
  border: none;
  box-shadow: none;
  background: transparent;
  cursor: pointer;
  &:before,
  &:after {
    content: none;
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background-color: #fff;
    border: none;
    box-shadow: #999 2px 2px 2px;
    border-radius: 100%;
    left: 6px;
    top: 4px;
  }
  &:focus {
    outline: none;
    border: none;
    &:before {
      box-shadow: #333 2px 2px 2px;
    }
  }
}

.noUi-tooltip {
  background: #fff;
  padding-bottom: 3px;
  font-family: $fontSans;
  font-size: 14px;
  border: none;
  bottom: -125% !important;
  border-radius: 0;
  line-height: 1.2;
  padding: 6px 13px 4px;

  &:after {
    content: '\25b2';
    padding-left: 0.5em;
    position: absolute;
    color: white;
    top: -10px;
    left: 14px;
  }
}

.page-gallery{
  .button.button-select-trigger:focus-visible{
    outline: 2px solid rgb(16,16,16);
    outline-offset: 2px;
  }

  .button.submit:focus-visible{
    outline: 2px solid white;
    outline-offset: -3px;
  }

  a:focus-visible{
    outline-width: 2px;
  }
  
  .gallery-explorer-content{
    input:focus-visible{
      outline: 2px solid rgb(16,16,16);
    }
  }

  .slider-fullscreen.open{
    button,
    .ocd-button-download
    {
      &:focus-within
      {
        box-shadow: 0 0 0 2px white !important;
        border-radius: 50% !important;
        border: 3px solid black;
      }
    }
  }
}
