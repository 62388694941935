.social-sharing-container {
  position: relative;
}

.list .date .social-toggle {
  margin-left: 1em;
}

a.social-toggle + ul.social-sharing-list {
	top: 34px;
}

ul.social-sharing-list {
  display: none;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 10;
  width: auto;
  padding: 10px;
  border: 1px solid $color-grey;
  background: #fff;
  left: 50%;
  transform: translateX(-50%);

  &__open-upwards {
	  bottom: 60px;

	  @media #{$extra-small-only} {
		bottom: 53px;
	  }
  }

  &__open-downwards {
	  top: 60px;

	  @media #{$extra-small-only} {
		top: 45px;
	  }
  }

  &__no-transform {
	  transform: none;
	  left: auto;
  }

  > li{
    float: none;
    > a{
      text-transform: none;
      padding: 2px 10px;
      font-size: rem-calc(16px);
      color: $color-grey-dark;
      @include icon();
      @include icon-small-left();
      display: block;
      line-height: 1.25;
      margin-right: 0 !important;
      margin-bottom: 0;
      border: none;
      &:focus,
      &:hover{
        color: $color-red;
      }

      &.facebook{
        @include icon-small-facebook();
        @include icon-no-animation();
      }
      &.twitter{
        @include icon-small-twitter();
        @include icon-no-animation();
      }
      &.email{
        @include icon-small-email();
        @include icon-no-animation();
      }
    }
  }

  ul.list &,
  .case-steps & {
    left: auto;
    margin-left: 0;
    top: auto !important;
    transform: none;
  }

  .case-steps & {
    > li {
      margin: 0;
      padding: 0;
      width: auto;
    }
  }
}
