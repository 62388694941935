.committee {
  @include clearfix();
  .profile-image-wrapper{
    float: left;
    margin: 0 20px 0 0;
    background-color: $color-grey;
  }
  .content{
    .block-title{
      margin-bottom: 10px;
    }
    > p{
      margin-top: 0;
      a {
          color: $color-black;
          &:hover {
              color: $color-red;
          }
      }
    }
  }
}
