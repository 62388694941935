/* Beslutninger */
span.beslo-italic {
  font-style: italic;
}

td.beslo-cell-align-left {
  text-align: left;
}

td.beslo-cell-align-right {
  text-align: right;
}

td.beslo-cell-align-center {
  text-align: center;
}

ul.beslo-list-none {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-lower-alpha {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-upper-alpha {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-decimal {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-lower-roman {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-upper-roman {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-fri {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-opprams {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-strek {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.beslo-list-disc {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

/* Innstillinger */
span.innst-italic {
  font-style: italic;
}

td.innst-cell-align-left {
  text-align: left;
}

td.innst-cell-align-right {
  text-align: right;
}

td.innst-cell-align-center {
  text-align: center;
}

ul.innst-list-none {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.innst-list-lower-alpha {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.innst-list-lower-decimal {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.innst-list-lower-roman {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.innst-list-disc {
  list-style-image: url("/Web/images/line.gif");
  margin-left: 1.2em;
  list-style-position: outside;
}

/* Dok8 */
span.dok8-italic {
  font-style: italic;
}

td.innst-cell-align-left {
  text-align: left;
}

td.innst-cell-align-right {
  text-align: right;
}

td.innst-cell-align-center {
  text-align: center;
}

ul.dok8-list-none {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.dok8-list-lower-alpha {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.dok8-list-arab {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.dok8-list-lower-roman {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

ul.dok8-list-disc {
  list-style-type: none;
  margin-left: 2em;
  list-style-position: inside;
}

/* TOC documents */
ul.stdoc {
  margin: 0;
  padding: 0;
  float: left;
  position: relative;

  li {
    list-style: none;
    float: left;
    position: relative;
    display: block;
    width: 100%;
    margin-left: 0em;
    margin-top: 0.3em;
  }

  ul li {
    padding-left: 3em;
  }
}
