.block-main {
    overflow: hidden;
    background-color: $color-grey-light;

    .video-embed-wrapper {
        position: relative;
        width: 100%;
        height: 0;
        padding-bottom: 56.66%;
    }

    .video-embed-wrapper iframe {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
    }
}

.block-main.grief-mode {
    background-color: black;

    .video-embed-wrapper, .block-main-media, .block-main-content {
        h2,
        span,
        a,
        p {
            color: white;
        }

        a:focus {
            outline: 2px white solid;
        }
    }
}
a.block-main {
  display: block;
  padding-bottom: 0 !important;
  border-bottom: none !important;
  transition: all 0.3s ease-in-out;

  .block-main-title {
    transition: color 0.3s ease-in-out;
  }

  * {
    color: $color-black;
  }

  &:hover,
  &:focus {
    .block-main-title {
      color: $color-red;
    }
  }
}

.large-12 {
  .block-main {
    @media #{$large-up} {
      display: table;
      table-layout: fixed;
      width: 100%;
      margin-bottom: 40px;
    }
  }

  .block-main-media {
    @media #{$large-up} {
      display: table-cell;
      width: 50%;
    }
  }
}

.block-main-iframe {
  top: 0;
  height: 22em;
  display: flex;
  left: 0;
  width: 100%;
  overflow: hidden;

  @media #{$large-down} {
    height: 32em;
  }
}

.block-main-media {
  @media #{$large-down} {
    text-align: center;
  }

  .no-link {
    position: relative;
  }

  &.block-main-media-video {
    position: relative;
  }

  &.block-main-media img {
    width: 100%;
  }

  a {
    display: block;
    position: relative;
    padding-bottom: 0 !important;
    border-bottom: none !important;
  }

  .video-link {
    &:before {
      content: "";
      float: left;
      width: 14px;
      height: 20px;
      background: url("../gfx/icons/play-hover.svg") 0 0 no-repeat;
      background-position: center;
      margin-right: 10px;

      .no-svg & {
        background: url("../gfx/icons/play-hover.png") 0 0 no-repeat;
      }
    }

    &:hover,
    &:focus {
      &:before {
        background-image: url("../gfx/icons/play.svg");

        .no-svg & {
          background-image: url("../gfx/icons/play.png");
        }
      }
    }
  }
}

.block-main-content {
  padding: 30px 30px 30px 30px;
  position: relative;
  * {
    margin-bottom: 0;
    + * {
      margin-top: rem-calc(20);
    }
  }
}

@media #{$large-up} {
  .large-12 {
    .block-main-content {
      position: relative;
      display: table-cell;
      width: 50%;
      vertical-align: bottom;
      padding: 10px 40px 25px 50px;
      &.center {
        vertical-align: middle;
        padding: 0 40px 0 50px;
        p:last-child {
          margin-bottom: 0;
        }
      }
      &.bottom {
        padding-bottom: 50px;
      }
      img {
        width: 100%;
      }
    }
  }
}

.block-main-subtitle {
  display: block;
  font-size: rem-calc(10);
  text-transform: uppercase;
  margin-bottom: 15px;
  font-weight: 700;
  @media #{$large-up} {
    font-size: rem-calc(14);
    margin-bottom: 20px;
  }
}

.block-main-title {
  display: inline;
  line-height: 1.3;
  &:after {
    content: "";
    width: 0;
    height: 0;
    margin-bottom: 25px;
    display: block;
  }

  @include section-title();
  a {
    &:link,
    &:visited {
      padding-bottom: 0;
      color: $color-black;
      transition: all 0.3s ease-in-out;
    }
  }
  a & {
    @include link($color-grey-darker);
    color: $color-black;
  }
}

.block-main-title-small {
  display: block;
  margin: 0 0 15px 0;
  font-family: $fontSerif;
  font-size: 20px;
  font-weight: 400;
  @media #{$medium-up} {
    font-size: 30px;
  }
  a {
    &:link,
    &:visited {
      padding-bottom: 0;
      color: $body-font-color;
      border-bottom: none;
      transition: all 0.3s ease-in-out;
    }
  }
}

.block-main-excerpt {
  font-size: rem-calc(14);
  margin: 0;
  font-weight: 500;
  @media #{$large-up} {
    font-size: rem-calc(16);
  }
}

.block-main-link {
  display: inline;
  border-bottom: 2px solid #dcdddf;
  padding-bottom: 2px;
}

.block-main-list {
  padding: 0;
  margin: 30px 0 0 0;
  list-style-type: none;
  padding-top: 20px;
  overflow: hidden;
  a {
    font-family: $fontSans;
    color: inherit;
    border-bottom: none;
    text-transform: uppercase;
  }
}

@media #{$large-up} {
  .large-12 {
    .block-main-list {
      border-top: 1px solid #dcdddf;
      margin: 10px 0 0 0;
    }

    .block-main-ajax {
      position: relative;
      min-height: 239px;
      max-height: 330px;
      overflow: hidden;

      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 20px;
        content: "";
        // IE9 SVG, needs conditional override of 'filter' to 'none'
        background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iI2VlZWVlZSIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiNlZWVlZWUiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
        background: -moz-linear-gradient(
          top,
          rgba(238, 238, 238, 0) 0%,
          rgba(235, 232, 228, 1) 100%
        ); // FF3.6+
        background: -webkit-gradient(
          linear,
          left top,
          left bottom,
          color-stop(0%, rgba(235, 232, 228, 0)),
          color-stop(100%, rgba(238, 238, 238, 1))
        ); // Chrome,Safari4+
        background: -webkit-linear-gradient(
          top,
          rgba(238, 238, 238, 0) 0%,
          rgba(235, 232, 228, 1) 100%
        ); // Chrome10+,Safari5.1+
        background: -o-linear-gradient(
          top,
          rgba(238, 238, 238, 0) 0%,
          rgba(235, 232, 228, 1) 100%
        ); // Opera 11.10+
        background: -ms-linear-gradient(
          top,
          rgba(238, 238, 238, 0) 0%,
          rgba(235, 232, 228, 1) 100%
        ); // IE10+
        background: linear-gradient(
          to bottom,
          rgba(238, 238, 238, 0) 0%,
          rgba(235, 232, 228, 1) 100%
        ); // W3C
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00eeeeee', endColorstr='$color-grey-light',GradientType=0 ); // IE6-8
        pointer-events: none;
      }
    }
  }
}
.block-main-list-item {
  margin: 0 0 15px 0;
  @include clearfix();
  @media #{$medium-up} {
    float: left;
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
  }
  @media #{$large-up} {
    margin-right: 0;
    width: 33.3%;
    .hearings.block-main-list & {
      width: 50%;
    }
  }
  a {
    font-size: rem-calc(14);
    letter-spacing: 1px;
    display: block;
  }
}

.content-container {
  .block-main-list-item {
    a {
      color: $color-black;
    }
  }
}

.block-main-caption {
  font-family: $fontSerifText;
  text-align: left;
  font-size: 18px;
  font-weight: 400;
  p {
    margin-bottom: 0;
    font-family: $fontSerifText;
    font-size: 18px;
    line-height: 30px;
    + p {
      margin-top: rem-calc(20);
    }
  }
  span {
    font-weight: 700;
  }
}
