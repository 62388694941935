.block-chart {
  .block-link {
    float: right;
    margin: 0;
  }
}

.block-chart-bar {
  margin: 0;
  li {
    height: 20px;
    line-height: 20px;
    margin: 0 0 15px 0;
  }
  .name-wrapper {
    float: left;
    @media #{$medium-up} {
      width: 200px;
    }
  }
  .name {
    display: inline-block;
    cursor: pointer;
    @include link;
    color: $color-black;
    font-size: rem-calc(16);
  }
  .rep-count {
    width: 20px;
    text-align: right;
    color: $color-black;
    display: inline-block;
    float: right;
    font-size: rem-calc(16);
    font-weight: 400;
    @media #{$medium-up} {
      float: left;
    }
  }
  .rep-count-total-wrapper {
    @media #{$medium-up} {
      margin-left: 200px;
    }
  }
  .rep-count-total {
    display: inline-block;
    font-size: rem-calc(16);
    font-weight: 400;
    color: $color-black;
  }
  .bar-wrapper {
    display: none;
    @media #{$medium-up} {
      margin-left: 230px;
      display: block;
    }
  }
  .bar {
    width: 10px;
    height: 20px;
    background-color: $color-grey;
  }
  li > a:hover {
    .bar {
      background-color: $color-red;
    }
  }
}

.content-container .block-chart-bar .bar {
  border: none;
  padding: 0;
}

.party-distribution-with-buttons {
  .player-ctrl-timeshift {
    padding: 5px;
    margin-bottom: 10px;
  }

  .section-title {
    padding: 5px;
  }

  .bar-wrapper {
    margin-left: 210px;
  }

  .block-chart-bar {
    margin: 0 5px;
  }

  .name-wrapper {
    width: 180px;
    overflow: hidden;
    white-space: nowrap;
    position: relative;

    &:after {
      content: "";
      position: absolute;
      z-index: 1;
      bottom: 0;
      right: -1px;
      pointer-events: none;
      background-image: linear-gradient(
        to right,
        rgba(255, 255, 255, 0),
        rgba(255, 255, 255, 1) 90%
      );
      width: 30px;
      height: 20px;
    }
  }

  .rep-count-total-wrapper {
    @media #{$medium-up} {
      margin-left: 177px;
    }
  }
}
